import { createAction, props } from "@ngrx/store";
import {
  TSendVirtualCountsPayload,
  TSendVirtualCountsResponse,
} from "../../../../view/home/station-home/station-home-kpi-card/kpi-card-items/semi-manual-count-button-card/semi-manual-count-button-card.types";

export const sendVirtualCounts = createAction(
  '[Semi Manual Count Button Card Component] Send Virtual Counts',
  props<{ payload: TSendVirtualCountsPayload, stationID: number }>(),
);

export const sendVirtualCountsSuccess = createAction(
  '[Semi Manual Count Button Card Component] Send Virtual Counts Success',
);

export const sendVirtualCountsFailure = createAction(
  '[Semi Manual Count Button Card Component] Send Virtual Counts Failure',
  props<{ error: any }>(),
);
