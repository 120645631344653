import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, switchMap } from 'rxjs/operators';
import { from, of } from 'rxjs';
import scheduleScenarioUsersSchedulerDumpDataJson from '../../../assets/scheduler_dump_data/scheduleScenarioUsers.schedulerDumpData.json';
import * as AppActions from '../app/actions';
import * as ObjectActions from './scheduler-scenario.actions';
import * as SchedulerActions from '../../store/scheduler/scheduler.actions';
import { Store } from '@ngrx/store';
import * as oeeAppReducer from '../oee.reducer';
import { SchedulerScenarioService } from '../../shared/service/scheduler-scenario/scheduler-scenario.service';
import {
  CloneScenarioResponseInterface,
  CreateScenarioResponseInterface,
  DeleteScenarioResponseInterface,
  ScenarioBulkResponse,
  ScenarioBulkResponseDataInterface,
  UpdateScenarioResponseInterface,
} from './scheduler-scenario.model';
import { HttpParams } from '@angular/common/http';
import { SiteService } from '../../shared/service/filter/site.service';
import { UserService } from '../../shared/service/user/user.service';
import {
  BaseOneResponseInterface,
  GetManyResponseInterface,
} from '../../shared/model/interface/crud-response-interface.model';
import { UserGetManyCRUDDataInterface } from '../../shared/service/user/user.model';
import { SchedulerResourceSettingService } from '../../shared/service/scheduler-resource-setting/scheduler-resource-setting.service';
import { ISchedulerResourceSetting } from '../../view/scheduler-resource-settings/scheduler-resource-settings.model';

@Injectable()
export class SchedulerScenarioEffects {
  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly actions$: Actions<ObjectActions.SchedulerScenarioActions>,
    private readonly schedulerScenarioService: SchedulerScenarioService,
    private readonly schedulerResourceSettingService: SchedulerResourceSettingService,
    private readonly siteService: SiteService,
    private readonly userService: UserService,
  ) {}

  $loadScenarios = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.LoadSchedulerScenarios),
      switchMap((objectData: ObjectActions.LoadSchedulerScenarios) => {
        return this.schedulerScenarioService.loadScenarios(objectData).pipe(
          switchMap((response) => {
            return of(new ObjectActions.SchedulerScenariosLoaded(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error));
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  // TODO: Not used, should be removed in Phase-2
  $loadSchedulerScenariosUser = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.LoadSchedulerScenarioUsers),
      switchMap(() => {
        return of(new ObjectActions.SchedulerScenarioUsersLoaded(scheduleScenarioUsersSchedulerDumpDataJson));
      }),
    ),
  );

  $getUserData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.LoadScenarioUsers),
      switchMap((objectData: ObjectActions.LoadScenarioUsers) => {
        const queryParams: HttpParams = this.schedulerScenarioService.getUserHttpParams(
          objectData.userId,
          objectData.searchText,
          objectData.siteId,
        );

        return from(this.userService.getUsersRequest(queryParams)).pipe(
          switchMap((response: UserGetManyCRUDDataInterface) => {
            return of(new ObjectActions.ScenarioUsersLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $getSiteData = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.SchedulerSiteDataLoading),
      switchMap((payload: ObjectActions.SchedulerSiteDataLoading) => {
        const httpParams: HttpParams = new HttpParams().set('limit', '1000');

        return from(this.siteService.getData(httpParams)).pipe(
          switchMap((response) => {
            return of(new ObjectActions.SchedulerSiteDataLoaded(response));
          }),
          catchError((errorRes) => {
            return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((errorRes) => {
        return of(new ObjectActions.FetchError(errorRes), new AppActions.HideLoader());
      }),
    ),
  );

  $createScenario = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.CreateSchedulerScenario),
      switchMap((objectData: ObjectActions.CreateSchedulerScenario) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.schedulerScenarioService.createScenario(objectData.scenario).pipe(
          switchMap((response: CreateScenarioResponseInterface) => {
            return of(new AppActions.HideLoader(), new ObjectActions.SchedulerScenarioCreated(response));
          }),
          catchError((error) => {
            return of(
              new AppActions.HideLoader(),
              new ObjectActions.FetchError(error),
              new ObjectActions.ScenarioError(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $updateScenario = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.UpdateSchedulerScenario),
      switchMap((objectData: ObjectActions.UpdateSchedulerScenario) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.schedulerScenarioService.updateScenario(objectData.id, objectData.scenario).pipe(
          switchMap((response: UpdateScenarioResponseInterface) => {
            return of(
              ...[
                new AppActions.HideLoader(),
                new ObjectActions.SchedulerScenarioUpdated(response),
                new SchedulerActions.UpdateScenarioDetails(response.data),
                new SchedulerActions.ScenarioPrepared(false),
                new SchedulerActions.ScenarioItemsLoadedToGanttChart(false),
                new SchedulerActions.ClearApplyData(),
                new SchedulerActions.ClearSchedulerData(),
                ...(objectData.isProductionScheduler ? [new SchedulerActions.KpiMetricInfoLoad(objectData.id)] : []),
              ],
            );
          }),
          catchError((error) => {
            return of(
              new AppActions.HideLoader(),
              new ObjectActions.FetchError(error),
              new ObjectActions.ScenarioError(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $bulkEditScenario = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.BulkEditScenario),
      switchMap((objectData: ObjectActions.BulkEditSchedulerScenario) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.schedulerScenarioService.bulkEditScenarios(objectData.scenarios).pipe(
          switchMap((response: BaseOneResponseInterface<ScenarioBulkResponse[]>) => {
            return of(new ObjectActions.BulkEditSchedulerScenarioCompleted(response), new AppActions.HideLoader());
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $cloneScenario = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.CloneSchedulerScenario),
      switchMap((objectData: ObjectActions.CloneSchedulerScenario) => {
        this.store.dispatch(new AppActions.ShowLoader());

        return this.schedulerScenarioService.createScenario(objectData.scenario).pipe(
          switchMap((response: CloneScenarioResponseInterface) => {
            return of(new AppActions.HideLoader(), new ObjectActions.SchedulerScenarioCloned(response));
          }),
          catchError((error) => {
            return of(
              new AppActions.HideLoader(),
              new ObjectActions.FetchError(error),
              new ObjectActions.ScenarioError(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $deleteScenario = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.DeleteSchedulerScenario),
      switchMap((objectData: ObjectActions.DeleteSchedulerScenario) => {
        return this.schedulerScenarioService.deleteScenario(objectData.id).pipe(
          switchMap((response: DeleteScenarioResponseInterface) => {
            return of(new AppActions.HideLoader(), new ObjectActions.SchedulerScenarioDeleted(response));
          }),
          catchError((error) => {
            return of(new ObjectActions.FetchError(error), new ObjectActions.ScenarioError());
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $deleteScenarios = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.BulkDeleteSchedulerScenario),
      switchMap((objectData: ObjectActions.BulkDeleteSchedulerScenario) => {
        this.store.dispatch(new AppActions.ShowLoader());
        return this.schedulerScenarioService.deleteScenarios(objectData.payload).pipe(
          switchMap((response: ScenarioBulkResponseDataInterface) => {
            return of(
              new AppActions.HideLoader(),
              new ObjectActions.BulkDeleteSchedulerScenarioCompleted(objectData.payload.length > 1, response),
            );
          }),
          catchError((error) => {
            return of(
              new ObjectActions.FetchError(error),
              new AppActions.HideLoader(),
              new ObjectActions.ScenarioError(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );

  $loadSchedulerResourceSettings = createEffect(() =>
    this.actions$.pipe(
      ofType(ObjectActions.SchedulerScenarioActionTypes.LoadSchedulerResourceSettings),
      switchMap((objectData: ObjectActions.LoadSchedulerResourceSettings) => {
        this.store.dispatch(new AppActions.ShowLoader());
        const httpParams: HttpParams = new HttpParams()
          .append('join', 'schSettingLine')
          .set('s', JSON.stringify({ siteId: { $eq: objectData.siteId } }));

        return this.schedulerResourceSettingService.getResourceSettings(httpParams).pipe(
          switchMap((response: GetManyResponseInterface<ISchedulerResourceSetting>) => {
            return of(new AppActions.HideLoader(), new ObjectActions.SchedulerResourceSettingsLoaded(response.data));
          }),
          catchError((error) => {
            return of(
              new ObjectActions.FetchError(error),
              new AppActions.HideLoader(),
              new ObjectActions.ScenarioError(),
            );
          }),
        );
      }),
      catchError((error) => {
        return of(new ObjectActions.FetchError(error), new AppActions.HideLoader());
      }),
    ),
  );
}
