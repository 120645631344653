import {Component, OnDestroy, OnInit} from '@angular/core';
import { Line } from '../../../../store/line/model';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { Subscription } from 'rxjs';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../../../constants';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { User } from '../../../../store/user/model';
import {state} from "@angular/animations";
import {OnDestroyDecorator} from "../../../../shared/decorator/on-destroy-decorator";
import {take} from "rxjs/operators";

@OnDestroyDecorator
@Component({
  selector: 'app-shift-oee-metric',
  templateUrl: './shift-oee-metric.component.html',
})
export class ShiftOeeMetricComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  private siteDecimalScaleLimit$: number = DECIMAL_DEFAULT_SCALE_LIMIT;

  public currentShiftName: string = '';
  public shiftBasedOee: string = '0';
  public color: string;

  constructor(
    private readonly decimalHelper: DecimalHelper,
    private readonly store: Store<OeeAppState>,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
        this.siteDecimalScaleLimit$ = state.siteDecimalScaleLimit;
      }),
      this.store.select('line').subscribe((state: Line) => {
        this.currentShiftName = state.quantities.currentShiftName;
        this.shiftBasedOee = this.decimalHelper.multiply(state.quantities.shiftBasedOee, '100');
        this.shiftBasedOee = this.decimalHelper.toFixedValue(this.shiftBasedOee, this.siteDecimalScaleLimit$);
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription) => {
      value.unsubscribe();
    });
  }
}
