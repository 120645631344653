export type TScrapGoodCounts = { scrapCounts: string[], goodCounts: string[] };

export enum ESensorCountType {
  GOOD_COUNTER = 'good_counter',
  SCRAP_COUNTER = 'total_counter',
}

export type TSendVirtualCountsPayload = { type: ESensorCountType, value: string }[];

export type TSendVirtualCountsResponse = {
  cycleTime: string,
  totalCount: string,
  goodCount: string,
  idleTimeDuration: string,
  downTimeUnplannedDuration: string,
  runTimeDuration: string,
  downTimePlannedDuration: string,
};