import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import { ITableHeader } from '../../../../constants.model';
import {
  EWidgetType,
  WidgetConfigurationInterface
} from '../../../shared/component/page-configuration/page-configuration.model';

export enum ECheckInSourceType {
  USER = 1,
  EQUIPMENT = 2,
}

export enum ECheckInDestinationType {
  LINE = 1,
  LINE_STATION = 2,
}

export interface ILaborAssetViewCheckIn {
  checkInId: number;
  checkInTime: string;
  calculatedCheckInTime: string;
  checkOutTime: string | null;
  calculatedCheckOutTime: string;
  duration: number;
}

export interface ILaborAssetViewData {
  fullName: string;
  isAvailable: boolean;
  isOffline: boolean;
  avatarPath: string;
  tagId: number | null;
  tagName: string | null;
  tagColor: string | null;
  destinationTypeId: ECheckInDestinationType;
  destinationObjectId: number;
  lineId: number;
  siteId: number;
  lineName: string;
  stationName: string | null;
  sourceTypeId: ECheckInSourceType;
  sourceObjectId: number;
  levelId: number;
  woNumber: string | null;
  activityStart: string | null;
  minCheckInTime: string;
  maxCheckOutTime: string | null;
  scheduledCheckInTime: string | null;
  scheduledCheckOutTime: string | null;
  scheduledDuration: number | null;
  scheduledDestinationTypeId: number | null;
  scheduledDestinationObjectId: number | null;
  scheduledDestinationName: string | null;
  minCalculatedCheckInTime: string;
  maxCalculatedCheckOutTime: string;
  shiftDuration: number;
  shiftStartDate: string;
  shiftEndDate: string;
  checkIns: ILaborAssetViewCheckIn[];
  tagIds: number[] | null;
}

export interface ILaborAssetViewState {
  laborAssetViewDataLoading: boolean;
  laborAssetViewDataLoaded: boolean;
  laborAssetViewData: ILaborAssetViewData[];
  tableSettings: TabRowInterface[];
  pageConfigurationSettings: IPageConfigurationSettings;
}

export interface IPageConfigurationSettings {
  headers?: ITableHeader[];
  widgets?: WidgetConfigurationInterface<EWidgetType.KPI_CARD>[];
}
