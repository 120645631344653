import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import { ValidationErrorInterface, WorkOrderInterface } from '../../work-order-schedule/work-order-schedule.model';
import { ISelectForm, IStringInputForm } from '../../../shared/model/interface/scw-form.model';
import {
  DropdownOptionInterface,
  ScwMatSelectRule,
} from '../../../shared/component/scw-mat-ui/scw-mat-select/scw-mat-select.model';
import { ScwMatRadioGroupOption } from '../../../shared/component/scw-mat-ui/scw-mat-radio-group/scw-mat-radio-group.model';
import { EWorkOrderStatusKey } from '../../../view/reports/work-order-performance/work-order-performance.model';

export interface IJobsState {
  jobsDataLoading: boolean;
  jobsDataLoaded: boolean;
  jobsData: GetManyResponseInterface<IJob> | null;
  fileDownloadStatus: boolean;
  excelUploadResult: (IJob & { errorMessages?: string })[];
  isUploadExcelLoading: boolean;
  isUploadExcelLoaded: boolean;
  areModalJobDataLoading: boolean;
  areModalJobDataLoaded: boolean;
  modalJobData: GetManyResponseInterface<IJob> | null;
  jobsCountLoading: boolean;
  jobsCountLoaded: boolean;
  jobsCountForExcel: number;
}

export interface IJob {
  id: number;
  siteId: number;
  jobName: string;
  description: string | null;
  isAssignedToWorkOrder?: boolean;
  site?: {
    id: number;
    name: string;
  };
  workOrders?: string;
  workOrdersParsed?: IJobWorkOrder[];
  workOrderUpdateResults?: IWorkOrderUpdateResult[];
  jobStatus?: EJobStatus;
  scheduledStartDate?: string;
  scheduledEndDate?: string;
  minStartDate?: string;
  maxEndDate?: string;
  maxDueDate?: string;
  minReleaseDate?: string;
  overallOee?: string;
  availabilityPerInterval?: string;
  performancePerInterval?: string;
  qualityPerInterval?: string;
  runTimeDuration?: string;
  downTimeDuration?: string;
  idleTimeDuration?: string;
  scheduledPreDuration?: string;
  scheduledRunDuration?: string;
  scheduledPostDuration?: string;
  scheduledPreRunLaborHours?: string;
  scheduledRunLaborHours?: string;
  scheduledPostRunLaborHours?: string;
  totalPlannedDuration?: string;
  totalActualDuration?: string;
  variance?: string;
  varianceDuration?: string;
  formattedVariance?: string;
}

export interface IJobsDownloadExcelFilter {
  siteId: number[] | -1;
  selectedDownloadOffset: string | null;
  limit: number;
}

export interface IJobExcelData {
  jobData: { jobs: IJob[] };
  siteData: { id: number; name: string }[];
}

export interface IJobWorkOrder {
  id: number;
  woNumber: string;
  woStatus: EWorkOrderStatusKey;
}

export interface IJobFormInputs {
  siteId: ISelectForm<DropdownOptionInterface<number>> & {
    defaultValue?: DropdownOptionInterface<number>[] | null;
  };
  jobName: IStringInputForm & { defaultValue?: string | null };
  workOrderIds: ISelectForm<DropdownOptionInterface<number>> & {
    defaultValue?: DropdownOptionInterface<number>[] | null;
  };
  description: IStringInputForm & { defaultValue?: string | null };
}

export interface IGetWorkOrdersForModalParams {
  siteIds: number[];
  searchTerm?: string;
  jobIds?: number[];
}

export interface IWorkOrderUpdateResult {
  id: number;
  message: ValidationErrorInterface;
}

export enum EJobDeleteRadioButtonValues {
  setJobToNull = 'setJobToNull',
  transferJob = 'transferJob',
}

export interface IGetJobsForModalParams {
  siteId: number;
  selectedJobId: number;
  searchTerm?: string;
}

export interface IJobDeleteForm {
  radioButtonValue: EJobDeleteRadioButtonValues;
  defaultRadioButtonValue: EJobDeleteRadioButtonValues;
  radioGroup: ScwMatRadioGroupOption[];
  targetJob: IFormTargetJob;
}

export interface IFormTargetJob {
  value: IDropdownOptions[];
  data: IDropdownOptions[];
  rules: ScwMatSelectRule[];
}

export interface IDropdownOptions {
  id: number;
  name: string;
}

export enum EJobStatus {
  notStarted = 'notStarted',
  inProgress = 'inProgress',
  completed = 'completed',
  canceled = 'canceled',
}

export interface IJobGetManyWithDetailsParams {
  siteId: number;
  jobStatus: EJobStatus[];
  page: number;
  pageSize: number;
  orderBy?: string;
  isOrderDesc?: boolean;
  searchValue?: string;
}
