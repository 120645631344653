import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../../../constants';
import * as _ from 'lodash';
import { ActionsSubject, Store } from '@ngrx/store';
import { Subscription, take } from 'rxjs';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { TranslateService } from '@ngx-translate/core';
import { HelperService } from '../../../../shared/service/helper.service';
import { HomePageDisplaySettingsUtilities } from '../../../settings/home-page-display-settings/home-page-display-settings.utilities';
import { IHourlyOee, Line } from '../../../../store/line/model';
import { User } from '../../../../store/user/model';
import { OeeAppState } from '../../../../store/oee.reducer';
import { IKpiMetricInformation, IPreDefinedKPIMetricsWithProperties } from './speed-metric.model';
import { ISpeedMeta } from '../../../settings/home-page-display-settings/home-metrics/home-metrics.model';
import {
  ECountType,
  EDataSource,
  ETimeSpan,
  EUnitOfTime,
} from '../../../settings/home-page-display-settings/home-page-display-settings.constants';
import { OnDestroyDecorator } from '../../../../shared/decorator/on-destroy-decorator';
import { PROPERTIES } from '../home-metrics.component';
import * as moment from 'moment';
import { mysqlTimestampFormat } from '../../../../shared/helper/date';
import {
  IHomePageDisplaySettingsState,
  ILookup,
} from '../../../../store/settings/home-page-display-settings/home-page-display-settings.model';
import { IUnitConversion } from '../../../../store/settings/unit-conversion/unit-conversion.model';
import { HomeStateInterface } from '../../../../store/home/home.model';

@OnDestroyDecorator
@Component({
  selector: 'app-speed-metric',
  templateUrl: './speed-metric.component.html',
})
export class SpeedMetricComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription[] = [];
  public siteDecimalScaleLimit: number = DECIMAL_DEFAULT_SCALE_LIMIT;
  private timezone: string;
  private hourly: IHourlyOee[] = [];
  private metricCalculationValues: IKpiMetricInformation = this.initializeMetricCalculationValues();
  public actualValue: string = null;
  public expectedValue: string = null;
  public progressBarTitle: string = '--';
  public progressBarRateCapped: number = null;
  public progressBarRate: string = null;
  public actualValueClass: string | null = null;
  public progressBarClass: string | null = null;
  public actualValueUnit: string = '';
  public expectedValueUnit: string = '';
  public iconClasses: string[] = [];
  public properties: ISpeedMeta = null;
  public titleBracketInfo: string = '';
  public productUnit: ILookup = null;
  public unitConversions: IUnitConversion[] = null;
  public baseUnitId: number = null;
  public selectedMetric: IPreDefinedKPIMetricsWithProperties = null;

  constructor(
    private readonly translate: TranslateService,
    private readonly store: Store<OeeAppState>,
    private readonly decimalHelper: DecimalHelper,
    private readonly helperService: HelperService,
    private readonly actions: ActionsSubject,
    @Inject(PROPERTIES) private readonly metricProperties: ISpeedMeta,
  ) {
    this.properties = metricProperties;
  }

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store
        .select('user')
        .pipe(take(1))
        .subscribe((state: User) => {
          this.siteDecimalScaleLimit = state.siteDecimalScaleLimit;
          this.timezone = state.timezone;
        }),
      this.store.select('homeStore').subscribe((state: HomeStateInterface) => {
        if (!state.homeInformationLoading && state.homeMetricSetItemsLoaded) {
          this.baseUnitId = HelperService.cloneDeep(state.line.workOrderCard.unitId);
        }
      }),
      this.store.select('homePageDisplaySettingsStore').subscribe((state: IHomePageDisplaySettingsState) => {
        if (!state.isProductUnitsLoading && state.isProductUnitsLoaded) {
          const selectedUnitId: number = Number(
            this.properties?.unitOfCount === '0' ? this.baseUnitId : this.properties?.unitOfCount,
          );
          this.productUnit = state.productUnits.find((unit: ILookup) => unit.id === selectedUnitId);
        }

        if (!state.isProductUnitConversionsLoading && state.isProductUnitConversionsLoaded) {
          this.unitConversions = state.productUnitConversions;
          this.calculateActualAndExpectedValues(HelperService.cloneDeep(this.selectedMetric));
        }
      }),
      this.store.select('line').subscribe((state: Line) => {
        this.updateMetricCalculationValues(HelperService.cloneDeep(state));
        const speedMetricInfo: IPreDefinedKPIMetricsWithProperties[] =
          HomePageDisplaySettingsUtilities.getPreDefinedSpeedMetricsWithProperties(
            this.translate,
            this.decimalHelper,
            this.metricCalculationValues,
            this.properties,
          );

        if (state.unitName) {
          const unitValue: string = this.productUnit?.name ? this.productUnit?.name : state.unitName;
          const unitKey: string = `homePageDisplaySettings.unit.${_.camelCase(unitValue)}By${
            this.properties.unitOfTime === EUnitOfTime.HOUR ? 'Hour' : 'Minute'
          }.formula`;

          this.actualValueUnit = this.translate.instant(unitKey);
          this.expectedValueUnit = this.translate.instant(unitKey);
        }

        this.selectedMetric = _.find(speedMetricInfo, { meta: this.properties });
        this.progressBarTitle = this.selectedMetric?.name;
        this.iconClasses = this.selectedMetric?.iconClass;

        this.titleBracketInfo = this.selectedMetric?.meta.countType
          ? HomePageDisplaySettingsUtilities.setTitleBracketInfo(
              this.translate,
              this.selectedMetric?.meta.dataSource,
              this.selectedMetric?.meta.countType,
              this.selectedMetric?.meta.timeSpan,
            )
          : '';

        this.calculateActualAndExpectedValues(HelperService.cloneDeep(this.selectedMetric));
      }),
    );
  }

  private initializeMetricCalculationValues(): IKpiMetricInformation {
    return {
      expectedSpeed: null,
      totalDuration: null,
      initialSensorCountInOneMinute: null,
      initialSensorCountInTenMinutes: null,
      initialSensorCount: null,
      initialCount: null,
      goodSensorCountInOneMinute: null,
      goodSensorCountInTenMinutes: null,
      goodSensorCount: null,
      goodCount: null,
      runDuration: null,
      downDuration: null,
      idleDuration: null,
      runDownDuration: null,
      currentActivityDuration: null,
      conversionMultiplier: null,
    };
  }

  private updateMetricCalculationValues(state: Line): void {
    const now: string = moment().tz(this.timezone).format(mysqlTimestampFormat);
    this.metricCalculationValues = {
      ...this.metricCalculationValues,
      expectedSpeed: state.expectedSpeed,
      totalDuration: state.quantities.totalDuration,
      initialSensorCountInOneMinute: state.quantities.initialSensorCountInOneMinute,
      initialSensorCountInTenMinutes: state.quantities.initialSensorCountInTenMinutes,
      initialSensorCount: state.quantities.initialSensorCount,
      initialCount: state.quantities.initialCount,
      goodSensorCountInOneMinute: state.quantities.goodSensorCountInOneMinute,
      goodSensorCountInTenMinutes: state.quantities.goodSensorCountInTenMinutes,
      goodSensorCount: state.quantities.goodSensorCount,
      goodCount: state.quantities.goodCount,
      runDuration: state.quantities.runDuration,
      downDuration: state.quantities.downDuration,
      idleDuration: state.quantities.idleDuration,
      currentActivityDuration: moment(now)
        .diff(moment(state.activityStart).format(mysqlTimestampFormat), 'seconds')
        .toString(),
      runDownDuration: this.decimalHelper.add(state.quantities.runDuration, state.quantities.downDuration),
    };
    this.hourly = state.quantities.hourlyOee;
  }

  public setProductUnitConversation(): void {
    const unitConversion: IUnitConversion = this.unitConversions?.find(
      (conversion: IUnitConversion) => conversion.referenceUnitId === this.productUnit.id,
    );
    const multiplier: string = this.productUnit.id === this.baseUnitId ? '1' : unitConversion?.multiplier;

    if (multiplier) {
      this.actualValue = this.decimalHelper.multiply(this.actualValue, multiplier);
      this.expectedValue = this.decimalHelper.multiply(this.expectedValue, multiplier);
    } else {
      this.actualValue = null;
      this.expectedValue = null;
      this.progressBarRateCapped = null;
      this.progressBarRate = null;
    }
  }

  private getTimeSpan(timeSpan: ETimeSpan): number {
    switch (timeSpan) {
      case ETimeSpan.LAST_ONE_HOUR:
        return 1;
      case ETimeSpan.LAST_TWO_HOURS:
        return 2;
      case ETimeSpan.LAST_THREE_HOURS:
        return 3;
      default:
        return 0;
    }
  }

  private calculateActualAndExpectedValues(selectedMetric: IPreDefinedKPIMetricsWithProperties): void {
    this.expectedValue = this.decimalHelper.removeThousandSeparator(selectedMetric?.formula.expectedValue);
    this.expectedValue = this.decimalHelper.sanitizeString(this.expectedValue);

    if (selectedMetric?.meta.dataSource === EDataSource.HOURLY) {
      const timeSpan: number = this.getTimeSpan(selectedMetric?.meta.timeSpan);
      this.actualValue = '0';

      for (let i = 0; i < timeSpan; i = i + 1) {
        if (selectedMetric?.meta.countType === ECountType.GOOD) {
          this.actualValue = this.decimalHelper.add(
            _.get(this.hourly[i]?.calculationValues, 'goodCount'),
            this.actualValue,
          );
        } else if (selectedMetric?.meta.countType === ECountType.INITIAL) {
          const initialCount: string = this.decimalHelper.add(
            _.get(this.hourly[i]?.calculationValues, 'goodCount'),
            _.get(this.hourly[i]?.calculationValues, 'scrapCount'),
          );

          this.actualValue = this.decimalHelper.add(initialCount, this.actualValue);
        }
      }
    } else {
      this.actualValue = selectedMetric?.formula.actualValue;

      if (
        selectedMetric?.meta.dataSource === EDataSource.WORK_ORDER &&
        selectedMetric?.meta.unitOfTime === EUnitOfTime.HOUR
      ) {
        this.actualValue = this.decimalHelper.multiply(this.actualValue, '60');
        this.expectedValue = this.decimalHelper.multiply(this.expectedValue, '60');
      }

      if (this.properties.dataSource === EDataSource.WORK_ORDER && !_.isNil(this.productUnit)) {
        this.setProductUnitConversation();
      }
    }

    this.progressBarRateCapped = this.decimalHelper.decimalToNumberFormatter(
      this.decimalHelper.calculateRate(
        this.actualValue,
        this.expectedValue,
        DECIMAL_DEFAULT_SCALE_LIMIT,
        false,
        true,
        true,
      ),
    );

    this.progressBarRate = this.decimalHelper.calculateRate(
      this.actualValue,
      this.expectedValue,
      DECIMAL_DEFAULT_SCALE_LIMIT,
    );

    this.actualValueClass = this.helperService.findClass(this.progressBarRateCapped.toString()).color;
    this.progressBarClass = this.helperService.findClass(this.progressBarRateCapped.toString()).backgroundColor;
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription) => {
      value.unsubscribe();
    });
  }
}
