<ng-template [ngIf]="showMetricInfo">
  <div class="alert alert-info icons-alert">
    <p>{{ sampleDataWarning }}</p>
  </div>
</ng-template>
<div class="modal-container">
  <div class="row">
    <div class="col-12 empty-container">
      <ng-container *ngFor="let item of kpiCardsInformation">
        <div
          class="col-12 mb-3 d-flex justify-content-center"
          [ngClass]="showMetricInfo ? 'info-container-size' : 'container-size'"
        >
          <div class="d-flex align-items-center h-100" [ngClass]="showMetricInfo ? 'w-60' : 'w-100'">
            <ng-container *ngIf="item.metricType === 'progressBar' || item.metricType === 'count'">
              <progress-bar-metric
                class="w-100 h-100 position-relative"
                [actualValue]="item.actualValue"
                [expectedValue]="item.expectedValue"
                [showExpectedValue]="item.showExpectedValue"
                [progressBarTitle]="item.metricName"
                [progressBarRateCapped]="item.progressBarRateCapped"
                [progressBarRate]="item.progressBarRate"
                [showProgressBar]="item.showProgressBar"
                [actualValueClass]="item.actualValueClass"
                [actualValueUnit]="item.unit"
                [expectedValueUnit]="item.unit"
                [showMetricInfo]="showMetricInfo"
                [actualValueInfoText]="item.actualFormula"
                [expectedValueInfoText]="item.expectedFormula"
                [icons]="item.icons"
                [titleBracketInfo]="item.titleBracketInfo"
              ></progress-bar-metric>
            </ng-container>
            <ng-container *ngIf="item.metricType === 'badge'">
              <app-count-badges-metric
                class="w-100 h-100 position-relative"
                [goodCount]="item.expectedValue"
                [scrapCount]="item.actualValue"
                [showMetricInfo]="showMetricInfo"
                [goodCountInfoText]="item.actualFormula"
                [scrapCountInfoText]="item.expectedFormula"
                [goodCountUnit]="item.unit"
                [scrapCountUnit]="item.unit"
              ></app-count-badges-metric>
            </ng-container>
            <ng-container *ngIf="item.metricType === 'oee'">
              <oee-metric
                *ngIf="item.metricKey === EHomeMetrics.WORK_ORDER_OEE; else else1"
                class="w-100 h-100 position-relative"
                [oee]="'50'"
                [target]=""
                [cardTitleKey]="item.metricKey"
                [color]="greenColor"
                [showMetricInfo]="showMetricInfo"
                [oeeInfoText]="item.actualFormula"
              >
              </oee-metric>
              <ng-template #else1>
                <oee-metric
                  *ngIf="item.metricKey === EHomeMetrics.SHIFT_OEE; else else2"
                  class="w-100 h-100 position-relative"
                  [subtitle]="'kpiCardInformation.shiftOee.currentShiftName' | translate"
                  [oee]="'50'"
                  [cardTitleKey]="item.metricKey"
                  [hideTarget]="true"
                  [showMetricInfo]="showMetricInfo"
                  [oeeInfoText]="item.actualFormula"
                >
                </oee-metric>
              </ng-template>
              <ng-template #else2>
                <oee-metric
                  *ngIf="item.metricKey === EHomeMetrics.JOB_OEE"
                  class="w-100 h-100 position-relative"
                  [oee]="'50'"
                  [target]=""
                  [cardTitleKey]="item.metricKey"
                  [color]="greenColor"
                  [showMetricInfo]="showMetricInfo"
                  [oeeInfoText]="item.actualFormula"
                >
                </oee-metric>
              </ng-template>
            </ng-container>
            <ng-container *ngIf="item.metricType === 'hourlyOee'">
              <app-chart-metric
                class="w-100 h-100 position-relative"
                [title]="item.metricName"
                [data]="item.actualValueForHourly"
                [percentageData]="item.actualValueForHourly"
                [target]="item.expectedValue"
                [averageOee]="'50'"
                [showMetricInfo]="showMetricInfo"
                [averageValueInfoText]="item.actualFormula"
              ></app-chart-metric>
            </ng-container>
            <ng-container *ngIf="item.metricType === 'activitiesTotalDuration'">
              <app-activities-total-duration-metric
                class="w-100 h-100 position-relative"
                [runTimeDuration]="item.expectedValue"
                [downTimeUnplannedDuration]="item.actualValue"
                [downTimePlannedDuration]="item.actualValue"
                [idleTimeDuration]="item.actualValue"
                [unit]="item.unit"
              ></app-activities-total-duration-metric>
            </ng-container>
            <ng-container *ngIf="item.metricType === 'multipleUnits'">
              <app-multiple-units-metric
                class="w-100 h-100 position-relative"
                [actualValue]="item.actualValue"
                [titleBracketInfo]="item.titleBracketInfo"
                [unitOfCount]="item.unitOfCount"
                [fromMetricInfo]="true"
                [unitOfTime]="item.unitOfTime"
              >
              </app-multiple-units-metric>
            </ng-container>
          </div>
        </div>
        <div *ngIf="showMetricInfo" class="col-12 bg-c-lightgrey">
          <div class="row p-2">
            <div class="col border-right">
              <p class="f-12">
                <strong class="f-14">{{ item.actualFormula }}: </strong> {{ item.actualValueDescription }}
              </p>
              <p *ngIf="item.formulaValue !== ''" class="f-12">
                <strong class="f-14"> {{ item.formulaValue }}: </strong> {{ item.formulaValueDescription }}
              </p>
              <div *ngIf="item.info !== ''" class="metric-info-list" [innerHTML]="item.info"></div>
            </div>
            <div *ngIf="item.showExpectedValue" class="col">
              <p class="grey-text f-12">
                <strong class="f-14">{{ item.expectedFormula + ':' }} </strong> {{ item.expectedValueDescription }}
              </p>
            </div>
          </div>
        </div>
        <hr *ngIf="showMetricInfo" class="metric-hr" />
      </ng-container>
    </div>
  </div>
</div>
