import * as Actions from './skill-matrix-elastic.actions';
import { ISkillMatrixElasticState } from './skill-matrix-elastic.model';

export const skillMatrixInitialState: ISkillMatrixElasticState = {
  isSkillMatrixElasticDataLoading: false,
  isSkillMatrixElasticDataLoaded: false,
  skillMatrixElasticData: {
    productsNameArray: [],
    usernameArray: [],
    rawData: [],
    maxDurationInHour: null,
    minDurationInHour: null,
    chartData: [],
    d3ChartData: [],
  },
};

export function skillMatrixElasticReducer(
  state: ISkillMatrixElasticState = skillMatrixInitialState,
  action: Actions.SkillMatrixElasticActions,
): ISkillMatrixElasticState {
  switch (action.type) {
    case Actions.SKILL_MATRIX_ELASTIC_DATA_LOADING:
      return {
        ...state,
        isSkillMatrixElasticDataLoading: true,
        isSkillMatrixElasticDataLoaded: false,
      };
    case Actions.SKILL_MATRIX_ELASTIC_DATA_LOADED:
      return {
        ...state,
        isSkillMatrixElasticDataLoading: false,
        isSkillMatrixElasticDataLoaded: true,
        skillMatrixElasticData: action.payload,
      };
    case Actions.RESET_SKILL_MATRIX_ELASTIC_DATA:
      return {
        ...state,
        isSkillMatrixElasticDataLoading: false,
        isSkillMatrixElasticDataLoaded: true,
        skillMatrixElasticData: skillMatrixInitialState.skillMatrixElasticData,
      };
    case Actions.FETCH_DATA_ERROR:
      return {
        ...state,
        isSkillMatrixElasticDataLoading: false,
        isSkillMatrixElasticDataLoaded: true,
      };
    default:
      return state;
  }
}
