import { Action } from '@ngrx/store';
import { ISkillMatrixElasticFilter, ISkillMatrixElasticData } from './skill-matrix-elastic.model';

export const SKILL_MATRIX_ELASTIC_DATA_LOADING = '[SKILL MATRIX ELASTIC] SKILL MATRIX ELASTIC DATA LOADING';
export const SKILL_MATRIX_ELASTIC_DATA_LOADED = '[SKILL MATRIX ELASTIC] SKILL MATRIX ELASTIC DATA LOADED';
export const RESET_SKILL_MATRIX_ELASTIC_DATA = '[SKILL MATRIX ELASTIC] RESET SKILL MATRIX ELASTIC DATA';
export const FETCH_DATA_ERROR = '[SKILL MATRIX ELASTIC] FETCH DATA ERROR';

export class SkillMatrixElasticDataLoading implements Action {
  readonly type = SKILL_MATRIX_ELASTIC_DATA_LOADING;

  constructor(public params: ISkillMatrixElasticFilter) {}
}

export class SkillMatrixElasticDataLoaded implements Action {
  readonly type = SKILL_MATRIX_ELASTIC_DATA_LOADED;

  constructor(public payload: ISkillMatrixElasticData) {}
}

export class ResetSkillMatrixElasticData implements Action {
  readonly type = RESET_SKILL_MATRIX_ELASTIC_DATA;
}

export class FetchDataError implements Action {
  readonly type = FETCH_DATA_ERROR;

  constructor(public payload: object) {}
}

export type SkillMatrixElasticActions =
  | SkillMatrixElasticDataLoading
  | SkillMatrixElasticDataLoaded
  | ResetSkillMatrixElasticData
  | FetchDataError;
