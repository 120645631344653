import { createReducer, on } from '@ngrx/store';
import { IStationHomeKPIState } from './kpi-card.model';
import * as StationHomeKPIActions from './kpi-card.actions';
import * as SemiManualCountButtonCardActions from './semi-manual-count-button-card/semi-manual-count-button-card.actions';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';

const initialState: IStationHomeKPIState = {
  goodCount: '0',
  totalCount: '0',
  stationQuantities: {
    cycleTime: '0',
    totalCount: '0',
    goodCount: '0',
    runTimeDuration: '0',
    downTimeUnplannedDuration: '0',
    downTimePlannedDuration: '0',
    idleTimeDuration: '0',
  },
};

export const stationHomeKPIReducer = createReducer(
  initialState,
  on(StationHomeKPIActions.increaseGoodCount, (state, { count }) => ({
    ...state,
    goodCount: DecimalHelper.add1(count || '1', state.goodCount),
  })),
  on(StationHomeKPIActions.increaseTotalCount, (state, { count }) => ({
    ...state,
    totalCount: DecimalHelper.add1(count || '1', state.totalCount),
  })),
  on(StationHomeKPIActions.setCounts, (state, { goodCount, totalCount: scrapCount }) => ({
    ...state,
    goodCount: goodCount || state.goodCount,
    totalCount: scrapCount || state.totalCount,
  })),
  on(StationHomeKPIActions.fetchStationQuantitiesSuccess, (state, quantities) => ({
    ...state,
    goodCount: quantities.goodCount,
    totalCount: quantities.totalCount,
    stationQuantities: {
      totalCount: quantities.totalCount,
      runTimeDuration: quantities.runTimeDuration,
      idleTimeDuration: quantities.idleTimeDuration,
      goodCount: quantities.goodCount,
      downTimeUnplannedDuration: quantities.downTimeUnplannedDuration,
      downTimePlannedDuration: quantities.downTimePlannedDuration,
      cycleTime: quantities.cycleTime,
    },
  })),
);
