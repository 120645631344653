import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { OeeAppState } from '../../oee.reducer';
import { Store } from '@ngrx/store';
import { GetManyResponseInterface } from '../../../shared/model/interface/crud-response-interface.model';
import {
  IOeeCalculationResultFormatted,
  ResponseInterface,
} from '../../../shared/model/interface/generic-api-response.model';
import {
  IOeeWaterfallAnalysisOeeParameters,
  IOeeWaterfallAnalysisRootCauseAnalysisParameters,
  ITargetDatum,
  ITargetQueryParameters,
  IWaterfallAnalysisExcelFormattedActivityData,
  IWaterfallAnalysisExcelFormattedFilterData,
  IWaterfallAnalysisExcelFormattedTaskData,
  IWaterfallAnalysisTableData,
} from './oee-waterfall-analysis.model';
import { Observable, Subject } from 'rxjs';
import { IRootCauseAnalysisChartNode } from '../root-cause-analysis/root-cause-analysis.model';
import moment from 'moment';
import { mysqlDateFormat } from '../../../shared/helper/date';
import { TranslateService } from '@ngx-translate/core';
import { takeUntil } from 'rxjs/operators';
import { excelDateFormat, excelTimeFormat } from '../../../shared/model/enum/excel-date-format';
import { ICreateExcel, ICreateExcelSheet, IExcelColumnDefinition } from '../../../shared/service/excel/excel.helper';
import { ECellTypes, EExcelColumnWidth, EExcelSheetType } from '../../../shared/service/excel/excel.enum';
import { ValueType } from 'exceljs';
import { ExcelHelperService } from '../../../shared/service/excel/excel.helper.service';
import * as ObjectActions from './oee-waterfall-analysis.actions';
import { DecimalHelper } from '../../../shared/helper/decimal/decimal-helper';

@Injectable({
  providedIn: 'root',
})
export class OeeWaterfallAnalysisService {
  private readonly routes = {
    oee: `${this.baseUrl}/oee-calculation/calculate-oee`,
    rootCauseAnalysis: `${this.baseUrl}/activity-histories/root-cause-analysis`,
    targets: `${this.baseUrl}/targets`,
  };

  private timezone: string = 'utc';
  private dateFormat$: string;
  private timeFormat$: string;

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly baseUrl: string,
    private readonly store: Store<OeeAppState>,
    private translate: TranslateService,
    private excelHelper: ExcelHelperService,
    private decimalHelper: DecimalHelper,
  ) {
    const destroySubject: Subject<boolean> = new Subject<boolean>();
    this.store
      .select('user')
      .pipe(takeUntil(destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.timezone = state.timezone;

          if (state.locale !== '') {
            this.dateFormat$ = excelDateFormat[state.locale];
            this.timeFormat$ = excelTimeFormat[state.locale];
          }

          destroySubject.next(true);
          destroySubject.complete();
        }
      });
  }

  public getOeeData(
    body: IOeeWaterfallAnalysisOeeParameters,
  ): Observable<GetManyResponseInterface<IOeeCalculationResultFormatted>> {
    return this.http.post<GetManyResponseInterface<IOeeCalculationResultFormatted>>(this.routes.oee, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public getRootCauseAnalysisData(
    body: IOeeWaterfallAnalysisRootCauseAnalysisParameters,
  ): Observable<ResponseInterface<IRootCauseAnalysisChartNode[]>> {
    return this.http.post<ResponseInterface<IRootCauseAnalysisChartNode[]>>(`${this.routes.rootCauseAnalysis}`, body, {
      headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
    });
  }

  public getTargetData(queryParams: ITargetQueryParameters): Observable<GetManyResponseInterface<ITargetDatum>> {
    const filter: { [key: string]: any } = {
      $and: [
        {
          target_type: {
            $eq: 'OEE',
          },
        },
        {
          $and: [
            {
              target_period: {
                $gte: moment(queryParams.start).startOf('month').format(mysqlDateFormat),
              },
            },
            {
              target_period: {
                $lt: moment(queryParams.end).add(1, 'month').startOf('month').format(mysqlDateFormat),
              },
            },
            {
              'line.site_id': {
                in: queryParams.siteIds,
              },
            },
          ],
        },
        queryParams.lineIds
          ? {
              line_id: {
                in: queryParams.lineIds,
              },
            }
          : {},
      ],
    };
    const httpParams: HttpParams = new HttpParams()
      .append('limit', 1000)
      .append('join', 'line||siteId')
      .append('s', JSON.stringify(filter));

    return this.http.get<GetManyResponseInterface<ITargetDatum>>(`${this.routes.targets}`, {
      params: httpParams,
    });
  }

  public downloadExcel(
    filterData: IWaterfallAnalysisExcelFormattedFilterData,
    activityData: IWaterfallAnalysisTableData[],
    taskData: IRootCauseAnalysisChartNode[],
  ) {
    const pageTitle: string = this.translate.instant('pageTitles.oee_waterfall_analysis');
    const excelName: string = `${pageTitle} ${moment().tz(this.timezone).format(this.dateFormat$)}`;

    const filterExcelOptions: ICreateExcel = {
      data: [filterData],
      columns: this.getFiltersColumnsForExcel(),
    };

    const activityExcelOptions: ICreateExcel = {
      data: this.formatActivityDataForExcel(activityData),
      columns: this.getActivityColumnsForExcel(),
    };

    const taskExcelOptions: ICreateExcel = {
      data: this.formatTaskDataForExcel(taskData),
      columns: this.getTaskColumnsForExcel(),
    };

    const worksheets: ICreateExcelSheet[] = [
      {
        sheetTitle: this.translate.instant('excel.items.filters'),
        withData: true,
        sheetType: EExcelSheetType.TABLE,
        params: filterExcelOptions,
      },
      {
        sheetTitle: this.translate.instant('excel.items.activities'),
        withData: true,
        sheetType: EExcelSheetType.TABLE,
        params: activityExcelOptions,
      },
      {
        sheetTitle: this.translate.instant('excel.items.tasks'),
        withData: true,
        sheetType: EExcelSheetType.TABLE,
        params: taskExcelOptions,
      },
    ];

    this.excelHelper
      .createExcel(
        excelName,
        { name: 'oeeWaterfall', withData: true, siteId: filterData?.siteId },
        worksheets,
        this.timezone,
        this.dateFormat$,
        this.timeFormat$,
      ).then(
      () => {
        this.store.dispatch(new ObjectActions.DownloadExcelCompleted());
      },
      () => {
        this.store.dispatch(new ObjectActions.FetchError(null));
      },
    );
  }

  private getFiltersColumnsForExcel(): IExcelColumnDefinition[] {
    return [
      {
        header: this.translate.instant('waterfallAnalysis.excel.startDate.header'),
        key: 'startDate',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.DATE,
        },
      },
      {
        header: this.translate.instant('waterfallAnalysis.excel.endDate.header'),
        key: 'endDate',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.DATE,
        },
      },
      {
        header: this.translate.instant('waterfallAnalysis.excel.siteName.header'),
        key: 'siteName',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
      {
        header: this.translate.instant('waterfallAnalysis.excel.lineNames.header'),
        key: 'lineNames',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
    ];
  }

  private getActivityColumnsForExcel(): IExcelColumnDefinition[] {
    return [
      {
        header: this.translate.instant('waterfallAnalysis.excel.category.header'),
        key: 'category',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.DATE,
        },
      },
      {
        header: this.translate.instant('waterfallAnalysis.excel.lossesDueTo.header'),
        key: 'lossesDueTo',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.DATE,
        },
      },
      {
        header: this.translate.instant('waterfallAnalysis.excel.hoursLost.header'),
        key: 'hoursLost',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.DATE,
        },
      },
      {
        header: this.translate.instant('waterfallAnalysis.excel.oeeLoss.header'),
        key: 'oeeLoss',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.DATE,
        },
      },
      {
        header: this.translate.instant('waterfallAnalysis.excel.teepLoss.header'),
        key: 'teepLoss',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: ECellTypes.DATE,
        },
      },
    ];
  }

  private getTaskColumnsForExcel(): IExcelColumnDefinition[] {
    return [
      {
        header: this.translate.instant('waterfallAnalysis.excel.activity.header'),
        key: 'activity',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
      {
        header: this.translate.instant('general.dataTable.header.lineName'),
        key: 'lineName',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
      {
        header: this.translate.instant('waterfallAnalysis.excel.task.header'),
        key: 'task',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
      {
        header: this.translate.instant('waterfallAnalysis.excel.hoursLost.header'),
        key: 'hoursLost',
        width: EExcelColumnWidth.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: null,
      },
    ];
  }

  private formatActivityDataForExcel(
    activityData: IWaterfallAnalysisTableData[],
  ): IWaterfallAnalysisExcelFormattedActivityData[] {
    return activityData.map(
      (activityDatum: IWaterfallAnalysisTableData): IWaterfallAnalysisExcelFormattedActivityData => ({
        category: activityDatum.category,
        lossesDueTo: activityDatum.lossesDueTo,
        hoursLost:
          this.decimalHelper.toFixedValue(this.decimalHelper.convertToDecimal(activityDatum.hoursLost / 3600)) || '0',
        oeeLoss: activityDatum.oeeLoss ? activityDatum.oeeLoss.toString() : '-',
        teepLoss: activityDatum.teepLoss?.toString(),
      }),
    );
  }

  private formatTaskDataForExcel(taskData: IRootCauseAnalysisChartNode[]): IWaterfallAnalysisExcelFormattedTaskData[] {
    return taskData
      .sort((lhs: IRootCauseAnalysisChartNode, rhs: IRootCauseAnalysisChartNode) =>
        lhs.duration > rhs.duration ? -1 : lhs.duration === rhs.duration ? 0 : 1,
      )
      .map(
        (task: IRootCauseAnalysisChartNode): IWaterfallAnalysisExcelFormattedTaskData => ({
          activity: task.activityName,
          task: task.taskName,
          lineName: task.lineName,
          hoursLost: this.decimalHelper.toFixedValue(this.decimalHelper.convertToDecimal(task.duration / 3600)) || '0',
        }),
      );
  }
}
