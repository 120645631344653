import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import {
  ECheckInDestinationType,
  ECheckInSourceType,
  ILaborAssetViewData,
} from '../../../../store/dashboards/labor-asset-view/labor-asset-view.model';
import {
  ECicoAvailability,
  ECicoDestinationType,
  ECicoType,
  ICicoData,
} from '../../../../shared/component/cico/cico.model';
import { TranslateService } from '@ngx-translate/core';
import { EQueryType } from '../labor-asset-view.model';
import * as _ from 'lodash';

@Component({
  selector: 'scw-labor-asset-view-card-view',
  templateUrl: './labor-asset-view-card-view.component.html',
  styleUrls: ['./labor-asset-view-card-view.component.scss'],
})
export class LaborAssetViewCardViewComponent implements OnChanges {
  @Input() laborAssetViewData: ILaborAssetViewData[] = [];
  @Input() timeToggle!: EQueryType;
  @Input() isExpanded: boolean = false;
  @Input() selectMode: boolean = false;
  @Input() showWorkOrderRow: boolean = true;
  @Input() isMatMenuVisible: boolean = true;
  @Output() onChangeSelectedItems: EventEmitter<number[]> = new EventEmitter<number[]>();

  private readonly userDefaultIconCls: string = 'fas fa-user';
  private readonly assetDefaultIconCls: string = 'fas fa-cube';
  public cicoItems: ICicoData[] = [];
  public progressBarText: string = this.translate.instant('cico.operatingTimeAndShiftDuration');

  constructor(private readonly translate: TranslateService) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('laborAssetViewData') && Array.isArray(this.laborAssetViewData)) {
      this.progressBarText = this.translate.instant(
        this.timeToggle === EQueryType.ONGOING ? 'cico.operatingTimeAndShiftDuration' : 'cico.operatingTimeAnd24Hours',
      );

      this.prepareCicoData();
    }
  }

  private prepareCicoData(): void {
    this.cicoItems = this.laborAssetViewData.map((data: ILaborAssetViewData): ICicoData => {
      return {
        id: (_.find(data.checkIns, { checkOutTime: null }) ?? _.last(data.checkIns)).checkInId,
        fullName: data.fullName,
        status: data.isOffline
          ? ECicoAvailability.offline
          : data.isAvailable
          ? ECicoAvailability.available
          : ECicoAvailability.busy,
        tagId: data.tagId,
        tagName: data.tagName,
        tagColor: data.tagColor,
        avatarPath: data.avatarPath,
        avatarIsLoading: false,
        avatar: null,
        destination: {
          type:
            data.destinationTypeId === ECheckInDestinationType.LINE
              ? ECicoDestinationType.LINE
              : ECicoDestinationType.STATION,
          object: {
            objectId: data.destinationObjectId,
            objectName:
              data.destinationTypeId === ECheckInDestinationType.LINE_STATION ? data.stationName : data.lineName,
            lineName: data.lineName,
          },
        },
        lineId: data.lineId,
        siteId: data.siteId,
        checkInTime: data.minCalculatedCheckInTime,
        actualCheckInTime: data.minCheckInTime,
        checkOutTime: data.maxCalculatedCheckOutTime,
        actualCheckOutTime: data.maxCheckOutTime,
        type: data.sourceTypeId === ECheckInSourceType.USER ? ECicoType.LABOR : ECicoType.ASSET,
        iconCls: data.sourceTypeId === ECheckInSourceType.USER ? this.userDefaultIconCls : this.assetDefaultIconCls,
        scheduledCheckInTime: data.scheduledCheckInTime,
        scheduledCheckOutTime: data.scheduledCheckOutTime,
        scheduledDuration: data.scheduledDuration,
        scheduledDestination: {
          type:
            data.scheduledDestinationTypeId === ECheckInDestinationType.LINE
              ? ECicoDestinationType.LINE
              : ECicoDestinationType.STATION,
          object: {
            objectId: data.scheduledDestinationObjectId,
            objectName: data.scheduledDestinationName,
          },
        },
        shiftDuration: data.shiftDuration,
        shiftStartDate: data.shiftStartDate,
        shiftEndDate: data.shiftEndDate,
        meta: {
          levelId: data.levelId,
          woNumber: data.woNumber,
        },
        checkIns: data.checkIns,
        tagIds: data.tagIds,
        sourceObjectId: data.sourceObjectId,
      };
    });
  }

  public onCicoItemSelectionChange(selectedItems: number[]): void {
    this.onChangeSelectedItems.emit(selectedItems);
  }
}
