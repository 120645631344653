import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { AllSettledResolvedInterface, ResponseArrayInterface } from '../../model/interface/generic-api-response.model';
import {
  FormattedSaveLaborSchedulerItemInterface,
  LaborSchedulerGanttAllItemsResponseInterface,
  ILaborSchedulerItemResponse,
  SaveLaborSchedulerItemInterface,
  ITeamsResponse,
} from './labor-scheduler.interface';
import { forkJoin, from, Observable, Subject } from 'rxjs';
import { TaskType } from '../../../view/scheduler/gantt-view/gantt-view.model';
import moment from 'moment';
import { mysqlTimestampFormat } from '../../helper/date';
import { CheckInTableQueryParams } from '../../../view/reports/check-in-logs/check-in-logs.model';
import { map, takeUntil } from 'rxjs/operators';
import { CheckInLogService } from '../../../store/reports/check-in-log/check-in-log.service';
import {
  CheckInLogTableResponseInterface,
  CheckInTableGroupedByNoneInterface,
  GroupedByTypes,
} from '../../../store/reports/check-in-log/check-in-log.model';
import {
  ILaborSchedulerDownloadExcelPayload,
  ILaborSchedulerListViewItem,
} from '../../../view/labor-scheduler/labor-scheduler-list-view/labor-scheduler-list-view.model';
import {
  CellTypes,
  CreateExcelInterface,
  CreateExcelSheetInterface,
  ExcelColumnDefinitionInterface,
  ExcelColumnWidthEnum,
  ExcelDateFormatInformationInterface,
  ExcelHelperService,
  ExcelSheetTypeEnum,
} from '../excel/excel-helper.service';
import { ITableHeader } from '../../../../constants.model';
import { ValueType } from 'exceljs';
import * as oeeAppReducer from '../../../store/oee.reducer';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as LaborSchedulerActions from '../../../store/labor-scheduler/labor-scheduler.actions';
import * as AppActions from '../../../store/app/actions';
import { HelperService } from '../helper.service';

@Injectable({
  providedIn: 'root',
})
export class LaborSchedulerService {
  private readonly routes = {
    laborSchedulerItems: '/labor-scheduler-items',
    laborSchedulerItemsSaveAndDeploy: '/labor-scheduler-items/save-and-deploy',
    teams: '/teams',
  };
  private readonly destroySubject: Subject<boolean> = new Subject<boolean>();
  private dateFormatInformation: ExcelDateFormatInformationInterface;

  constructor(
    private readonly store: Store<oeeAppReducer.OeeAppState>,
    private readonly excelHelperService: ExcelHelperService,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    private checkInLogService: CheckInLogService,
  ) {
    this.store
      .select('user')
      .pipe(takeUntil(this.destroySubject))
      .subscribe((state) => {
        if (state.isUserLoaded) {
          this.dateFormatInformation = {
            timezone: state.timezone,
            dateFormat$: state.dateFormat,
            timeFormat$: state.timeFormat,
            locale$: state.locale,
            dateFormatRaw$: state.dateFormat,
            dateTimeFormatRaw$: state.dateTimeFormatWithSecond,
          };
          this.destroySubject.next(true);
          this.destroySubject.complete();
        }
      });
  }

  public loadLaborSchedulerItems(scenarioId: number): Observable<ResponseArrayInterface<ILaborSchedulerItemResponse>> {
    const params: HttpParams = new HttpParams().append('join', 'team').set('scenarioId', scenarioId.toString());
    return this.http.get<ResponseArrayInterface<ILaborSchedulerItemResponse>>(
      `${this.api}${this.routes.laborSchedulerItems}`,
      {
        params,
      },
    );
  }

  public saveLaborSchedulerItems(
    scenarioId: number,
    saveLaborSchedulerItems: FormattedSaveLaborSchedulerItemInterface[],
    lineIds: number[],
    isDeploy: boolean,
  ): Observable<AllSettledResolvedInterface[]> {
    return this.http.post<AllSettledResolvedInterface[]>(`${this.api}${this.routes.laborSchedulerItemsSaveAndDeploy}`, {
      scenarioId,
      saveLaborSchedulerItems,
      lineIds,
      isDeploy,
    });
  }

  public formattedSaveLaborSchedulerItems(
    items: SaveLaborSchedulerItemInterface[],
    type: TaskType,
  ): FormattedSaveLaborSchedulerItemInterface[] {
    return items?.map((item: SaveLaborSchedulerItemInterface) => {
      const userId: number = type === TaskType.line ? item.resourceId : item.objectId;
      return {
        userId: !item.isTeam ? userId : null,
        lineId: type === TaskType.line ? item.objectId : item.resourceId,
        startDate: moment(item.startDate).format(mysqlTimestampFormat),
        endDate: moment(item.endDate).format(mysqlTimestampFormat),
        scenarioId: item.scenarioId,
        teamId: item.teamId ?? null,
      };
    });
  }

  public loadLaborSchedulerGanttAllItems(
    scenarioId: number,
    checkInQuery: CheckInTableQueryParams,
  ): Observable<LaborSchedulerGanttAllItemsResponseInterface> {
    const laborSchedulerItemsParams: HttpParams = new HttpParams().set('scenarioId', scenarioId.toString());

    const observables: {
      laborSchedulerItems: Observable<ResponseArrayInterface<ILaborSchedulerItemResponse>>;
      checkIns: Observable<CheckInLogTableResponseInterface>;
    } = {
      laborSchedulerItems: this.http.get<ResponseArrayInterface<ILaborSchedulerItemResponse>>(
        `${this.api}${this.routes.laborSchedulerItems}`,
        {
          params: laborSchedulerItemsParams,
        },
      ),
      checkIns: from(this.checkInLogService.getCheckInTableData(checkInQuery, GroupedByTypes.NONE)),
    };

    return forkJoin(observables).pipe(
      map(
        (resolvedObservables: {
          laborSchedulerItems: ResponseArrayInterface<ILaborSchedulerItemResponse>;
          checkIns: CheckInLogTableResponseInterface;
        }) => {
          return {
            laborSchedulerItems: resolvedObservables.laborSchedulerItems.data,
            checkIns: resolvedObservables.checkIns.data as CheckInTableGroupedByNoneInterface[],
          };
        },
      ),
    );
  }

  public loadTeams(params: HttpParams): Observable<ResponseArrayInterface<ITeamsResponse>> {
    return this.http.get<ResponseArrayInterface<ITeamsResponse>>(`${this.api}${this.routes.teams}`, {
      params,
    });
  }

  public downloadLaborSchedulerListViewExcel(payload: ILaborSchedulerDownloadExcelPayload): void {
    const sheetTitle: string = this.translate.instant('pageTitles.labor_scheduler');
    const excelName: string = `${sheetTitle} ${moment()
      .tz(this.dateFormatInformation.timezone)
      .format(this.dateFormatInformation.dateFormat$)}`;
    const excelTemplateFormatOptions: CreateExcelInterface = {
      data: this.formatExcelData(payload.data, false),
      columns: this.getExcelColumns(payload.headers, false),
    };
    const excelDataAnalysisFormatOptions: CreateExcelInterface = {
      data: this.formatExcelData(payload.data, true),
      columns: this.getExcelColumns(payload.headers, true),
    };
    const workSheets: CreateExcelSheetInterface[] = [
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.worksheetName'),
        sheetType: ExcelSheetTypeEnum.README,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.templateFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelTemplateFormatOptions,
        withData: true,
        isDisabledColumnsFirstLine: true,
        addDateTimeFormula: undefined,
        excelRowFormatLimit: 1001,
      },
      {
        sheetTitle: this.translate.instant('activityLogs.excel.readme.dataAnalysisFormatTitle'),
        sheetType: ExcelSheetTypeEnum.TABLE,
        params: excelDataAnalysisFormatOptions,
        withData: true,
        isDisabledColumnsFirstLine: true,
        addDateTimeFormula: undefined,
        excelRowFormatLimit: 1001,
      },
    ];
    this.excelHelperService
      .createExcel(
        excelName,
        { name: 'laborSchedulerListView' },
        workSheets,
        this.dateFormatInformation.timezone,
        this.dateFormatInformation.dateFormat$,
        this.dateFormatInformation.timeFormat$,
      )
      .then(
        () => {
          this.store.dispatch(new LaborSchedulerActions.DownloadLaborSchedulerExcelCompleted());
          this.store.dispatch(new AppActions.HideTopLoader());
        },
        () => {
          this.store.dispatch(new LaborSchedulerActions.FetchError({}));
          this.store.dispatch(new AppActions.HideLoader());
        },
      );
  }

  private formatExcelData(data: ILaborSchedulerListViewItem[], isDataAnalysis: boolean) {
    return data.map((item: ILaborSchedulerListViewItem) => ({
      ...item,
      category: this.translate.instant(`laborScheduler.listView.category.${item.category}`),
      startDate: item.startDate
        ? isDataAnalysis
          ? moment(item.startDate).format(mysqlTimestampFormat)
          : this.helperService.setUserDateTimeFormat(item.startDate)
        : null,
      endDate: item.endDate
        ? isDataAnalysis
          ? moment(item.endDate).format(mysqlTimestampFormat)
          : this.helperService.setUserDateTimeFormat(item.endDate)
        : null,
    }));
  }

  private getExcelColumns(headers: ITableHeader[], isDataAnalysis: boolean): ExcelColumnDefinitionInterface[] {
    return headers.reduce((excelColumns: any[], column: ITableHeader) => {
      excelColumns.push({
        header: column.name,
        key: column.value,
        width: ExcelColumnWidthEnum.DEFAULT,
        type: ValueType.String,
        style: { numFmt: '@' },
        dataValidation: {
          type: CellTypes.CUSTOM,
          formulae: [],
          showErrorMessage: false,
          showInputMessage: false,
        },
        ...(isDataAnalysis ? this.excelHelperService.getExcelColumnInfo(column.value, { decimalFields: [] }) : {}),
      });
      return excelColumns;
    }, []);
  }
}
