import { Component, OnInit } from '@angular/core';
import { Line } from '../../../../store/line/model';
import { TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { Subscription } from 'rxjs';
import { DECIMAL_DEFAULT_SCALE_LIMIT } from '../../../../../constants';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { User } from '../../../../store/user/model';
import { KpiTargetsStateInterface } from '../../../../store/settings/kpi-targets/kpi-targets.model';
import { HelperService } from '../../../../shared/service/helper.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-work-order-oee-metric',
  templateUrl: './work-order-oee-metric.component.html',
})
export class WorkOrderOeeMetricComponent implements OnInit {
  private readonly subscriptions: Subscription[] = [];
  private siteDecimalScaleLimit$: number = DECIMAL_DEFAULT_SCALE_LIMIT;

  public progressBarRateCapped: number = 0;
  public oee: string = '0';
  public workOrderOeeTarget: string = '0';
  public color: string;

  constructor(
    private readonly decimalHelper: DecimalHelper,
    private readonly helperService: HelperService,
    private readonly store: Store<OeeAppState>,
  ) {}

  public ngOnInit(): void {
    this.subscriptions.push(
      this.store.select('user').subscribe((state: User) => {
        this.siteDecimalScaleLimit$ = state.siteDecimalScaleLimit;
      }),

      this.store.select('kpiTargetsStore').subscribe((state: KpiTargetsStateInterface) => {
        if (!state.bulkEditKpiTargetLoading && state.kpiTargetsLoaded) {
          this.workOrderOeeTarget = _.isNil(state.kpiTargetsData?.data[0]?.oeeTarget)
            ? null
            : this.decimalHelper.toFixedValue(state.kpiTargetsData?.data[0]?.oeeTarget, this.siteDecimalScaleLimit$);
        }
      }),

      this.store.select('line').subscribe((state: Line) => {
        this.oee = this.decimalHelper.multiply(state.quantities.oee, '100');
        this.oee = this.decimalHelper.toFixedValue(this.oee, this.siteDecimalScaleLimit$);
        this.color = this.helperService.getBarColor(
          this.decimalHelper.multiply(state.quantities.oee, '100'),
          this.decimalHelper.sanitizeString(this.workOrderOeeTarget),
        );
      }),
    );
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((value: Subscription) => {
      value.unsubscribe();
    });
  }
}
