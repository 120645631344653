<div class="device-status-container row px-3 pt-3 pb-2">
  <div class="col-12 d-flex justify-content-between">
    <span class="f-w-600">{{ data.deviceName ?? ('sensorStatusIndicator.otherSensors.label' | translate) }}</span>
    <span [ngClass]="data.deviceStatus === 'online' ? 'online-status' : 'offline-status'">
      {{ data.deviceStatus ? ('sensorStatusIndicator.deviceStatus.' + data.deviceStatus | translate) : '' }}
    </span>
  </div>
  <ul class="d-block sensor-list">
    <li *ngFor="let sensor of data.sensorDetails" class="sensor-list-item">
      <span>{{
        sensor.parentSensorType
          ? ('sensorTypes.sensor_type_' + sensor.parentSensorType | translate)
          : ('sensorTypes.sensor_type_' + sensor.sensorType | translate)
      }}</span>
      <span> - </span>
      <span>{{ sensor.lastCommDate ?? ('sensorStatusIndicator.sensor.noMessageReceived' | translate) }}</span>
      <span *ngIf="sensor.sensorType === SensorTypeEnum.SUB_SENSOR">
        ({{ 'sensorTypes.sensor_type_' + SensorTypeEnum.SUB_SENSOR | translate }})
      </span>
    </li>
  </ul>
</div>
