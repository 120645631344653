import { Component, Input, OnInit } from '@angular/core';
import { IDeviceAndSensors } from '../../../store/sensor-statuses/sensor-statuses.model';
import { SensorTypeEnum } from '../../../../constants';

@Component({
  selector: 'sensor-status-indicator',
  templateUrl: './sensor-status-indicator.component.html',
  styleUrls: ['./sensor-status-indicator.component.scss'],
})
export class SensorStatusIndicatorComponent implements OnInit {
  @Input() data: IDeviceAndSensors;

  public SensorTypeEnum = SensorTypeEnum;

  private readonly sensorTypeOrder: SensorTypeEnum[] = [
    SensorTypeEnum.GOOD_COUNTER,
    SensorTypeEnum.MID_COUNTER,
    SensorTypeEnum.TOTAL_COUNTER,
    SensorTypeEnum.VIBRATION,
    SensorTypeEnum.CARD_READER,
    SensorTypeEnum.TEMPERATURE,
    SensorTypeEnum.HUMIDITY,
    SensorTypeEnum.SUB_SENSOR,
  ];

  public ngOnInit(): void {
    this.data?.sensorDetails?.sort((a, b) => {
      return (
        this.sensorTypeOrder.indexOf(a.parentSensorType ?? a.sensorType) -
        this.sensorTypeOrder.indexOf(b.parentSensorType ?? b.sensorType)
      );
    });
  }
}
