import { FilterSiteActions, FilterSiteActionTypes } from './site.actions';
import { SiteCRUDInterface } from '../../../shared/component/filter/filter.class';

export interface FilterSiteState {
  isLoading: boolean;
  isLoaded: boolean;
  data: SiteCRUDInterface[];
}

export const initialFilterSiteState: FilterSiteState = {
  isLoaded: false,
  isLoading: false,
  data: [],
};

export function filterSiteReducer(state = initialFilterSiteState, action: FilterSiteActions): FilterSiteState {
  switch (action.type) {
    case FilterSiteActionTypes.StartLoadFilterSites:
      return { ...state, isLoading: true };

    case FilterSiteActionTypes.FilterSitesLoaded:
      return { ...state, data: action.data, isLoaded: true, isLoading: false };

    case FilterSiteActionTypes.AppendSite:
      return {
        ...state,
        data: [
          ...state.data,
          ...(!state.data.find((site: SiteCRUDInterface) => site.id === action.site.id)
            ? [action.site as SiteCRUDInterface]
            : []),
        ],
      };

    default:
      return state;
  }
}
