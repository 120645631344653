import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { ResponseArrayInterface, ResponseInterface } from '../../model/interface/generic-api-response.model';
import { IActivityHistory } from './activity-history.model';
import { STATIC_MAX_LIMIT_OF_CRUD } from '../../../../constants';
import {
  IActivityHistoryTableData,
  OngoingActivityValidationDataInterface,
  OverlappedActivitiesDataInterface,
  OverlappingDataInterface,
  WorkorderNumbersDataInterface,
  WorkOrderResponseDataInterface,
} from '../../../store/activity-history/activity-history.model';
import { ActivityHistoryBulkUpdateRequestModel } from '../../component/activity-form/activity-form.model';
import { BulkResponseDataInterface } from '../../model/interface/crud-response-interface.model';
import { ActivityLogFormattedInterface } from '../../../store/reports/activity-logs/activity-logs.model';
import * as _ from 'lodash';
import { ActivityTypes } from '../../model/enum/activity-types';

@Injectable({
  providedIn: 'root',
})
export class ActivityHistoryService {
  constructor(public http: HttpClient, @Inject('API_BASE_URL') private readonly api: string) {}

  private readonly routes = {
    activityHistory: `${this.api}/activity-histories`,
    lines: `${this.api}/lines`,
    validateActivityOverlap: 'validate-activity-overlap',
  };
  private readonly activityHistoryDefaultParams: HttpParams = new HttpParams()
    .append('join', 'task')
    .append('join', 'task.equipment')
    .append('join', 'activity')
    .append('join', 'workOrder')
    .append('join', 'workOrder.product');
  public timeEntryModalWorkOrdersSubject: BehaviorSubject<Partial<IActivityHistory>> = new BehaviorSubject<
    Partial<IActivityHistory>
  >(null);

  public getActivity(id: number): Observable<ResponseInterface<IActivityHistory>> {
    const params: HttpParams = this.activityHistoryDefaultParams.append('join', 'user').append('join', 'createdByUser');
    return this.http.get<ResponseInterface<IActivityHistory>>(`${this.routes.activityHistory}/${id}`, {
      params,
    });
  }

  public getActivities(
    search: object,
    page: number = 1,
    limit: number = STATIC_MAX_LIMIT_OF_CRUD,
    orderBy: string = null,
    orderDesc: boolean = null,
  ): Observable<ResponseArrayInterface<IActivityHistory>> {
    let params: HttpParams = this.activityHistoryDefaultParams
      .append('join', 'workOrder.runningLine||productId')
      .append('join', 'workOrder.ongoingFinalizeOeeCalculation')
      .set('s', JSON.stringify(search))
      .set('page', String(page))
      .set('limit', String(limit));

    if (orderBy !== null && orderDesc !== null) {
      params = params.set('sort', `${orderBy},${orderDesc ? 'DESC' : 'ASC'}`);
    }

    return this.http.get<ResponseArrayInterface<IActivityHistory>>(this.routes.activityHistory, {
      params,
    });
  }

  public validateOngoingActivityOverlap(
    lineId: number,
    params: OngoingActivityValidationDataInterface,
  ): Observable<OverlappedActivitiesDataInterface> {
    return this.http.post<OverlappedActivitiesDataInterface>(
      `${this.routes.lines}/${lineId}/${this.routes.validateActivityOverlap}`,
      params,
    );
  }

  public validateActivityOverlap(requestData: OverlappingDataInterface): Observable<OverlappedActivitiesDataInterface> {
    return this.http.post<OverlappedActivitiesDataInterface>(
      `${this.routes.activityHistory}/${this.routes.validateActivityOverlap}`,
      requestData,
    );
  }

  public overlappingActivitiesActivityHistory(
    data: ActivityHistoryBulkUpdateRequestModel,
    params?: HttpParams,
  ): Observable<BulkResponseDataInterface> {
    return this.http.post<BulkResponseDataInterface>(
      `${this.routes.activityHistory}/bulk/overlapping-activities`,
      data,
      { params },
    );
  }

  public deleteManyActivityHistoryData(data: number[]): Observable<BulkResponseDataInterface> {
    const httpOptions = {
      body: { activityIds: data },
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    };
    return this.http.delete<BulkResponseDataInterface>(`${this.routes.activityHistory}/bulk/delete`, httpOptions);
  }

  public setActivityHistoryTaskDescription(lineTimeLine: any): void {
    if (lineTimeLine.activityGroupType === 'runTime') {
      lineTimeLine.taskDescription = lineTimeLine.productDescription;
    } else {
      lineTimeLine.taskDescription = lineTimeLine.taskName;
    }
  }

  public checkMissingDataConditions(
    selectedActivityIds: number[],
    data: IActivityHistoryTableData[] | ActivityLogFormattedInterface[],
  ): boolean[] {
    let missingWo: boolean = false;
    let activityWithoutWo: boolean = false;
    let missingTask: boolean = false;
    selectedActivityIds.forEach((id: number) => {
      const selectedActivity: IActivityHistoryTableData = _.find(data, { id });

      if (
        selectedActivity?.workOrder?.woNumber === '' ||
        selectedActivity?.workOrder?.woNumber === null ||
        selectedActivity?.workOrderNumber === '' ||
        selectedActivity?.workOrderNumber === null
      ) {
        activityWithoutWo = true;

        if (
          selectedActivity?.activity?.activityType !== ActivityTypes.IDLE_TIME ||
          selectedActivity?.activityType !== ActivityTypes.IDLE_TIME
        ) {
          missingWo = true;
        }
      }

      if (
        (selectedActivity?.activity?.activityType !== ActivityTypes.RUN_TIME ||
          selectedActivity?.activityType !== ActivityTypes.RUN_TIME) &&
        (selectedActivity?.taskName === '' || selectedActivity?.taskName === null)
      ) {
        missingTask = true;
      }
    });

    return [missingWo, activityWithoutWo, missingTask];
  }

  public formatActivityHistoryWorkOrderData(resData: WorkOrderResponseDataInterface): WorkOrderResponseDataInterface {
    const data: WorkorderNumbersDataInterface[] = _.cloneDeep(resData.data);
    data.forEach((row: WorkorderNumbersDataInterface) => {
      row.isCalculating = Number(row.completed) === 1 && row.goodCount === null;
    });
    return { ...resData, data };
  }
}
