import { Component, Input, ViewChild, ViewChildDecorator } from '@angular/core';
import { NgbCarouselConfig, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { WebcamImage } from 'ngx-webcam';
import { Observable, Subject } from 'rxjs';
import { ScwModalSize } from '../../scw-mat-ui/scw-mat-modal/scw-mat-modal.model';
import { NgxImageCompressService } from 'ngx-image-compress-legacy';
import { ECameraErrors, EImageMimeType } from '../file-upload.model';
import { TranslateService } from '@ngx-translate/core';
import { OnDestroyDecorator } from '../../../decorator/on-destroy-decorator';
import { EFileType, IFile } from '../../../../store/file-upload/file-upload.model';
import { ImageHelperService } from '../../../helper/image-helper.service';
import { HelperService } from '../../../service/helper.service';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import * as AppActions from '../../../../store/app/actions';
import { SIZE_LIMIT_PER_FILE_AS_MB } from '../../../../../constants';
import { WebcamInitError } from 'ngx-webcam/src/app/modules/webcam/domain/webcam-init-error';

@OnDestroyDecorator
@Component({
  selector: 'scw-camera-modal',
  templateUrl: './camera-modal.component.html',
  styleUrls: ['./camera-modal.component.scss'],
  providers: [NgbCarouselConfig],
})
export class CameraModalComponent {
  @ViewChild('camera_modal') cameraModalTemplateRef: ViewChildDecorator;

  private trigger: Subject<void> = new Subject();

  private readonly compressedImageQuality: number = 25;
  private readonly thumbnailImageRatio: number = 25;
  private readonly originalImageRatio: number = 100;
  private readonly compressedPngRatio: number = 80;
  private readonly compressedPngQuality: number = 10;

  public readonly imageQualityTooltip = this.translate.instant('fileUpload.cameraModal.tooltip');
  public readonly EFileType = EFileType;
  public static filesSubject: Subject<IFile[]> = new Subject();
  public webcamImage!: WebcamImage;
  public isCapturingMode: boolean;
  public files: IFile[] = [];

  public cameraModalRef: NgbModalRef;
  public saveImageText: string;
  @Input() public isOriginalQuality: boolean = false;
  public isFromGallery: boolean = false;
  public initialFiles: string[] = [];
  public isCameraAvailable: boolean = true;

  @Input()
  public sizeLimitPerFileAsMB: number = SIZE_LIMIT_PER_FILE_AS_MB;

  constructor(
    private readonly ngbModal: NgbModal,
    private readonly imageCompress: NgxImageCompressService,
    private readonly translate: TranslateService,
    private readonly helperService: HelperService,
    private readonly store: Store<OeeAppState>,
  ) {}

  public async openCameraModal(isCameraMode: boolean, files?: string[]): Promise<void> {
    this.switchCameraMode(isCameraMode);
    this.isFromGallery = !isCameraMode;
    this.initialFiles = files;

    if (files?.length) {
      const promises: Promise<any>[] = [];
      files.forEach((item: string) => {
        promises.push(this.setOriginalAndThumbnailFiles(item));
      });

      this.files = await Promise.all(promises);
    }

    this.cameraModalRef = this.ngbModal.open(this.cameraModalTemplateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: ScwModalSize.medium,
    });
  }

  private switchCameraMode(isCameraMode: boolean): void {
    this.isCapturingMode = isCameraMode;
    this.saveImageText = isCameraMode
      ? this.translate.instant('fileUpload.cameraModal.capture')
      : this.translate.instant('fileUpload.cameraModal.save');
  }

  public getSnapshot(): void {
    this.trigger.next();
  }

  public async captureImg(webcamImage: WebcamImage): Promise<void> {
    this.webcamImage = webcamImage;
    this.initialFiles = [webcamImage.imageAsDataUrl];

    this.files.push(await this.setOriginalAndThumbnailFiles(webcamImage.imageAsDataUrl));

    if (this.files.length) {
      this.switchCameraMode(false);
    }
  }

  private getImageExtensionFromBase64(base64String: string): string | null {
    const matches: RegExpMatchArray = base64String.match(/^data:image\/([a-zA-Z]+);base64,/);

    if (matches && matches[1]) {
      return matches[1].toLowerCase();
    }

    return null;
  }

  private async setOriginalAndThumbnailFiles(image: string): Promise<IFile> {
    const fileExtension: string | null = this.getImageExtensionFromBase64(image);
    const compressedImage: string = await this.imageCompress.compressFile(
      image,
      1,
      fileExtension === EImageMimeType.PNG ? this.compressedPngRatio : this.originalImageRatio,
      fileExtension === EImageMimeType.PNG ? this.compressedPngQuality : this.compressedImageQuality,
    );

    const thumbnailImage: string = await this.imageCompress.compressFile(
      image,
      1,
      this.thumbnailImageRatio,
      this.isOriginalQuality ? 100 : this.compressedImageQuality,
    );

    const originalFile: string = this.isOriginalQuality ? image : compressedImage;
    const originalFileSize: number = ImageHelperService.getBase64Size(originalFile);

    return {
      original: originalFile,
      thumbnail: thumbnailImage,
      type: EFileType.IMAGE,
      size: originalFileSize,
    };
  }

  public invokeObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public onChange(): void {
    this.isOriginalQuality = false;
    this.files = [];

    if (this.isFromGallery) {
      this.cameraModalRef.close();
      return;
    }

    this.switchCameraMode(true);
  }

  public onCloseModal(): void {
    this.files = [];
    this.isOriginalQuality = false;
    this.cameraModalRef.close();
  }

  public saveImage(): void {
    const fileLimitValidation: boolean = !!this.files.find((item: IFile) => item.size > this.sizeLimitPerFileAsMB);

    if (fileLimitValidation) {
      this.helperService.showToastMessage(
        false,
        this.translate.instant('general.failed'),
        this.translate.instant('fileUpload.phaseComment.sizeFileLimit'),
      );
      return;
    }

    CameraModalComponent.filesSubject.next(this.files);
    this.files = [];
    this.initialFiles = [];
    this.cameraModalRef?.close();
  }

  public async changeQuality(): Promise<void> {
    this.isOriginalQuality = !this.isOriginalQuality;
    this.store.dispatch(new AppActions.ShowLoader());

    if (this.initialFiles.length) {
      const promises: Promise<any>[] = [];
      this.initialFiles.forEach((item: string) => {
        promises.push(this.setOriginalAndThumbnailFiles(item));
      });

      this.files = await Promise.all(promises);
      this.store.dispatch(new AppActions.HideLoader());
    }
  }

  public initError(event: WebcamInitError): void {
    if (event.mediaStreamError.name === ECameraErrors.PERMISSION_DENIED) {
      this.isCameraAvailable = false;
    }
  }

}
