import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import {
  IWorkOrderScheduleWithRules,
  TFetchWorkOrdersForSchedulerRules,
  TGetManyWorkOrderSet,
} from '../../../store/scheduler-work-order-set/scheduler-work-order-set.model';
import { Observable } from 'rxjs';
import { ScenarioWorkOrdersTableQueryParams } from '../../../view/work-orders-new-design/work-orders-new-design.model';
import { FormattedWorkOrdersResponseInterface } from '../../../store/work-order-schedule/work-order-schedule.model';
import { WorkOrderScheduleService } from '../work-order-schedule/work-order-schedule.service';
import {
  FieldTypes,
  IFilterOutput,
  QueryTypes,
  TargetEndpoints,
} from '../../component/filter/advanced-filter/advanced-filter.model';
import { AdvancedFilterService } from '../../component/filter/advanced-filter/advanced-filter.service';
import { isNil } from 'lodash';
import { DecimalHelper } from '../../helper/decimal/decimal-helper';

@Injectable({
  providedIn: 'root',
})
export class SchedulerWorkOrderSetService {
  private readonly routes = {
    schedulerBase: '/scheduler',
    workOrders: '/work-orders',
    workOrdersForScheduler: '/work-orders/work-orders-for-scheduler',
  };

  constructor(
    public http: HttpClient,
    @Inject('API_BASE_URL') private readonly api: string,
    private readonly workOrderScheduleService: WorkOrderScheduleService,
    private readonly advancedFilterService: AdvancedFilterService,
    private readonly decimalHalper: DecimalHelper,
  ) {}

  public getWorkOrdersForSchedulerRules(payload: TFetchWorkOrdersForSchedulerRules): Observable<TGetManyWorkOrderSet> {
    let params: HttpParams = new HttpParams()
      .set('fields', this.getFieldsForWorkOrders())
      .set('page', String(payload.query.page))
      .set('limit', String(payload.query.pageSize))
      .append('join', 'site||name')
      .append('join', 'product||productId,description,productSpeed')
      .append('join', 'job||jobName')
      .append('join', 'product.customer')
      .append('join', 'scenarioWorkOrderRules')
      .append('join', 'scheduledLineDetail');

    if (payload.query.orderType && payload.query.orderBy) {
      params = params.set('sort', `${payload.query.orderBy},${payload.query.orderType}`);
    }

    return this.http.post<TGetManyWorkOrderSet>(
      `${this.api}${this.routes.workOrdersForScheduler}`,
      {
        scenarioId: payload.scenarioId,
        workOrderIds: payload.workOrderIds,
        excludedWorkOrderIds: payload.excludedWorkOrderIds,
        isBlack: payload.isBlack,
      },
      {
        headers: new HttpHeaders({ 'X-HTTP-Method': 'GET' }),
        params,
      },
    );
  }

  public loadUnplannedWorkOrderData(
    filters: ScenarioWorkOrdersTableQueryParams,
  ): Observable<FormattedWorkOrdersResponseInterface> {
    const url: string = `${this.api}${this.routes.workOrders}`;

    let params = new HttpParams()
      .set('fields', this.getFieldsForWorkOrders())
      .set('page', String(filters.page))
      .set('limit', String(filters.pageSize))
      .append('join', 'site||name')
      .append('join', 'product||productId,description')
      .append('join', 'job||jobName')
      .append('join', 'scenarioWorkOrderRules')
      .append('join', 'scheduledLineDetail');

    if (filters.orderType && filters.orderBy) {
      params = params.set('sort', `${filters.orderBy},${filters.orderType}`);
    }

    params = params.set('s', this.getUnplannedWorkOrderSearchParamsAsString(filters));

    return this.http.get<FormattedWorkOrdersResponseInterface>(`${url}`, {
      params,
    });
  }

  private getUnplannedWorkOrderSearchParamsAsString(filters: ScenarioWorkOrdersTableQueryParams): string {
    const searchParamsAdvancedFilter: { [key: string]: any } = { $and: [] };

    if (filters.advancedFilter) {
      const advancedFilter: IFilterOutput[] = filters.advancedFilter.filters;

      for (const filter of advancedFilter) {
        if (filter.queryType !== QueryTypes.withinAdvancedFilterParams) {
          continue;
        }

        if (filter.type === FieldTypes.predefined) {
          searchParamsAdvancedFilter['$and'].push(
            this.advancedFilterService.generateQuery(
              filter.path,
              filter.type,
              filter.operator.name,
              filter.operator.type,
              TargetEndpoints.NestJSCrud,
              filter.value[0][filter.searchBy],
            ),
          );
        } else {
          searchParamsAdvancedFilter['$and'].push(
            this.advancedFilterService.generateQuery(
              filter.path,
              filter.type,
              filter.operator.name,
              filter.operator.type,
              TargetEndpoints.NestJSCrud,
              filter.value,
            ),
          );
        }
      }
    }

    const searchParams: { [key: string]: any } = {
      $and: [
        {
          $and: [
            {
              $or: [
                {
                  completed: {
                    $isnull: true,
                  },
                },
                {
                  completed: {
                    $eq: 0,
                  },
                },
              ],
            },
            {
              canceled: {
                $eq: 0,
              },
            },
            {
              siteId: {
                $eq: filters.siteId,
              },
            },
            filters.search
              ? {
                  woNumber: {
                    $cont: filters.search,
                  },
                }
              : {},
          ],
        },
        {
          $and: [...searchParamsAdvancedFilter['$and']],
        },
      ],
    };

    return JSON.stringify(searchParams);
  }

  private getFieldsForWorkOrders(): string {
    return [
      'id',
      'woNumber',
      'job.jobName',
      'product.productId',
      'product.productDescription',
      'woDueDate',
      'releaseDate',
      'scheduledLineDetail.title',
      'scheduledRunDate',
      'scheduledEndDate',
      'quantityOrdered',
      'oeeGoodCount',
      'scheduledPreDuration',
      'scheduledPostDuration',
      'scheduledRunDuration',
    ].join(',');
  }

  public formatUnPlannedWorkOrders(data: IWorkOrderScheduleWithRules[]): IWorkOrderScheduleWithRules[] {
    return data.map((item: IWorkOrderScheduleWithRules) => {
      return {
        ...item,
        averageDuration: this.getWorkOrderAverageDuration(item),
        siteId: item.site.id,
      };
    });
  }

  private getWorkOrderAverageDuration(item: IWorkOrderScheduleWithRules): number {
    if (!isNil(item.scheduledPreDuration) && !isNil(item.scheduledPostDuration) && !isNil(item.scheduledRunDuration)) {
      return this.decimalHalper.decimalToNumberFormatter(
        this.decimalHalper.add(
          this.decimalHalper.add(item.scheduledPreDuration, item.scheduledPostDuration),
          item.scheduledRunDuration,
        ),
      );
    }

    if (item.product?.productSpeed) {
      return this.decimalHalper.decimalToNumberFormatter(
        this.decimalHalper.divide(item.quantityOrdered, String(item.product.productSpeed)),
      );
    }

    return 0;
  }
}
