import { SensorTypeEnum } from 'src/constants';
import {
  IBaseSensorInfo,
  ITemperatureSensorInfo,
} from '../../../../view/home/home-metrics/home-mini-metrics/home-mini-metrics.model';
import { IMiniMetricDetails, IMiniMetricMeta } from '../home-metrics/home-metrics.model';

export enum EHomeMiniMetricTemperatureScale {
  Celsius = 'C',
  Fahrenheit = 'F',
}

export enum EHomeMiniMetricType {
  Temperature = 'temperature',
  Humidity = 'humidity',
}
export type THomeMiniMetricType = EHomeMiniMetricType.Temperature | EHomeMiniMetricType.Humidity;

export interface IMiniMetricLine {
  id: number;
  title: string;
}

export class HomeMiniMetricsSetProperties {
  constructor(public readonly lineId: number, public readonly miniMetrics?: HomeMiniMetrics) {
    if (!miniMetrics) {
      this.miniMetrics = new HomeMiniMetrics([], []);
    }
  }

  public toMiniMetricMeta(): IMiniMetricMeta {
    return {
      lineId: this.lineId,
      miniMetrics: this.miniMetrics.toMiniMetricDetails(),
    };
  }
}

export class HomeMiniMetrics {
  constructor(
    public readonly temperature: HomeMiniMetricTemperature[] = [],
    public readonly humidity: HomeMiniMetricHumidity[] = [],
  ) {}

  public toMiniMetricDetails(): IMiniMetricDetails {
    return {
      humidity: this.humidity.map((metric) => metric.toSensorInfo()),
      temperature: this.temperature.map((metric) => metric.toSensorInfo()),
    };
  }
}

export abstract class HomeMiniMetric {
  constructor(public readonly sensorId: string) {}

  public abstract toSensorInfo(): IBaseSensorInfo;
}

export class HomeMiniMetricTemperature extends HomeMiniMetric {
  constructor(sensorId: string, public readonly scale: EHomeMiniMetricTemperatureScale) {
    super(sensorId);
  }

  public toSensorInfo(): ITemperatureSensorInfo {
    return {
      sensorId: this.sensorId,
      scale: this.scale,
    };
  }
}

export class HomeMiniMetricHumidity extends HomeMiniMetric {
  constructor(sensorId: string) {
    super(sensorId);
  }

  public toSensorInfo(): IBaseSensorInfo {
    return {
      sensorId: this.sensorId,
    };
  }
}

export interface IGetSensorsOfLinesResponse {
  id: number;
  sensorId: string;
  sensorType: SensorTypeEnum;
  lineId: number;
  startDate: string;
  endDate: string;
}
