import { Component, NgZone, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ActionsSubject, Store } from '@ngrx/store';
import { ofType } from '@ngrx/effects';
import { CallSupport, ESensorAndOPCButtonStyle, lineVisibleUrls, TLineVisibleUrls } from './main.model';
import { ToastrService } from 'ngx-toastr';
import * as AppActions from '../../store/app/actions';
import * as MainActions from '../../store/main/main.actions';
import * as StationActivityHistoryActions from '../../store/station-activity-history/station-activity-history.actions';
import { InputLimit } from '../../shared/model/enum/input-limit';
import { InterfaceCustomMailGroupInterface, MainStateInterface, MenuItemInterface } from '../../store/main/main.model';
import { OeeAppState } from '../../store/oee.reducer';
import { LayoutConstants } from '../layout-constants';
import {
  closeNavigationBar,
  fireClick,
  goLink,
  hoverOutsideSidebar,
  NavBarElements,
  notificationOutsideClick,
  onResize,
  scroll,
  setBackgroundPattern,
  setHeaderAttributes,
  setMenuAttributes,
  setSidebarStates,
  toggleHeaderNavRight,
  toggleLanguage,
  toggleLiveNotification,
  toggleNotificationFeed,
  toggleOpened,
  toggleProfileNotification,
  toggleSidebarInDesktop,
} from '../../shared/helper/navbar-helper';
import { LayoutAnimations } from '../animations';
import { getCurrentDateTime } from '../../shared/helper/date';
import { PageHeaderService } from '../../shared/service/page-header/page-header.service';
import { PageHeaderInterface } from '../../shared/service/page-header/page-header.model';
import * as AlertPauseActions from '../../store/alert-pause/alert-pause.actions';
import * as SensorStatusesActions from '../../store/sensor-statuses/sensor-statuses.actions';
import { User, UserLevels } from '../../store/user/model';
import {
  IDeviceAndSensors,
  ISensorStatus,
  ISensorStatusState,
} from '../../store/sensor-statuses/sensor-statuses.model';
import * as momentTz from 'moment-timezone';
import { MonitoringService } from '../../shared/service/error-service/monitoring.service';
import { GetNotificationList } from '../../store/user/actions';
import { NotificationList } from '../../shared/service/notification/notification.service';
import { MomentDateFormat } from '../../shared/helper/date.constants';
import moment from 'moment';
import { SearchOptionInterface } from '../../view/home/cico/cico.model';
import { DropdownSettings } from 'angular2-multiselect-dropdown/lib/multiselect.interface';
import * as HomeActions from '../../store/home/home.actions';
import * as _ from 'lodash';
import { UserGetManyCRUDDataInterface, UserGetOneCRUDDataInterface } from '../../shared/service/user/user.model';
import { MenuService } from '../../shared/service/menu/menu.service';
import { environment } from '../../../environments/environment';
import { TModalResultType } from '../../shared/model/interface/common-page.model';
import { IStationDropdownResponse } from '../../store/station-activity-history/station-activity-history.model';
import * as PageHeaderActions from '../../store/page-header/page-header.actions';
import { HomeStateInterface } from '../../store/home/home.model';
import { OnDestroyDecorator } from '../../shared/decorator/on-destroy-decorator';
import { HelperService } from '../../shared/service/helper.service';
import { EHeaderExceptionType } from 'src/app/store/page-header/page-header.model';
import { PushNotificationService } from '../../store/push-notification/push-notification.service';
import { ScwFirebaseService } from '../../shared/service/firebase/firebase.service';
import { ESiteLineSelectionMode } from '../../store/site-line-selection/site-line-selection.model';
import Timer = NodeJS.Timer;
import { DropdownOptionsInterface } from './main.model';
import { ResponseInterface } from '../../shared/model/interface/generic-api-response.model';
import { AccountSettingsInterface, LanguageInterface } from '../../store/settings/account/account.model';
import * as AccountActions from '../../store/settings/account/account.actions';
import { ToastHelperService } from '../../shared/service/toast/toast.helper.service';
import { CreateQuickIssueActionModalComponent } from '../../shared/component/create-quick-issue-action-modal/create-quick-issue-action-modal.component';
import { ScwModalSize } from '../../shared/component/scw-mat-ui/scw-mat-modal/scw-mat-modal.model';
import { smallModal } from '../../../constants';

@OnDestroyDecorator
@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: [
    './main.component.scss',
    '../../../assets/icon/icofont/css/icofont.scss',
    '../../../scss/navigation-bar.scss',
  ],
  animations: [
    LayoutAnimations.notificationBottom,
    LayoutAnimations.slideInOut,
    LayoutAnimations.mobileHeaderNavRight,
    LayoutAnimations.fadeInOutTranslate,
    LayoutAnimations.mobileMenuTop,
  ],
})
export class MainComponent extends LayoutConstants implements OnInit, OnDestroy {
  @ViewChild('payment_status_modal', { static: false }) paymentStatusModalTemplateRef: TemplateRef<void>;
  @ViewChild(CreateQuickIssueActionModalComponent)
  private createQuickIssueActionModal: CreateQuickIssueActionModalComponent;
  toggleSidebarInDesktop = toggleSidebarInDesktop;
  setSidebarStates = setSidebarStates;
  hoverOutsideSidebar = hoverOutsideSidebar;
  onResize = onResize;
  setHeaderAttributes = setHeaderAttributes;
  setMenuAttributes = setMenuAttributes;
  closeNavigationBar = closeNavigationBar;
  toggleHeaderNavRight = toggleHeaderNavRight;
  toggleProfileNotification = toggleProfileNotification;
  toggleNotificationFeed = toggleNotificationFeed;
  toggleLiveNotification = toggleLiveNotification;
  toggleLanguage = toggleLanguage;
  toggleOpened = toggleOpened;
  notificationOutsideClick = notificationOutsideClick;
  setBackgroundPattern = setBackgroundPattern;
  fireClick = fireClick;
  scroll = scroll;
  goLink = goLink;
  public newMenuItems: MenuItemInterface[];
  public callSupportForm: CallSupport;

  InputLimit = InputLimit;

  public isLineSelectionVisible: boolean = false;
  public homeAuth: boolean = false;
  public pageHeader: PageHeaderInterface = {
    title: null,
    titleKey: null,
    icon: null,
    fullScreenButton: false,
    fullScreenTargetElementId: null,
  };
  public isDigitalFormsActive$: boolean = false;

  readonly deviceType = {
    desktop: 'desktop',
    tablet: 'tablet',
    phone: 'phone',
  };

  siteLineSelectionTitle = '';

  private mainStoreSubscription: Subscription;
  private sendContactSupportCompletedActionSubscription: Subscription;
  private userStoreSubscription: Subscription;
  private lineStoreSubscription: Subscription;
  private pageHeaderSubscription: Subscription;
  private getSensorStatusesCompletedSubscription: Subscription;
  private readonly storeSubscriptions: Subscription[] = [];

  public clock: Date;
  public timeFormat$: string;
  public currentSensorStatuses: IDeviceAndSensors[] = [];
  public numberOfOfflineDevices: number = 0;
  private timeZone$: string;
  private timer: Subscription;
  private paymentStatusTimer: Subscription;
  private lineId$: number;
  private momentFormat: 'MMM D, YYYY h:mm A' | 'MMM D, YYYY H:mm';
  public hasUserAuthorization$: boolean = false;
  public unSeenNotifications$: number = 0;
  private notificationList$: NotificationList = { duration: '', next: '', results: [], unread: 0, unseen: 0 };
  public toastPosition: string = 'toast-bottom-right';
  public userDropdownOptions: SearchOptionInterface[] = [];
  public userDropdownSettings: Partial<DropdownSettings> = {
    singleSelection: true,
    enableSearchFilter: true,
    enableCheckAll: false,
    text: this.translate.instant('cico.modules.laborTracker.searchDropdown.placeholder'),
    labelKey: 'optionText',
    noDataLabel: this.translate.instant('cico.modules.laborTracker.searchDropdown.searchInput.placeholder'),
    classes: 'form-control searchableDropdown',
    maxHeight: 425,
    disabled: false,
    tagToBody: false,
  };
  public fromDropdownOptions: SearchOptionInterface[] = [];
  public fromDropdownSettings: Partial<DropdownSettings> = {
    singleSelection: true,
    enableSearchFilter: true,
    enableCheckAll: false,
    text: this.translate.instant('cico.modules.laborTracker.searchDropdown.placeholder'),
    labelKey: 'optionText',
    noDataLabel: this.translate.instant('cico.modules.laborTracker.searchDropdown.searchInput.placeholder'),
    classes: 'form-control searchableDropdown',
    maxHeight: 425,
    disabled: false,
    tagToBody: false,
  };
  public userSearchInput: string = '';
  private userSearchTimer: Timer;
  public fromSearchInput: string = '';
  private fromSearchTimer: Timer;
  public siteId$: number;
  public defaultSite$: number;
  public customMailGroups: InterfaceCustomMailGroupInterface[];
  private callSupportDropdownOpened: boolean;
  public customMailGroupsLoading: boolean;
  public userSearchFor: 'user' | 'from';
  public userId: number;
  public userFullName: string;
  private readonly searchInputPlaceHolder: string = 'searchInput.placeholder';
  public isHomeScreen: boolean = false;
  public readonly version: string = environment.version;
  public showGmpDisclaimerModal: boolean = false;
  public isAppNotificationActive$: boolean = false;
  public navBarElements = NavBarElements;
  public stations: IStationDropdownResponse[] = [];
  public isStationInformationLoading$: boolean = false;
  public isHomeInformationLoading$: boolean = false;
  public isLineStation: boolean = false;
  public callSupportModalRef: NgbModalRef;
  public siteLineSelectionModalRef: NgbModalRef;
  public paymentStatusModalRef: NgbModalRef;
  public paymentStatusModalMessage: string;
  public paymentStatusModalTitle: string;
  public isThePaymentStatusRequestedPage: boolean = false;
  public logbookUrl: string;
  public isAccessLogbookActive: boolean;
  public isHaveOPCFailure: boolean | null = null;
  public sensorAndOPCButtonStyle: ESensorAndOPCButtonStyle = ESensorAndOPCButtonStyle.UNKNOWN;
  public isOeeTrackerActive: boolean = true;
  private devicePushNotificationToken$: string;
  public lineVisibleMenuItems: { [key in TLineVisibleUrls]?: boolean } = {};
  public readonly submitATicketUrl: string = environment.submitATicketUrl;
  public siteLineSelectionMode: ESiteLineSelectionMode = ESiteLineSelectionMode.LINES;
  public ESiteLineSelectionMode = ESiteLineSelectionMode;
  public isSubmitATicketButtonEnabled: boolean = true;
  public languages: DropdownOptionsInterface[] = [];
  public userLanguage: string = null;
  public isActionTrackerActive$: boolean = false;

  constructor(
    private readonly ngbModal: NgbModal,
    private http: HttpClient,
    private store: Store<OeeAppState>,
    public router: Router,
    private translate: TranslateService,
    public helperService: HelperService,
    private toast: ToastrService,
    private mainActions: ActionsSubject,
    private pageHeaderService: PageHeaderService,
    private monitoringService: MonitoringService,
    private readonly menuService: MenuService,
    private readonly zone: NgZone,
    private readonly toastHelperService: ToastHelperService,
  ) {
    super();
    this.setHeaderAttributes(this.windowWidth);
    this.setMenuAttributes(this.windowWidth);
    this.setHeaderAttributes(this.windowWidth);

    this.isCallSupportSubmitButtonClicked = false;
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;

    this.router.events.subscribe((routerEvent) => {
      if (event instanceof NavigationStart) {
        this.closeNavigationBar();
      }
      if (routerEvent instanceof NavigationEnd) {
        // trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
        window.scrollTo(0, 0);
      }
    });
  }

  toggleShiftReview() {
    this.shiftReviewDropdown = this.shiftReviewDropdown === 'an-off' ? 'an-animate' : 'an-off';
    this.shiftReviewDropdownClass = this.shiftReviewDropdown === 'an-animate' ? 'show' : '';
  }

  toggleAlertControl(): void {
    this.alertControlDropdown = this.alertControlDropdown === 'an-off' ? 'an-animate' : 'an-off';
    this.alertControlDropdownClass = this.alertControlDropdown === 'an-animate' ? 'show' : '';

    if (!this.lineId$ || this.alertControlDropdown !== 'an-animate') {
      return;
    }

    this.store.dispatch(new AlertPauseActions.StartGetAlertPauseDataLoading({ lineId: this.lineId$ }));
    this.store.dispatch(new SensorStatusesActions.GetSensorStatuses(this.lineId$));
  }

  toggleLineStation(): void {
    this.lineStationDropdown = this.lineStationDropdown === 'an-off' ? 'an-animate' : 'an-off';
    this.lineStationDropdownClass = this.lineStationDropdown === 'an-animate' ? 'show' : '';

    if (!this.lineId$ || this.lineStationDropdown !== 'an-animate') {
      return;
    }

    this.store.dispatch(new StationActivityHistoryActions.StationDropdownItemsLoading(this.lineId$));
  }

  toggleNotificationControl(): void {
    this.notificationDropdown = this.notificationDropdown === 'an-off' ? 'an-animate' : 'an-off';
    this.notificationDropdownClass = this.notificationDropdown === 'an-animate' ? 'show' : '';

    if (this.notificationDropdown !== 'an-animate') {
      return;
    }

    this.store.dispatch(new GetNotificationList(false, this.notificationList$.id_lt));
  }

  public toggleCallSupport(): void {
    this.callSupportDropdownOpened = !this.callSupportDropdownOpened;

    if (_.isNil(this.siteId$) || !this.callSupportDropdownOpened) {
      return;
    }

    this.store.dispatch(new MainActions.GetCustomMailGroups(this.siteId$));
  }

  public onUserDropdownOpened(): void {
    this.userSearchInput = '';
    this.userDropdownOptions = [];
    this.setNoDataLabel(this.searchInputPlaceHolder, 'user');
  }

  public emptyUserSelection(): void {
    this.callSupportForm.user = [];
  }

  public onFromDropDownOpened(): void {
    this.fromSearchInput = '';
    this.fromDropdownOptions = [];
    this.setNoDataLabel(this.searchInputPlaceHolder, 'from');
  }

  public emptyFromSelection(): void {
    this.callSupportForm.from = [];
  }

  public fillUserOptionsIntoDropdown(searchText: string, type: 'user' | 'from'): void {
    this.store.dispatch(new AppActions.ShowTopLoader());
    this.store.dispatch(new HomeActions.SearchUsersByFilter(searchText, this.lineId$));
    this.userSearchFor = type;
  }

  public onSearchModelChange(searchText: string, type: 'user' | 'from'): void {
    if (searchText.length < 3) {
      if (type === 'user') {
        this.userDropdownOptions = [];
      } else {
        this.fromDropdownOptions = [];
      }

      this.setNoDataLabel(this.searchInputPlaceHolder, type);
    }
  }

  public setNoDataLabel(translateKey: string, type: 'user' | 'from'): void {
    switch (type) {
      case 'user':
        this.userDropdownSettings = {
          ...this.userDropdownSettings,
          noDataLabel: this.translate.instant(`cico.modules.laborTracker.searchDropdown.${translateKey}`),
        };
        break;
      case 'from':
        this.fromDropdownSettings = {
          ...this.fromDropdownSettings,
          noDataLabel: this.translate.instant(`cico.modules.laborTracker.searchDropdown.${translateKey}`),
        };
        break;
    }
  }

  siteLineSelectionModal(content) {
    if (!this.changeSiteAndLine) {
      return;
    }
    this.siteLineSelectionTitle = this.translate.instant('siteLineSelection.selectSiteLine');
    this.siteLineSelectionModalRef = this.ngbModal.open(content, {
      windowClass: 'scw-modal-xl',
      keyboard: false,
      backdrop: 'static',
    });
  }

  openCallSupportModal(content, type, additionalData = null) {
    let toValue = '';
    switch (type) {
      case 'ContactIT': {
        toValue = this.translate.instant('general.itTeam');
        break;
      }
      case 'ContactSupervisor': {
        toValue = this.translate.instant('general.supervisorTeam');
        break;
      }
      case 'ContactMaintenance': {
        toValue = this.translate.instant('general.maintenanceTeam');
        break;
      }
      case 'ContactQuality': {
        toValue = this.translate.instant('general.qualityTeam');
        break;
      }
      case 'ContactSCW': {
        toValue = this.translate.instant('general.supplyChainWizardTeam');
        break;
      }
      case 'ContactWarehouse': {
        toValue = this.translate.instant('general.warehouseSuppliesTeam');
        break;
      }
      case 'CustomMailGroup': {
        toValue = additionalData.title;
        break;
      }
    }

    const formValue = this.translate.instant('general.callSupportDescription', {
      lineName: this.lineName,
      siteName: this.siteName,
    });

    this.callSupportForm = {
      additionalData,
      mail: true,
      sms: false,
      textBody: this.translate.instant('general.callSupportDefaultText'),
      contactType: type,
      to: toValue,
      textSubject: formValue,
      user: null,
      character: 160,
      total: 1,
      errors: {
        sentType: null,
      },
      from: [
        {
          id: this.userId,
          optionText: `${this.userName} - ${this.userFullName}`,
          name: this.userFullName,
          shortName: this.userName,
        },
      ],
    };

    this.fromDropdownOptions = [
      {
        id: this.userId,
        optionText: `${this.userName} - ${this.userFullName}`,
        name: this.userFullName,
        shortName: this.userName,
      },
    ];

    this.callSupportModalRef = this.ngbModal.open(content, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-md',
    });
  }

  public setLanguage(language: DropdownOptionsInterface): void {
    this.store.dispatch(new AccountActions.EditAccountSettings({ userLanguage: language.id }));
  }

  onMessageTextareaKeyUp() {
    const maxCharacterSize = 160;
    const size = this.callSupportForm.textBody.length;
    const total = (size / maxCharacterSize + 1).toFixed();
    this.callSupportForm.character = maxCharacterSize - (size % maxCharacterSize);
    this.callSupportForm.total = +total;
  }

  public sendCallSupport(isValid: boolean) {
    this.isCallSupportSubmitButtonClicked = true;

    if (!isValid || (!this.callSupportForm.mail && !this.callSupportForm.sms)) {
      return;
    }

    this.store.dispatch(new AppActions.ShowLoader());

    const data = {
      contactType: this.callSupportForm.contactType,
      textSubject: this.callSupportForm.textSubject,
      textBody: this.callSupportForm.textBody,
      mail: this.callSupportForm.mail,
      sms: this.callSupportForm.sms,
      userId: _.get(this.callSupportForm, 'user[0].id', null),
      customMailGroupId: _.get(this.callSupportForm, 'additionalData.id', null),
      siteId: this.siteId$,
      lineId: this.lineId$,
      from: _.get(this.callSupportForm, 'from[0].id', null),
    };

    this.store.dispatch(
      new MainActions.SendContactSupport(
        data.contactType,
        data.textSubject,
        data.textBody,
        data.mail,
        data.sms,
        data.userId,
        data.customMailGroupId,
        data.siteId,
        data.lineId,
        data.from,
      ),
    );
  }

  backToClassicView() {
    this.store.dispatch(new AppActions.ShowLoader());
    this.store.dispatch(new MainActions.SwitchHomePage(false));
  }

  showSiteTour() {
    this.store.dispatch(new AppActions.SetShowTour(true));
  }

  reloadCurrentPage() {
    window.location.reload();
  }

  public ngOnInit(): void {
    this.store.dispatch(new AccountActions.GetLanguages());
    this.isLineStation = this.router.url.includes('/home/station');

    this.isLineSelectionVisible =
      this.router.url === '/production-review' ||
      this.router.url === '/activity-review' ||
      this.router.url === '/line-observation' ||
      this.router.url === '/home' ||
      this.router.url.startsWith('/production-review') ||
      this.router.url.startsWith('/activity-review') ||
      this.router.url.startsWith('/activity-history') ||
      this.router.isActive('/activity-history', {
        matrixParams: 'exact',
        queryParams: 'ignored',
        paths: 'exact',
        fragment: 'exact',
      });
    this.setBackgroundPattern('theme1');
    this.closeNavigationBar();

    this.isHomeScreen = this.router.url === '/home';

    this.userStoreSubscription = this.store.select('user').subscribe((state: User) => {
      this.notificationList$ = state.notifications;
      this.unSeenNotifications$ = this.notificationList$.unseen;
      this.isDigitalFormsActive$ = state.isDigitalFormsActive;
      this.lineId$ = state.lineId;
      this.siteId$ = state.siteId;
      this.lineName = state.lineName;
      this.siteName = state.siteName;
      this.userName = state.username;
      this.defaultSite$ = Number(state.defaultSite);
      this.userLevel = state.level.parentLevelId ?? state.userLevelId;
      this.userId = Number(state.userId);
      this.userFullName = state.fullName;
      this.hasUserAuthorization$ = Number(state.level.parentLevelId ?? state.userLevelId) === UserLevels.ADMIN;
      this.userMenuPermissions$ = state.menuPermissions;
      this.logbookUrl = state.logbookAccessUrl;
      this.isAccessLogbookActive = state.logbookAccess === 1;
      this.devicePushNotificationToken$ = state.devicePushNotificationToken;
      this.isOeeTrackerActive = state.isOeeTrackerActive;
      this.userLanguage = state.language.toUpperCase();

      if (
        Number(this.userLevel) !== UserLevels.EXECUTIVE &&
        Number(this.userLevel) !== UserLevels.PLANNER &&
        this.userLevel !== null &&
        this.userMenuPermissions$ === null
      ) {
        this.homeAuth = true;
      }

      this.timeZone$ = state.timezone;
      this.momentFormat = MomentDateFormat.DATETIME_24H;
      this.timeFormat$ = MomentDateFormat.TIME_24H;
      const selectedFormat = moment().format(state.dateTimeFormat);

      if (selectedFormat.search('AM') !== -1 || selectedFormat.search('PM') !== -1) {
        this.momentFormat = MomentDateFormat.DATETIME_12H;
        this.timeFormat$ = MomentDateFormat.TIME_12H;
      }

      if (state.clientCode && state.clientCode === '006') {
        this.isSubmitATicketButtonEnabled = false;
      }
    });

    this.getSensorStatusesCompletedSubscription = this.store
      .select('sensorStatusesStore')
      .subscribe((state: ISensorStatusState) => {
        if (state.sensorStatusLoaded && !state.sensorStatusLoading) {
          this.numberOfOfflineDevices = 0;

          this.currentSensorStatuses = state.sensorStatusDetails.map((deviceAndSensors: IDeviceAndSensors) => {
            this.numberOfOfflineDevices += Number(
              deviceAndSensors.deviceStatus &&
                deviceAndSensors.deviceStatus !== 'online' &&
                deviceAndSensors.sensorDetails.length,
            );

            return {
              ...deviceAndSensors,
              sensorDetails: deviceAndSensors.sensorDetails.map((sensorDetail: ISensorStatus) => ({
                ...sensorDetail,
                lastCommDate: sensorDetail.lastCommDate
                  ? momentTz.tz(sensorDetail.lastCommDate, 'UTC').tz(this.timeZone$).format(this.momentFormat)
                  : null,
              })),
            };
          });
        }

        this.decideSensorAndOPCButtonStyle();
      });

    this.lineStoreSubscription = this.store.select('line').subscribe((state) => {
      this.missingDataCount = state.missingDataCount;
    });

    this.mainStoreSubscription = this.store.select('mainStore').subscribe((store: MainStateInterface) => {
      this.showGmpDisclaimerModal = Boolean(store.activeSiteModules?.gmpDisclaimer);
      this.isAppNotificationActive$ = Boolean(store.activeSiteModules?.appNotification);
      this.isActionTrackerActive$ = Boolean(store.activeSiteModules?.actionTracker);

      if (!store.getNavigationMenuLoading && store.getNavigationMenuLoaded) {
        this.changeSiteAndLine = !store.singleSiteAndLine;
        this.newMenuItems = this.menuService.prepareAngularNavigationStructure(_.cloneDeep(store.menu));
        this.clientLogo = store.clientLogo;
        this.hideNavMenu = !store.menu || store.menu.menus?.length === 0 || !this.newMenuItems?.length;
        this.lineVisibleMenuItems = (store.menu.menus ?? [])?.reduce((total, menuItem) => {
          if (lineVisibleUrls.includes(menuItem.link as TLineVisibleUrls)) {
            total[menuItem.link] = true;
          }

          return total;
        }, {});
        this.setSidebarStates();
      }

      if (!store.getCustomMailGroupsLoading && store.getCustomMailGroupsLoaded) {
        this.customMailGroups = store.customMailGroups;
        this.customMailGroupsLoading = false;
      } else {
        this.customMailGroups = [];
        this.customMailGroupsLoading = true;
      }
    });

    this.sendContactSupportCompletedActionSubscription = this.mainActions
      .pipe(ofType(MainActions.MainActionTypes.SendContactSupportCompleted))
      .subscribe((response: any) => {
        if (response.payload.success) {
          this.displayToastMessage('success', 'contactSuccessful');
        }

        if (response.payload.success === false && response.payload.data.userCount === 0) {
          this.displayToastMessage('error', 'contactNoContact');
        }

        if (!response.payload.success && response.payload.data === undefined) {
          this.displayToastMessage('error', 'contactWarning');
        }

        this.ngbModal.dismissAll();
      });

    if (this.pcodedDeviceType !== this.deviceType.desktop) {
      this.pageHeaderPaddingTop = '0';
    }

    this.pageHeaderSubscription = this.pageHeaderService.pageHeader.subscribe((item: PageHeaderInterface) => {
      this.pageHeader = {
        title: item.title,
        titleKey: item.titleKey,
        icon: item.icon,
        fullScreenButton: item.fullScreenButton,
        fullScreenTargetElementId: item.fullScreenTargetElementId,
        breadcrumbs: item.breadcrumbs,
        isTabPage: item.isTabPage,
        showPageConfiguration: item.showPageConfiguration,
        showCountDownButton: item.showCountDownButton,
        showFilterBarVisibilityButton: item.showFilterBarVisibilityButton,
        showPrintFunctionalityButton: item.showPrintFunctionalityButton,
        showElementVisibilityButton: item.showElementVisibilityButton,
        elementVisibilityTranslations: item.elementVisibilityTranslations,
      };
      this.store.dispatch(new PageHeaderActions.ResetStoreToDefault());
    });

    this.storeSubscriptions.push(
      this.mainActions
        .pipe(ofType(HomeActions.HomeActionTypes.SearchUsersByFilterCompleted))
        .subscribe((payload: UserGetManyCRUDDataInterface) => {
          function getDropdownOptions() {
            return (item) => {
              return {
                id: item.id,
                optionText: `${item.userName} - ${item.fullName}`,
                name: item.fullName,
                shortName: item.userName,
              };
            };
          }

          if (this.userSearchFor === 'user') {
            this.userDropdownOptions = [];
            const data: UserGetOneCRUDDataInterface[] = payload.data;

            this.userDropdownOptions = data.map(getDropdownOptions());

            if (data.length === 0) {
              this.setNoDataLabel('noResultMessage', 'user');
            }
          }

          if (this.userSearchFor === 'from') {
            this.fromDropdownOptions = [];
            const data: UserGetOneCRUDDataInterface[] = payload.data;

            this.fromDropdownOptions = data.map(getDropdownOptions());

            if (data.length === 0) {
              this.setNoDataLabel('noResultMessage', 'from');
            }
          }

          this.store.dispatch(new AppActions.HideTopLoader());
        }),
      this.mainActions
        .pipe(ofType(StationActivityHistoryActions.ActionTypes.STATION_DROPDOWN_ITEMS_LOADING))
        .subscribe(() => {
          this.isStationInformationLoading$ = true;
        }),
      this.mainActions
        .pipe(ofType(StationActivityHistoryActions.ActionTypes.STATION_DROPDOWN_ITEMS_LOADED))
        .subscribe((payload: StationActivityHistoryActions.StationDropdownItemsLoaded) => {
          this.isStationInformationLoading$ = false;
          this.stations = _.cloneDeep(payload.response.data);
        }),
      this.store.select('homeStore').subscribe((state: HomeStateInterface) => {
        this.isHomeInformationLoading$ = state.homeInformationLoading;
      }),
      this.mainActions
        .pipe(ofType(HomeActions.HomeActionTypes.IsOpcIntegrationInterrupted))
        .subscribe((payload: HomeActions.IsOpcIntegrationInterrupted) => {
          this.isHaveOPCFailure = payload.value;
          this.decideSensorAndOPCButtonStyle();
        }),
      this.mainActions
        .pipe(ofType(AccountActions.GET_LANGUAGES_COMPLETED))
        .subscribe((response: { response: ResponseInterface<LanguageInterface[]> }) => {
          this.languages = response.response.data.map((data: LanguageInterface) => {
            return {
              id: data.abbreviation,
              name: data.language,
            };
          });
        }),
      this.mainActions
        .pipe(ofType(AccountActions.EDIT_ACCOUNT_SETTINGS_COMPLETED))
        .subscribe((response: { response: ResponseInterface<AccountSettingsInterface> }) => {
          if (response.response.success) {
            const successTranslation: string = this.translate.instant('general.success');
            const successTranslationMessage: string = this.translate.instant('settings.account.save.success.message');
            this.toastHelperService.showToastMessage(true, successTranslation, successTranslationMessage);
            this.reloadCurrentPage();
          }
        }),
    );

    const isToastShownOnLogin: boolean = Boolean(localStorage.getItem('toastShownOnLogin'));
    this.isThePaymentStatusRequestedPage = this.router.url.includes('home') || this.router.url === '/production-review';

    if (!isToastShownOnLogin || this.isThePaymentStatusRequestedPage) {
      this.http.get(`${environment.checkPaymentStatusUrl}/${this.userId}`).subscribe(
        (response: {
          showToast: boolean;
          message: {
            toastMessage: string;
            modalMessage: string;
          };
          title: string;
          modalInterval: number;
        }) => {
          if (response.showToast) {
            if (!isToastShownOnLogin) {
              this.toast.warning(response.message.toastMessage, response.title, {
                ...{
                  closeButton: true,
                  progressBar: false,
                  disableTimeOut: true,
                  tapToDismiss: true,
                  positionClass: 'toast-bottom-right',
                },
                ...{ enableHtml: true },
              });
              localStorage.setItem('toastShownOnLogin', 'true');
            }

            this.setPaymentStatusTimer(response);
          }
        },
      );
    }
  }

  displayToastMessage(type, messageKey) {
    const toastMessage = this.translate.instant(`general.${messageKey}`);
    const toastTitle = this.translate.instant(`general.${type}`);

    if (type === 'success') {
      this.toast.success(toastMessage, toastTitle, {
        closeButton: true,
        progressBar: true,
        positionClass: this.toastPosition,
      });
    }
    if (type === 'error') {
      this.toast.error(toastMessage, toastTitle, {
        closeButton: true,
        progressBar: true,
        positionClass: this.toastPosition,
      });
    }
  }

  public userLogout(): void {
    this.unsubscribeDeviceFromPushNotifications();
    localStorage.clear();
    this.monitoringService.logoutAuthorizedUser();
    window.location.assign(`${environment.ssoUrl}${environment.ssoLogoutUrl}`);
  }

  ngOnDestroy(): void {
    if (this.sendContactSupportCompletedActionSubscription) {
      this.sendContactSupportCompletedActionSubscription.unsubscribe();
    }
    if (this.userStoreSubscription) {
      this.userStoreSubscription.unsubscribe();
    }
    if (this.lineStoreSubscription) {
      this.lineStoreSubscription.unsubscribe();
    }

    if (this.pageHeaderSubscription) {
      this.pageHeaderSubscription.unsubscribe();
    }

    if (this.getSensorStatusesCompletedSubscription) {
      this.getSensorStatusesCompletedSubscription.unsubscribe();
    }

    if (this.mainStoreSubscription) {
      this.mainStoreSubscription.unsubscribe();
    }

    this.timer?.unsubscribe();
    this.paymentStatusTimer?.unsubscribe();
    this.isHomeScreen = false;

    this.store.dispatch(new AppActions.HideLoader());
  }

  ngAfterContentInit(): void {
    this.zone.runOutsideAngular(() => {
      const oneSecondTimer = timer(0, 1000);
      this.timer = oneSecondTimer.subscribe(() => {
        const time = getCurrentDateTime(this.timeZone$);
        if (this.clock === undefined || !moment(time).isSame(this.clock, 'minute')) {
          this.zone.run(() => {
            this.clock = time;
          });
        }
      });
    });
  }

  private setApplicationSwitch() {
    return new Promise((resolve, reject) => {
      this.http.post('/api/solutionswitch?solution=SCHEDULER', {}).subscribe((response: any) => {
        if (response.hasOwnProperty('redirectURL')) {
          resolve(response);
        } else {
          reject();
        }
      });
    });
  }

  public onCloseModal(modal: NgbModalRef, result: TModalResultType): void {
    modal.close(result);
  }

  public clickDigitalFormsButton(): void {
    if (!this.isDigitalFormsActive$) {
      this.router.navigate(['/book-a-demo'], { queryParams: { module: 'digitalForms' } });
      this.store.dispatch(new AppActions.HideLoader());

      return;
    }

    this.router.navigate(['/line-observation']);
  }

  public onStationClick(id: number): void {
    this.router.navigate([`/home/station/${id}`]);
  }

  public openPaymentStatusModal(message: string, title: string): void {
    if (!this.isThePaymentStatusRequestedPage) {
      return;
    }

    this.paymentStatusModalMessage = message;
    this.paymentStatusModalTitle = title;

    this.paymentStatusModalRef?.dismiss();
    this.paymentStatusModalRef = this.ngbModal.open(this.paymentStatusModalTemplateRef, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-md',
    });
  }

  public setPaymentStatusTimer(response: {
    showToast: boolean;
    message: {
      toastMessage: string;
      modalMessage: string;
    };
    title: string;
    modalInterval: number;
  }): void {
    if (!this.isThePaymentStatusRequestedPage) {
      return;
    }

    this.zone.runOutsideAngular(() => {
      const setTimer: Observable<number> = timer(0, response.modalInterval ?? 3600000);
      this.paymentStatusTimer = setTimer.subscribe((index: number) => {
        this.zone.run(() => {
          if (index === 0) {
            this.openPaymentStatusModal(response.message.modalMessage, response.title);
          }

          if (index !== 0) {
            this.http.get(`${environment.checkPaymentStatusUrl}/${this.userId}`).subscribe(
              (response: {
                showToast: boolean;
                message: {
                  modalMessage: string;
                };
                title: string;
              }) => {
                if (response.showToast) {
                  this.openPaymentStatusModal(response.message.modalMessage, response.title);
                }
              },
            );
          }
        });
      });
    });
  }

  public setSiteLineSelectionMode(mode: ESiteLineSelectionMode): void {
    this.siteLineSelectionMode = mode;
  }

  public onCreateQuickItemClicked(): void {
    this.ngbModal.open(CreateQuickIssueActionModalComponent, {
      ...smallModal,
      windowClass: `${ScwModalSize.small} scw-modal-all-scrollable`,
    });
  }

  private decideSensorAndOPCButtonStyle(): void {
    const totalSensorCount: number = this.currentSensorStatuses.length;
    const isHaveUnknownSensorStatus: boolean = this.currentSensorStatuses.some(
      (details: IDeviceAndSensors) => !details.deviceStatus,
    );

    if (!this.isHomeScreen) {
      return;
    }

    if (this.isHaveOPCFailure) {
      this.store.dispatch(
        new PageHeaderActions.SetHeaderExceptionIndicator({
          message: this.translate.instant('opc.failure.message.homePage'),
          type: EHeaderExceptionType.WARNING,
        }),
      );
    } else {
      this.store.dispatch(new PageHeaderActions.SetHeaderExceptionIndicator(null));
    }

    if (this.isHaveOPCFailure || this.numberOfOfflineDevices > 0) {
      this.sensorAndOPCButtonStyle = ESensorAndOPCButtonStyle.FAILURE;
      return;
    }

    if (this.isHaveOPCFailure === null && (isHaveUnknownSensorStatus || totalSensorCount === 0)) {
      this.sensorAndOPCButtonStyle = ESensorAndOPCButtonStyle.UNKNOWN;
      return;
    }

    this.sensorAndOPCButtonStyle = ESensorAndOPCButtonStyle.OPERATIONAL;
  }

  private unsubscribeDeviceFromPushNotifications(): void {
    if (!this.devicePushNotificationToken$ || !ScwFirebaseService.pushNotificationsServiceUrl) {
      return;
    }

    PushNotificationService.unsubscribeFromTopics(this.http, this.devicePushNotificationToken$).subscribe();
  }
}
