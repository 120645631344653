import { IMeta } from '../../settings/home-page-display-settings/home-metrics/home-metrics.model';
import { EHomeMetrics } from '../../settings/home-page-display-settings/home-page-display-settings.constants';
import { HomeMetricTypes } from '../../../shared/component/kpi-cards-information/kpi-cards-information.model';
import { ActivityTypes } from '../../../shared/model/enum/activity-types';

export interface IHomeScreenMetric {
  key: EHomeMetrics;
  component: any;
  componentType: EComponentType;
  dashboardMode: boolean | undefined;
  homeMetricSetProperties: IMeta;
}

export type HomeMetrics = {
  [homeMetric in HomeMetricTypes]: IHomeScreenMetric;
};

export interface CountEntriesInterface {
  goodCount: string;
  scrapCount: string;
}

export enum EComponentType {
  SPEED = 'speed',
  DURATION = 'duration',
  COUNT = 'count',
  TOTAL = 'total',
  POST_RUN = 'post-run',
  RUN = 'run',
  PRE_RUN = 'pre-run',
}

export interface IAddOeeToNonActivityHours {
  date: string;
  value: string;
}

export interface IHomeMetricSiteLineInfo {
  lineID: number;
  siteID: number;
  lineTitle: string;
  siteName: string;
}

export interface ISimplifiedActivityCard {
  activityName?: string;
  activityType?: ActivityTypes
  taskName?: string;
  backgroundColour: string;
}

export interface ISimplifiedWorkOrderCard {
  workOrderName?: string;
  productName?: string;
  productDescription?: string;
  backgroundColour: string;
}

export interface ISiteLineName {
  siteName: string;
  lineTitle: string;
}
