import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { HelperService } from '../../../../shared/service/helper.service';
import { TranslateService } from '@ngx-translate/core';
import { DecimalHelper } from '../../../../shared/helper/decimal/decimal-helper';
import { User } from '../../../../store/user/model';
import * as _ from 'lodash';
import { DECIMAL_DEFAULT_SCALE_LIMIT, DECIMAL_NUMERIC_SCALE } from '../../../../../constants';
import { Line } from '../../../../store/line/model';
import { HomePageDisplaySettingsUtilities } from '../../../settings/home-page-display-settings/home-page-display-settings.utilities';
import { IWorkOrderDurationMeta } from '../../../settings/home-page-display-settings/home-metrics/home-metrics.model';
import { IWorkOrderDurationData } from '../../../settings/home-page-display-settings/home-page-display-settings.model';
import { PROPERTIES } from '../home-metrics.component';

@Component({
  selector: 'work-order-duration-metric',
  templateUrl: './work-order-duration-metric.component.html',
})
export class WorkOrderDurationMetricComponent implements OnInit, OnDestroy {
  public workOrderActualDuration: string = '0';
  public workOrderTargetDuration: string = '0';
  private userSubscription: Subscription;
  private lineStoreSubscription: Subscription;
  private siteDecimalScaleLimit$: number;
  public workOrderDurationText: string = '';
  public unit: string = '';
  public woDurationAndTargetRateCapped: number = null;
  public woDurationAndTargetRate: string = null;
  public properties: IWorkOrderDurationMeta = null;

  constructor(
    public helperService: HelperService,
    private store: Store<OeeAppState>,
    private translate: TranslateService,
    private readonly decimalHelper: DecimalHelper,
    @Inject(PROPERTIES) private readonly metricProperties: IWorkOrderDurationMeta,
  ) {
    this.properties = metricProperties;
  }

  public ngOnInit(): void {
    this.workOrderDurationText = this.translate.instant('main.workOrder.workOrderDuration');
    this.unit = this.translate.instant('general.shortHour');
    this.userSubscription = this.store.select('user').subscribe((state: User) => {
      this.siteDecimalScaleLimit$ = state.siteDecimalScaleLimit;
    });

    this.lineStoreSubscription = this.store.select('line').subscribe((state: Line) => {
      this.woDurationAndTargetRate = null;
      this.woDurationAndTargetRateCapped = null;
      this.workOrderActualDuration = null;
      this.workOrderTargetDuration = null;

      const minutesInOneHour: string = '60';
      let expectedSpeed = this.decimalHelper.removeThousandSeparator(state.expectedSpeed);
      expectedSpeed = this.decimalHelper.sanitizeString(expectedSpeed);

      const quantityOrderedPerSpeed: string = this.decimalHelper.divide(
        this.decimalHelper.divide(state.workOrderQuantityOrdered, expectedSpeed),
        minutesInOneHour,
      );

      const actualWorkOrderDurationValues: IWorkOrderDurationData[] =
        HomePageDisplaySettingsUtilities.getActualWorkOrderDurationValues(state.quantities, this.decimalHelper);

      this.workOrderActualDuration = _.find(actualWorkOrderDurationValues, {
        name: this.properties.actualWorkOrderDuration,
      }).value;

      this.workOrderActualDuration = this.decimalHelper.divide(this.workOrderActualDuration, minutesInOneHour);

      const targetWorkOrderDurationValues: IWorkOrderDurationData[] =
        HomePageDisplaySettingsUtilities.getTargetWorkOrderDurationValues(
          state.quantities,
          quantityOrderedPerSpeed,
          this.decimalHelper,
        );

      this.workOrderTargetDuration = _.find(targetWorkOrderDurationValues, {
        name: this.properties.targetWorkOrderDuration,
      })?.value;

      const workOrderActualDurationForRate: string = this.decimalHelper.toFixedValue(
        HelperService.cloneDeep(this.workOrderActualDuration),
        DECIMAL_NUMERIC_SCALE,
        false,
      );
      const workOrderTargetDurationForRate: string = this.decimalHelper.toFixedValue(
        HelperService.cloneDeep(this.workOrderTargetDuration),
        DECIMAL_NUMERIC_SCALE,
        false,
      );

      this.woDurationAndTargetRate = this.decimalHelper.calculateRate(
        workOrderActualDurationForRate,
        workOrderTargetDurationForRate,
        DECIMAL_DEFAULT_SCALE_LIMIT,
      );

      this.woDurationAndTargetRateCapped = this.decimalHelper.decimalToNumberFormatter(
        this.decimalHelper.calculateRate(
          workOrderActualDurationForRate,
          workOrderTargetDurationForRate,
          DECIMAL_DEFAULT_SCALE_LIMIT,
          false,
          true,
          true,
        ),
      );

      this.workOrderActualDuration = this.decimalHelper.toFixedValue(
        this.workOrderActualDuration,
        this.siteDecimalScaleLimit$,
      );
      this.workOrderTargetDuration = this.decimalHelper.toFixedValue(
        this.workOrderTargetDuration,
        this.siteDecimalScaleLimit$,
      );
    });
  }

  public ngOnDestroy(): void {
    if (this.lineStoreSubscription) {
      this.lineStoreSubscription.unsubscribe();
    }
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
  }
}
