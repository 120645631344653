import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { Action, ActionsSubject, Store } from '@ngrx/store';
import { CommentFeedModalComponent } from 'src/app/standalone/comment-feed/comment-feed-modal/comment-feed-modal.component';
import { TagType } from 'src/app/store/settings/tags/tags.model';
import { OeeAppState } from '../../../../store/oee.reducer';
import * as AppActions from '../../../../store/app/actions';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { InputLimit } from '../../../model/enum/input-limit';
import * as ActivityButtonActions from '../../../../store/activity-button/activity-button.actions';
import * as ActivityButtonMultiLineActions from '../../../../store/activity-button-multi-line/activity-button-multi-line.actions';
import { combineLatest, Observable, Subject, Subscription } from 'rxjs';
import { activityStyles } from '../../../helper/home-helper';
import {
  ActivityTaskUpdateInterface,
  ButtonDataInterface,
} from '../../../../store/activity-button/activity-button.model';
import { ActivityTypes } from '../../../model/enum/activity-types';
import { ToastrService } from 'ngx-toastr';
import {
  ChangeActivityRequestInterface,
  ICurrentActivity,
  IRequestChangeActivityParameters,
  PhaseCommentInterface,
  PhaseCommentsEnteredStatusInterface,
  PhaseCommentStoreDataInterface,
  RequestTypeEnum,
  UpdateCurrentActivityResponseInterface,
  WorkOrderCardInterface,
  WorkOrderCloseDataInterface,
  WorkOrderCloseResponseInterface,
} from '../../../../store/home/home.model';
import { filter, map, take } from 'rxjs/operators';
import * as WorkOrderFinalizeActions from '../../../../store/work-order-finalize/work-order-finalize.actions';
import * as HomeActions from '../../../../store/home/home.actions';
import { WoFinalQuantityFormDataService } from '../../../../store/work-order-finalize/wo-final-quantity-form-data.service';
import { WorkOrderFinalizeStateInterface } from '../../../../store/work-order-finalize/work-order-finalize.model';
import { ChangeActivityResponseInterface, Line } from '../../../../store/line/model';
import { ofType } from '@ngrx/effects';
import { HelperService } from '../../../service/helper.service';
import {
  EquipmentTaskOutputInterface,
  IStartEquipmentTaskParameter,
} from '../../../../store/equipment-task/equipment-task.model';
import { PhaseFieldInterface } from '../../progress-bar-metric/work-order-phase-comment-modal/work-order-phase-comment-modal.model';
import { CommentType } from '../../../../view/shift-summary/shift-summary.model';
import * as WorkOrderScheduleActions from '../../../../store/work-order-schedule/work-order-schedule.actions';
import * as _ from 'lodash';
import { PhaseDurationsInterface } from '../../../../view/home/home-metrics/common-phase-duration-metric/common-phase-duration-metric.model';
import { BatchCloseComponent } from '../../../../view/home/work-order/batch-close/batch-close.component';
import { ELanguages, TOtherLanguageOptions } from '../../../../../constants';
import { DecimalHelper } from '../../../helper/decimal/decimal-helper';
import { IRootAddress } from '../../../../store/settings/tree-nodes/tree-nodes.model';
import { ColorService } from '../../../service/color/color.service';
import { EntityTranslatorService } from '../../../service/entity-translator/entity-translator.service';
import * as StationActivityHistoryActions from '../../../../store/station-activity-history/station-activity-history.actions';
import {
  IStationActivityHistoryActivityChangeRequest,
  IStationActivityHistoryState,
} from '../../../../store/station-activity-history/station-activity-history.model';
import { SiteInterface } from 'src/app/store/site-line-selection/site-line-selection.model';
import { OnDestroyDecorator } from '../../../decorator/on-destroy-decorator';
import {
  ISelectWorkOrderModalConfigurations,
  WorkOrderHeader,
} from '../../../../view/home/work-order/work-order.model';
import { WorkOrderLineChangeInterface } from '../../select-work-order/select-work-order.model';
import { ActivitiesInterface, IActivitiesTasksInterface } from '../../../model/interface/activities.model';
import * as LineActions from '../../../../store/line/actions';
import { ELoadStatus, TDecimalSeparator, TThousandSeparator } from '../../../../../constants.model';
import { SemiManualCountButtonService } from '../../../service/station-home/station-home-kpi/semi-manual-count-button.service';

@OnDestroyDecorator
@Component({
  selector: 'app-activity-selection-button',
  templateUrl: './activity-selection-button.component.html',
  styleUrls: [
    './activity-selection-button.component.scss',
    '../../../../../../node_modules/sweetalert2/src/sweetalert2.scss',
    '../../../../../styles.scss',
    '../../../../view/home/home.component.scss',
  ],
})
export class ActivitySelectionButtonComponent implements OnInit, OnDestroy {
  @Input() action: string;
  @Input() lineId: number = null;
  @Input() siteId: number = undefined;
  @Input() renderedFromFinalizerButtonComponent: boolean = false;
  @Input() selectedWorkOrderControl: boolean = false;
  @Input() allSites: SiteInterface[] | undefined;
  @Input() isCalledFromActivityHistory: boolean = false;
  @Input() ignoreHidden: boolean;
  @Input() useMultiLineStore: boolean = false;

  @Output() editedActivityButtonEvent = new EventEmitter<ActivityTaskUpdateInterface>();
  @Output() openBatchClose = new EventEmitter<WorkOrderCloseDataInterface>();
  @ViewChild('workOrderPhaseComment') workOrderPhaseCommentModalContent: ElementRef;
  @ViewChild('pleaseSelectWorkOrderModal') pleaseSelectWorkOrderModalContent: ElementRef;
  @ViewChild('work_order_finalizer') workOrderFinalizerContent: ElementRef;
  @ViewChild('work_order_edit', { static: false }) workOrderModalContent: ElementRef;

  public startEquipmentTaskParameters: IStartEquipmentTaskParameter;
  public selectedActivityId: number;
  public selectedTaskId: number;
  public selectedActivityName: string;
  public selectedActivityNameTranslations: TOtherLanguageOptions;
  public selectedActivityType: string;
  public modalRef: NgbModalRef;
  public activityIsChanging: boolean = false;
  public buttonsData: ButtonDataInterface[] = [];
  public equipmentAndTaskTitle: string;
  public isAutomatedPhaseCommentsActive: boolean = false;
  public phaseCommentsEnteredStatus: PhaseCommentsEnteredStatusInterface = null;
  public phase: PhaseFieldInterface = {
    phaseText: null,
    actual: null,
    target: null,
  };
  private changeActivityRequestParams: ChangeActivityRequestInterface;
  public phaseType: CommentType = null;
  public phaseComment: PhaseCommentInterface = null;
  public shouldWorkOrderFinalizeModalOpen: boolean;

  InputLimit = InputLimit;
  private readonly storeSubscriptions: Subscription[] = [];
  private updateCurrentActivityCompletedActionSubscription: Subscription;
  private workOrderPhaseCommentModalRef: NgbModalRef;
  private activityCommentEditModalRef: NgbModalRef;
  public activityPhase: number;
  public selectedPhase$: number = null;
  public previousPhase$: number = null;
  public workOrderTableId: number = null;
  private isPhaseCommentModalOpen: boolean = null;
  public workOrderFinalizeQuantityServiceData: WorkOrderCloseResponseInterface = null;
  public language$: ELanguages;
  public pleaseSelectWorkOrderModalRef: NgbModalRef;
  public workOrderFinalizerRef: NgbModalRef;
  public workOrderCloseData: WorkOrderCloseDataInterface;
  public changeActivityRequestData: ChangeActivityRequestInterface;
  public allowChangeActivityAfterFinalizerActivite: boolean = false;
  public activityIsFinalizer: boolean = false;
  public currentWorkOrderId: number;
  public backToSelectEquipment: Subject<boolean> = new Subject();
  public taskTreeRootAddress: IRootAddress = { activityId: null, lineId: null };
  private isChangeActivityRequest: boolean = false;
  public currentActivity: ICurrentActivity = {
    isDurationExceedCommentRequired: false,
    isCommentRequired: false,
    activityAndTaskName: '',
    activityName: '',
    comment: '',
    taskTarget: '',
  };
  private initialComment: string = '';
  private decimalSeparator: TDecimalSeparator;
  private thousandSeparator: TThousandSeparator;
  public homeStation: { id?: number; activityType?: ActivityTypes; isWorkOrderSelected: boolean } = {
    id: 0,
    activityType: undefined,
    isWorkOrderSelected: false,
  };
  private isOpenedOnStationHome: boolean;
  public lineId$: number;
  public siteId$: number;

  public workOrderCard: Partial<WorkOrderCardInterface> = {
    workOrderId: null,
    productId: null,
    wOTableId: null,
    woDueDate: null,
    productDescription: null,
    workOrderGoodCount: null,
    workOrderQuantityOrdered: null,
    speedProgress: null,
    versionId: null,
    versionName: null,
    productTableId: null,
    start: null,
    end: null,
    unitName: null,
  };
  public selectWorkOrderModalConfigurations$: ISelectWorkOrderModalConfigurations = null;
  public workOrderHeader: WorkOrderHeader;
  public selectWorkOrderRef: NgbModalRef = null;
  public doYouWantToSelectWorkOrderModalRef: NgbModalRef;
  public addEditWorkOrderRef: NgbModalRef = null;
  public isRequestFromSelectWorkOrderModal: boolean = false;
  public requestChangeActivityParameters: IRequestChangeActivityParameters = {
    taskId: null,
    activityId: null,
  };
  public currentActivityType: string | undefined;
  public isDurationExceedComment: boolean = false;

  constructor(
    private readonly store: Store<OeeAppState>,
    private readonly translate: TranslateService,
    private readonly toast: ToastrService,
    private readonly actionsSubject: ActionsSubject,
    private readonly woFinalQuantityService: WoFinalQuantityFormDataService,
    private readonly helperService: HelperService,
    private readonly decimalHelper: DecimalHelper,
    private readonly colorService: ColorService,
    private hostElement: ElementRef,
    private readonly translatorService: EntityTranslatorService,
    private readonly ngbModal: NgbModal,
  ) {}

  ngOnInit(): void {
    this.isOpenedOnStationHome = this.helperService.isHomeRelatedComponentOpenedOnStation();
    this.lineId = +this.lineId;

    const fetchActivitiesAction: Action = this.useMultiLineStore
      ? new ActivityButtonMultiLineActions.FetchActivitiesOfLine(this.lineId)
      : new ActivityButtonActions.StartActivityButtonDataLoading(this.lineId);
    this.store.dispatch(fetchActivitiesAction);

    const activityButtonStore: Observable<ActivitiesInterface[]> = this.useMultiLineStore
      ? this.store.select('activityButtonMultiLine').pipe(
          filter((state) => state.fetchStatus === ELoadStatus.Success && Boolean(state.activitiesByLines[this.lineId])),
          map((state) => state.activitiesByLines[this.lineId]),
        )
      : this.store.select('activityButton').pipe(
          filter((value) => !value.isLoading && value.isLoaded),
          map((state) => state.activities),
        );
    this.storeSubscriptions.push(
      this.store.select('user').subscribe((state) => {
        this.language$ = state.language as ELanguages;
        this.decimalSeparator = state.decimalSeparator;
        this.thousandSeparator = state.thousandSeparator;
        this.siteId$ = state.siteId;
        this.lineId$ = state.lineId;

        this.selectWorkOrderModalConfigurations$ = {
          siteName: state.siteName,
          siteId: this.siteId$,
          lineId: this.lineId$,
          lineType: null,
        };
      }),
      combineLatest([
        this.store.select('user').pipe(filter((value) => value.isUserLoaded === true)),
        activityButtonStore,
      ]).subscribe(([user, activities]) => {
        const site: number = this.siteId ?? user.siteId;
        const componentColors = this.colorService.pickComponentColors('plannedDownTimeActivityButton', {
          visibleSiteIds: [site],
          allSites: this.allSites ?? [],
          client: user.clientInfo,
        });
        this.hostElement.nativeElement.style.setProperty('--planned-down-time-color', componentColors.background);
        this.hostElement.nativeElement.style.setProperty('--planned-down-time-color-text', componentColors.text);
        this.buttonsData = [];
          let buttonsDataWithOrder: ButtonDataInterface[] = [];
          const buttonsDataWithOutOrder: ButtonDataInterface[] = [];

        for (const activity of activities) {
          let className = '';

          switch (activity.activityType) {
            case ActivityTypes.RUN_TIME:
              className = 'btn-run-time';
              break;
            case ActivityTypes.DOWN_TIME:
              className = 'btn-down-time';
              break;
            case ActivityTypes.DOWN_TIME_PLANNED:
              className = 'btn-down-time-planned';
              break;
            case ActivityTypes.IDLE_TIME:
              className = 'btn-light btn-outline-light';
              break;
          }

          if (activity.hideOnHomePage && this.ignoreHidden) {
            continue;
          }

          const buttonsDataValue: ButtonDataInterface = {
            id: activity.id,
            name: activity.name,
            nameTranslations: activity.nameTranslations,
            type: activity.activityType,
            typeTitle: activity.activityTypeRelation === null ? null : activity.activityTypeRelation.type,
            class: className,
            icon: activityStyles(
              activity.activityType,
              activity.activitySubTypeRelation === null ? null : activity.activitySubTypeRelation.type,
            ),
            activityPhase: activity.activityPhase,
            automationActivityName: _.kebabCase(activity.originalEntityProperty?.['name'] ?? activity.name),
            tasks: activity.tasks,
            order: activity.activityOrder === undefined ? null : activity.activityOrder,
          };

          if (!_.isNil(activity.activityOrder)) {
            buttonsDataWithOrder.push(buttonsDataValue);
          } else {
            buttonsDataWithOutOrder.push(buttonsDataValue);
          }
        }

        buttonsDataWithOrder = _.sortBy(buttonsDataWithOrder, 'order');
        this.buttonsData = buttonsDataWithOrder.concat(buttonsDataWithOutOrder);
      }),

      this.store.select('line').subscribe((state: Line) => {
        this.selectedPhase$ = HelperService.cloneDeep(state.selectedPhase);
        this.currentActivity.isCommentRequired = state.isCommentRequired;
        this.currentActivity.activityAndTaskName = `${state.activityName} - ${state?.taskName}`;
        this.currentActivity.isDurationExceedCommentRequired = state.isDurationExceedCommentRequired;
        this.currentActivity.taskTarget = state.activityTarget;
        this.initialComment = HelperService.cloneDeep(state.activityDescription);
        this.selectWorkOrderModalConfigurations$.lineType = HelperService.cloneDeep(state.lineType);
      }),

      this.actionsSubject
        .pipe(ofType(HomeActions.HomeActionTypes.ChangeActivityCompleted))
        .subscribe((payload: { payload: ChangeActivityResponseInterface }) => {
          this.activityIsChanging = false;
          if (payload.payload.success) {
            this.toast.success(
              this.translate.instant('activityCards.messages.activityChangeSuccess'),
              this.translate.instant('general.success'),
              {
                closeButton: true,
                progressBar: true,
                positionClass: 'toast-bottom-right',
              },
            );
          }
          this.store
            .select('workOrderFinalize')
            .pipe(take(1))
            .subscribe((state: WorkOrderFinalizeStateInterface) => {
              if (!state.inProgress) {
                this.store.dispatch(new AppActions.HideLoader());
              }
            });
        }),
      this.actionsSubject
        .pipe(ofType(StationActivityHistoryActions.ActionTypes.STATION_ACTIVITY_HISTORY_ACTIVITY_CHANGE_LOADED))
        .subscribe((payload: StationActivityHistoryActions.StationActivityHistoryActivityChangeLoaded) => {
          if (payload.response.data.activityType === ActivityTypes.RUN_TIME) {
            this.store.dispatch(new AppActions.HideLoader());
          }

          this.helperService.showToastMessage(
            true,
            this.translate.instant('general.success'),
            this.translate.instant('activityCards.messages.activityChangeSuccess'),
          );
        }),

      this.store.select('homeStore').subscribe((state) => {
        this.activityIsChanging = state.changeActivityLoading;
        this.isAutomatedPhaseCommentsActive = state.isAutomatedPhaseCommentsActive;
        this.phaseCommentsEnteredStatus = state.phaseCommentsEnteredStatus;
        this.previousPhase$ = state.previousPhase;
        this.activityIsFinalizer = state.line?.activityCard?.batchFinalizer;

        if (state.phaseCommentsLoaded && state.phaseDurationLoaded && this.isPhaseCommentModalOpen === false) {
          this.isPhaseCommentModalOpen = true;
          this.workOrderPhaseCommentModalRef = this.ngbModal.open(
            this.workOrderPhaseCommentModalContent,
            {
              keyboard: false,
              backdrop: 'static',
              windowClass: 'scw-modal-sm',
            },
          );
        }

        if (state.phaseCommentSubmitLoading && !state.phaseCommentSubmitLoaded && this.workOrderPhaseCommentModalRef) {
          this.workOrderPhaseCommentModalRef.dismiss();
        }
      }),

      this.actionsSubject
        .pipe(ofType(HomeActions.HomeActionTypes.PhaseCommentsLoaded))
        .subscribe((payload: { response: PhaseCommentStoreDataInterface }) => {
          this.phaseComment = this.helperService.preparePhaseComment(payload.response, this.previousPhase$);
        }),

      this.actionsSubject
        .pipe(ofType(HomeActions.HomeActionTypes.PhaseDurationsLoaded))
        .subscribe((payload: { payload: PhaseDurationsInterface }) => {
          const phaseAndPhaseType = this.helperService.preparePhaseAndPhaseType(payload.payload, this.previousPhase$);
          this.phase = phaseAndPhaseType.phase;
          this.phaseType = phaseAndPhaseType.phaseType;
        }),

      this.actionsSubject
        .pipe(
          ofType(
            HomeActions.HomeActionTypes.CreatePhaseCommentCompleted,
            HomeActions.HomeActionTypes.UpdatePhaseCommentCompleted,
            HomeActions.HomeActionTypes.DeletePhaseCommentCompleted,
            WorkOrderScheduleActions.EDIT_WORK_ORDER_COMPLETED,
          ),
        )
        .subscribe(() => {
          if (this.workOrderFinalizeQuantityServiceData !== null) {
            this.openBatchClose.emit(this.workOrderCloseData);
          }

          this.workOrderFinalizeQuantityServiceData = null;
        }),

      this.actionsSubject
        .pipe(ofType(HomeActions.HomeActionTypes.UpdateCurrentActivityCompleted))
        .subscribe((payload: { payload: UpdateCurrentActivityResponseInterface }) => {
          if (this.allowChangeActivityAfterFinalizerActivite) {
            this.allowChangeActivityAfterFinalizerActivite = false;
            this.store.dispatch(new HomeActions.ChangeActivity(this.changeActivityRequestData));
            this.showPhaseCommentModal(false);
            this.store.dispatch(new HomeActions.StartLoadHomeInformation());
          }

          if (this.activityCommentEditModalRef) {
            this.activityCommentEditModalRef.dismiss();
          }

          if (payload.payload.success && this.isChangeActivityRequest) {
            this.store.dispatch(new AppActions.ShowLoader());
            this.changeActivity(this.changeActivityRequestParams);
          }

          this.isChangeActivityRequest = false;
        }),

      this.actionsSubject.pipe(ofType(LineActions.ONGOING_ACTIVITY_TOTAL_DURATION_LOADED)).subscribe((state: LineActions.OngoingActivityTotalDurationLoaded): void => {
        const oneHourInSeconds: string = '3600';
        const totalDuration: number = state.payload.totalDuration;
        const isTaskTargetExceeded: boolean = this.decimalHelper.isGreaterOrEqThan(
          this.decimalHelper.divide(totalDuration.toString(), oneHourInSeconds),
          this.currentActivity.taskTarget
        );

        if (this.currentActivity.isCommentRequired || (this.currentActivity.isDurationExceedCommentRequired && isTaskTargetExceeded)) {
          this.isDurationExceedComment = this.currentActivity.isDurationExceedCommentRequired && isTaskTargetExceeded;

          this.showActivityCommentModal();
          return;
        }

        this.changeActivity(this.changeActivityRequestParams);
      })
    );

    if (this.isOpenedOnStationHome) {
      this.storeSubscriptions.push(
        this.store.select('stationActivityHistoryStore').subscribe((state: IStationActivityHistoryState) => {
          this.homeStation = {
            id: state.ongoingStationActivityHistory?.stationId || state.noActivityStation?.id,
            activityType: state.ongoingStationActivityHistory?.activity?.activityType,
            isWorkOrderSelected: Boolean(state.ongoingStationActivityHistory?.workOrderId),
          };

          this.currentActivity = {
            isDurationExceedCommentRequired: Boolean(state.ongoingStationActivityHistory?.task?.isDurationExceedCommentRequired),
            isCommentRequired: Boolean(state.ongoingStationActivityHistory?.task?.isCommentRequired),
            activityAndTaskName: `${state.ongoingStationActivityHistory?.activity?.name} - ${state.ongoingStationActivityHistory?.task?.title}`,
            activityName: state.ongoingStationActivityHistory?.activity?.name,
            comment: this.currentActivity.comment,
            taskTarget: state.ongoingStationActivityHistory?.task?.target
          };

          this.initialComment = state.ongoingStationActivityHistory?.description;

          this.activityIsChanging = state.isChangeStationActivityLoading;
        }),

        this.actionsSubject
          .pipe(ofType(StationActivityHistoryActions.ActionTypes.UPDATE_ONGOING_STATION_ACTIVITY_HISTORY_LOADED))
          .subscribe((payload: StationActivityHistoryActions.UpdateOngoingStationActivityHistoryLoaded) => {
            if (this.activityCommentEditModalRef) {
              this.activityCommentEditModalRef.dismiss();
            }

            if (this.selectWorkOrderRef) {
              this.selectWorkOrderRef.close();
            }

            if (this.pleaseSelectWorkOrderModalRef) {
              this.pleaseSelectWorkOrderModalRef.dismiss();
            }

            if (payload.response.success && this.isChangeActivityRequest) {
              this.store.dispatch(new AppActions.ShowLoader());
              this.changeActivity(this.changeActivityRequestParams);
            }

            this.isChangeActivityRequest = false;
          }),
      );
    }

    this.updateCurrentActivityCompletedActionSubscription = this.actionsSubject
      .pipe(ofType(HomeActions.HomeActionTypes.UpdateCurrentActivityCompleted))
      .subscribe(() => {
        if (this.selectWorkOrderRef) {
          this.selectWorkOrderRef.close();
        }

        if (this.addEditWorkOrderRef && this.workOrderCard.versionId === null) {
          this.addEditWorkOrderRef.dismiss();
        }

        if (this.pleaseSelectWorkOrderModalRef) {
          this.pleaseSelectWorkOrderModalRef.dismiss();
        }

        if (this.isRequestFromSelectWorkOrderModal) {
          if (this.selectedActivityId) {
            this.requestChangeActivityParameters = {
              taskId: this.selectedTaskId,
              activityId: this.selectedActivityId,
            };
          }

          setTimeout(() => {
            this.requestChangeActivity(this.requestChangeActivityParameters);
          }, 10);
          this.isRequestFromSelectWorkOrderModal = false;
        }

        this.store.dispatch(new AppActions.HideLoader());
      });
  }

  selectActivity(data: ButtonDataInterface, modalContent: TemplateRef<any>): void {
    let selectedWorkOrder: boolean = this.homeStation.isWorkOrderSelected;
    this.currentActivityType = this.homeStation.activityType;

    if (!this.isOpenedOnStationHome) {
      this.store
        .select('line')
        .pipe(take(1))
        .subscribe((state) => {
          this.workOrderTableId = state.wOTableId;

          if (state.workOrderId) {
            selectedWorkOrder = true;
          }
          this.currentActivityType = state.activityType;
        });
    }

    const actionAndActivityTypeControl: boolean =
      (this.action === 'change' && this.currentActivityType !== ActivityTypes.IDLE_TIME) ||
      (this.action === 'edit' && data.type !== ActivityTypes.IDLE_TIME && this.selectedWorkOrderControl);

    const taskWithoutWorkOrderControlValues: IActivitiesTasksInterface[] = data.tasks?.filter(
      (item: IActivitiesTasksInterface) => item.meta?.withoutWorkOrder === 1,
    );

    this.selectedActivityId = +data.id;
    this.selectedTaskId = data.tasks?.length ? Number(data.tasks[0]?.taskId) : null;
    this.selectedActivityName = data.name;
    this.selectedActivityNameTranslations = data.nameTranslations;
    this.selectedActivityType = data.type;
    this.activityPhase = data.activityPhase;
    this.taskTreeRootAddress = {
      activityId: this.selectedActivityId,
      lineId: this.lineId,
    };

    if (
      !taskWithoutWorkOrderControlValues?.length &&
      !selectedWorkOrder &&
      !_.isNil(this.currentActivityType) &&
      actionAndActivityTypeControl &&
      !this.homeStation.isWorkOrderSelected
    ) {
      this.pleaseSelectWorkOrderModalRef = this.ngbModal.open(
        this.pleaseSelectWorkOrderModalContent,
        {
          keyboard: false,
          backdrop: 'static',
          windowClass: 'scw-modal-sm',
        },
      );
      return;
    }

    if (this.action === 'change' && data.type === ActivityTypes.RUN_TIME) {
      const requestData: ChangeActivityRequestInterface = {
        activityId: +data.id,
      };
      this.requestChangeActivity(requestData);
    } else if (this.action === 'edit' && data.type === ActivityTypes.RUN_TIME) {
      const requestData = {
        activityId: +data.id,
        activityName: data.name,
        activityNameTranslations: data.nameTranslations,
        activityType: data.type,
        activityPhase: data.activityPhase,
      };
      this.editedActivityButtonEvent.emit(requestData);
    } else if (this.action === 'change' || this.action === 'edit') {
      this.openEquipmentTaskSelectionComponent(modalContent);
    }
  }

  openEquipmentTaskSelectionComponent(modalContent: TemplateRef<any>): void {
    this.startEquipmentTaskParameters = {
      activityId: this.selectedActivityId,
      lineId: this.lineId,
      siteId: this.siteId,
    };

    this.modalRef = this.ngbModal.open(modalContent, {
      windowClass: 'scw-modal-xl',
      keyboard: false,
      backdrop: 'static',
    });
  }

  changeActivity(requestData: ChangeActivityRequestInterface): void {
    this.activityIsChanging = true;
    this.store.dispatch(new AppActions.ShowLoader());

    if (this.isOpenedOnStationHome) {
      SemiManualCountButtonService.sendCountsSubject.next();

      this.store.dispatch(
        new StationActivityHistoryActions.StationActivityHistoryActivityChangeLoading(
          this.homeStation.id,
          requestData as IStationActivityHistoryActivityChangeRequest,
        ),
      );
      return;
    }

    this.store
      .select('line')
      .pipe(take(1))
      .subscribe((state: Line) => {
        this.currentActivity.activityName = state.activityName;
        this.currentWorkOrderId = _.get(state, 'wOTableId', null);
      });
    let currentLine$: number;
    this.store
      .select('user')
      .pipe(take(1))
      .subscribe((state) => {
        currentLine$ = state.lineId;
      });
    this.store
      .select('workOrderFinalize')
      .pipe(take(1))
      .subscribe((state: WorkOrderFinalizeStateInterface) => {
        if ((state.inProgress || this.activityIsFinalizer) && this.currentWorkOrderId !== null) {
          setTimeout(() => {
            this.store.dispatch(new WorkOrderFinalizeActions.SetSelectedActivityAndTask(requestData));

            this.woFinalQuantityService.get(currentLine$).then((resp: WorkOrderCloseResponseInterface) => {
              this.translatorService.translate(resp.data);
              this.workOrderFinalizeQuantityServiceData = resp;
              this.store.dispatch(new AppActions.HideLoader());
              this.activityIsChanging = false;
              const formattedData = BatchCloseComponent.setAdditionalFinalizeFormData(resp.data);
              this.workOrderFinalizeQuantityServiceData.data.oeeBarStyles = formattedData.oeeBarStyles;

              this.store
                .select('line')
                .pipe(take(1))
                .subscribe((state: Line) => {
                  formattedData.yield.actual = state.quantities.goodCount;
                  formattedData.scrap.actual = state.quantities.scrapCount;
                });

              this.workOrderCloseData = formattedData;
              this.changeActivityRequestData = requestData;

              if (this.activityIsFinalizer && !state.inProgress) {
                this.openWorkOrderFinalizerModal();
              } else if (state.inProgress) {
                this.continueFinalizer();
              }
            });
          }, 50);
        } else {
          this.store.dispatch(new HomeActions.ChangeActivity(requestData));
          this.showPhaseCommentModal(this.activityIsFinalizer);
        }
      });
  }

  public showPhaseCommentModal(finalizerSwitch: boolean): void {
    this.store.dispatch(new HomeActions.PhaseDurationsLoading(this.lineId));

    if (
      this.isAutomatedPhaseCommentsActive &&
      this.selectedPhase$ !== Number(this.activityPhase) &&
      (Number(this.activityPhase) !== 0 || finalizerSwitch || this.renderedFromFinalizerButtonComponent) &&
      this.selectedPhase$ !== null &&
      !this.helperService.isPreviousCommentIsEntered(this.selectedPhase$, this.phaseCommentsEnteredStatus) &&
      this.workOrderTableId &&
      this.workOrderTableId !== 0
    ) {
      this.store.dispatch(new HomeActions.PhaseCommentsLoading(this.workOrderTableId));
      this.isPhaseCommentModalOpen = false;
    }

    this.store.dispatch(new HomeActions.UpdatePreviousPhase(this.selectedPhase$));
  }

  editedEquipmentAndTask($event: EquipmentTaskOutputInterface, workOrderTableId: number | null): void {
    const actionAndActivityTypeControl: boolean =
      (this.action === 'change' && this.currentActivityType !== ActivityTypes.IDLE_TIME) ||
      (this.action === 'edit' && this.selectedActivityType !== ActivityTypes.IDLE_TIME);

    this.modalRef.dismiss();
    this.requestChangeActivityParameters = { taskId: $event.taskId, activityId: this.selectedActivityId };

    if (
      actionAndActivityTypeControl &&
      !this.isCalledFromActivityHistory &&
      (_.isNil($event.meta) || $event.meta?.withoutWorkOrder === 0) &&
      !_.isNil(this.currentActivityType) &&
      workOrderTableId === null &&
      !this.homeStation.isWorkOrderSelected
    ) {
      this.pleaseSelectWorkOrderModalRef = this.ngbModal.open(
        this.pleaseSelectWorkOrderModalContent,
        {
          keyboard: false,
          backdrop: 'static',
          windowClass: 'scw-modal-sm',
        },
      );
      return;
    }

    if (this.action === 'change') {
      this.requestChangeActivity(this.requestChangeActivityParameters);
    } else {
      this.editedActivityButtonEvent.emit({
        activityId: this.selectedActivityId,
        activityName: this.selectedActivityName,
        activityNameTranslations: this.selectedActivityNameTranslations,
        activityType: this.selectedActivityType,
        equipmentId: $event.equipmentId ? $event.equipmentId.toString() : null,
        equipmentName: $event.equipmentName,
        taskId: $event.taskId,
        taskName: $event.taskName,
        taskNameTranslations: $event.taskNameTranslations,
        activityPhase: $event.phaseId || this.activityPhase,
        isCommentRequired: Boolean($event.isCommentRequired),
        withoutWorkOrder: Boolean($event.meta.withoutWorkOrder),
      });
    }
  }

  setEquipmentAndTaskTitle(title: string): void {
    this.equipmentAndTaskTitle = title;
  }

  public onPhaseCommentModalClosedByUser(): void {
    let phaseCommentsEnteredStatus: PhaseCommentsEnteredStatusInterface;

    if (this.phaseCommentsEnteredStatus === null) {
      phaseCommentsEnteredStatus = {
        isRunCommentEntered: null,
        isPostRunCommentEntered: null,
        isPreRunCommentEntered: null,
      };
    } else {
      phaseCommentsEnteredStatus = _.cloneDeep(this.phaseCommentsEnteredStatus);
    }

    switch (this.phaseType) {
      case 'preRun':
        phaseCommentsEnteredStatus.isPreRunCommentEntered = false;
        break;
      case 'run':
        phaseCommentsEnteredStatus.isRunCommentEntered = false;
        break;
      case 'postRun':
        phaseCommentsEnteredStatus.isPostRunCommentEntered = false;
        break;
    }

    this.store.dispatch(
      new WorkOrderScheduleActions.EditWorkOrder(
        {
          phaseCommentsEnteredStatus,
        },
        this.workOrderTableId,
      ),
    );
    this.workOrderPhaseCommentModalRef.dismiss();
  }

  public continueFinalizer(): void {
    if (
      this.isAutomatedPhaseCommentsActive &&
      this.selectedPhase$ !== Number(this.activityPhase) &&
      (Number(this.activityPhase) !== 0 || this.activityIsFinalizer || this.renderedFromFinalizerButtonComponent) &&
      this.selectedPhase$ !== null &&
      !this.helperService.isPreviousCommentIsEntered(this.selectedPhase$, this.phaseCommentsEnteredStatus)
    ) {
      this.showPhaseCommentModal(true);
    } else {
      this.openBatchClose.emit(this.workOrderCloseData);
      this.workOrderFinalizeQuantityServiceData = null;
    }

    if (this.workOrderFinalizerRef) {
      this.workOrderFinalizerRef.close();
    }
  }

  public changeActivityWithoutFinalizing(): void {
    this.workOrderFinalizeQuantityServiceData = null;
    this.allowChangeActivityAfterFinalizerActivite = false;
    this.store.dispatch(new WorkOrderFinalizeActions.SetWorkOrderFinalizeInProgress(false));
    this.store.dispatch(new HomeActions.ChangeActivity(this.changeActivityRequestData));
    this.showPhaseCommentModal(false);
    this.workOrderFinalizerRef.close();
  }

  public openWorkOrderFinalizerModal(): void {
    this.workOrderFinalizerRef = this.ngbModal.open(this.workOrderFinalizerContent, {
      keyboard: false,
      backdrop: 'static',
      windowClass: 'scw-modal-sm',
    });
  }

  public backToEquipmentSelection() {
    this.backToSelectEquipment.next(true);
  }

  private requestChangeActivity(requestParams: ChangeActivityRequestInterface): void {
    this.changeActivityRequestParams = requestParams;
    this.isDurationExceedComment = false;

    if (this.currentActivity.isDurationExceedCommentRequired) {
      this.store.dispatch(new LineActions.OngoingActivityTotalDurationLoading(this.lineId, this.homeStation?.id));

      return;
    }

    if (this.currentActivity.isCommentRequired) {
      this.showActivityCommentModal();
      return;
    }

    this.changeActivity(requestParams);
  }

  public showActivityCommentModal(): void {
    this.currentActivity.comment = this.initialComment;

    this.activityCommentEditModalRef = this.ngbModal.open(CommentFeedModalComponent, {
      windowClass: 'scw-modal-sm',
      keyboard: false,
      backdrop: 'static',
    });
    this.activityCommentEditModalRef.componentInstance.activityName = this.currentActivity.activityAndTaskName;
    this.activityCommentEditModalRef.componentInstance.defaultTagTypes = this.isDurationExceedComment
      ? [TagType.PhaseTargetExceeded]
      : undefined;
    this.activityCommentEditModalRef.componentInstance.mode = 'requiredComment';
    this.activityCommentEditModalRef.componentInstance.objectIds = [this.lineId];
    this.activityCommentEditModalRef.componentInstance.objectType = 'lines';
    this.activityCommentEditModalRef.result.then(() => this.submitActivityComment(true));
  }

  public submitActivityComment(isValid: boolean): void {
    if (!isValid) {
      return;
    }

    this.isChangeActivityRequest = true;
    this.store.dispatch(new AppActions.ShowLoader());

    if (this.isOpenedOnStationHome) {
      this.store.dispatch(
        new StationActivityHistoryActions.UpdateOngoingStationActivityHistoryLoading(this.homeStation.id, {
          description: this.currentActivity.comment,
        }),
      );
      return;
    }

    this.store.dispatch(
      new HomeActions.UpdateCurrentActivity(
        { selectedDescription: this.currentActivity.comment },
        RequestTypeEnum.ACTIVITY_COMMENT,
      ),
    );
  }

  ngOnDestroy(): void {
    this.storeSubscriptions.forEach((item) => {
      item.unsubscribe();
    });
  }

  public openWorkOrderModal(): void {
    this.isRequestFromSelectWorkOrderModal = true;
    this.workOrderHeader = {
      id: null,
      productId: null,
      woNumber: null,
      quantityOrdered: null,
      productDescription: null,
      productName: null,
    };

    if (this.workOrderCard.workOrderId !== null) {
      this.workOrderHeader = {
        id: +this.workOrderCard.wOTableId,
        productId: this.workOrderCard.productId,
        woNumber: this.workOrderCard.workOrderId,
        quantityOrdered: this.workOrderCard.workOrderQuantityOrdered,
        productDescription: this.workOrderCard.productDescription,
        productName: this.workOrderCard.productId,
      };
    }

    this.selectWorkOrderRef = this.ngbModal.open(this.workOrderModalContent, {
      windowClass: 'scw-modal-xl',
      keyboard: false,
      backdrop: 'static',
    });

    this.selectWorkOrderRef.result.then(
      null,
      () => {
        this.isRequestFromSelectWorkOrderModal = false;
      }
    );

    if (this.doYouWantToSelectWorkOrderModalRef) {
      this.doYouWantToSelectWorkOrderModalRef.close();
    }
  }

  public workOrderSave(workOrderId: number): void {
    setTimeout(() => {
      if (workOrderId) {
        this.store.dispatch(new AppActions.ShowLoader());
        const action: Action = this.isOpenedOnStationHome
          ? new StationActivityHistoryActions.UpdateOngoingStationActivityHistoryLoading(this.homeStation.id, {
            workOrderId,
          })
          : new HomeActions.UpdateCurrentActivity({
            workOrderId,
          });
        this.store.dispatch(action);
      }
    }, 10);
  }

  public closeSelectWorkOrderModal(): void {
    this.selectWorkOrderRef.dismiss();
  }

  public updateCurrentWoIntoNew(parameters: WorkOrderLineChangeInterface): void {
    setTimeout(() => {
      if (parameters.resp.siteId) {
        this.addEditWorkOrderRef = parameters.workOrderModal;
        this.store.dispatch(new AppActions.ShowLoader());
        this.store.dispatch(
          new HomeActions.UpdateCurrentActivity({
            workOrderId: parameters.resp.id,
          }),
        );
      }
    }, 5);
  }
}
