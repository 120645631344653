<div
  id="pcoded"
  class="pcoded"
  [ngClass]="animateSidebar"
  [attr.nav-type]="navType"
  [attr.theme-layout]="themeLayout"
  [attr.horizontal-placement]="verticalPlacement"
  [attr.horizontal-layout]="verticalLayout"
  [attr.pcoded-device-type]="pcodedDeviceType"
  [attr.vertical-nav-type]="verticalNavType"
  [attr.vertical-effect]="verticalEffect"
  [attr.hnavigation-view]="vnavigationView"
  [attr.fream-type]="freamType"
  [attr.sidebar-img]="sidebarImg"
  [attr.sidebar-img-type]="sidebarImgType"
  [attr.layout-type]="layoutType"
  (window:resize)="onResize($event)"
>
  <!--<div class="pcoded-overlay-box"></div>-->
  <div class="pcoded-container navbar-wrapper">
    <nav
      class="navbar header-navbar pcoded-header"
      [attr.header-theme]="headerTheme"
      [attr.pcoded-header-position]="pcodedHeaderPosition"
    >
      <div class="navbar-wrapper">
        <div class="navbar-logo">
          <div [hidden]="hideNavMenu">
            <a
              class="mobile-menu ripple light nav-list"
              id="mobile-collapse"
              href="javascript:"
              (click)="toggleOpened($event)"
              data-automation-id="mobile-collapse"
            >
              <i class="feather" [ngClass]="toggleIcon"></i>
            </a>
            <a
              class="ripple light"
              [hidden]="pcodedDeviceType !== deviceType.desktop"
              id="desktop-collapse"
              href="javascript:"
              (click)="toggleSidebarInDesktop()"
              data-automation-id="desktop-collapse"
            >
              <i class="feather" [ngClass]="sidebarToggleIcon" style="font-size: 25px"></i>
            </a>
          </div>
          <a *ngIf="clientLogo" class="logo light">
            <img class="client-logo" src="{{ clientLogo }}" alt="OEE Tracker" />
          </a>
          <a [routerLink]="['/home/dashboard/default/']" class="logo ripple light cursor-pointer">
            <img class="oee-logo" src="assets/images/df-logo.svg" alt="Digital Factory" />
          </a>
          <a class="text-white d-inline-block m-0 light nav-list padding-right-twenty-px">
            {{ clock | momentDate: timeFormat$ }}
          </a>
          <a
            *ngIf="isLineSelectionVisible && lineVisibleMenuItems['line-observation']"
            (click)="clickDigitalFormsButton()"
            class="ripple light nav-list mobile-nowrap-120 padding-right-twenty-px padding-top-form"
          >
            <em class="fas fa-file"></em>
            <span> {{ 'pageTitles.observation' | translate }}</span>
          </a>
          <a class="fas fa-sync-alt refresh cursor-pointer padding-right-twenty-px" (click)="reloadCurrentPage()"></a>
          <a href="javascript:" class="mobile-options ripple light" (click)="toggleHeaderNavRight()">
            <i class="feather icon-more-horizontal"></i>
          </a>
          <a
            [hidden]="pcodedDeviceType !== deviceType.desktop"
            class="header-notification ripple light"
            appToggleFullScreen
          >
            <a href="javascript:">
              <em class="fas fa-expand-arrows-alt full-screen padding-right-twenty-px"></em>
            </a>
          </a>

          <a
            *ngIf="lineVisibleMenuItems['home'] && router.url !== '/select-site-and-line'"
            [routerLink]="['/home/dashboard/default/']"
            class="ripple light nav-list mobile-nowrap mobile-nowrap-90 padding-left-home-button"
            data-automation-id="home-btn"
            [ngClass]="pcodedDeviceType === deviceType.desktop ? 'desktop-sized-home-padding' : 'tablet-sized-home-padding'"
          >
            <em class="fa fa-home" aria-hidden="true" [ngClass]="router.url !== '/home' ? '' : 'padding-right-twenty-px'"></em>
          </a>
        </div>
        <div class="navbar-container container-fluid display-inline">
          <ul class="nav-left">
            <li class="ripple light">
              <div class="sidebar_toggle">
                <a href="javascript:"><i class="icon-menu"></i></a>
              </div>
            </li>
          </ul>
          <ul [@mobileHeaderNavRight]="navRight" [ngClass]="navRight" class="nav-right">
            <li class="ripple light top-menu-item f-w-400">
              <a
                *ngIf="isActionTrackerActive$"
                href="javascript:"
                class="mobile-options ripple light padding-right-twenty-px"
                (click)="onCreateQuickItemClicked()"
              >
                <i class="fas fa-file-medical fa-icon-size"></i>
                <span *ngIf="!this.helperService.isTouchDevice">{{ 'actionTracker.addIssueAction' | translate }}</span>
              </a>
            </li>
            <ng-template [ngIf]="isLineSelectionVisible || isLineStation">
              <li [ngClass]="[changeSiteAndLine ? '' : 'cursor-default']" class="ripple light top-menu-item f-w-400">
                <a class="nowrap desktop-max-width-500 mobile-nowrap-100 padding-right-twenty-px" (click)="siteLineSelectionModal(site_line_selection)"
                   data-automation-id="line-name">
                  <i class="fas fa-industry fa-icon-size"></i>
                  <span>{{ lineName }}</span>
                </a>
              </li>
              <ng-template [ngIf]="isOeeTrackerActive">
                <li
                  (clickOutside)="notificationOutsideClick(navBarElements.shiftReview)"
                  class="header-notification ripple light"
                  (click)="toggleShiftReview()"
                  *ngIf="!isLineStation"
                >
                  <a
                  *ngIf="lineVisibleMenuItems['activity-review'] || lineVisibleMenuItems['production-review']"
                  class="mobile-nowrap top-menu-item f-w-400 mobile-nowrap-105 station-padding" href="javascript:" data-automation-id="shift-btn">
                    <em class="fas fa-clock fa-icon-size" [ngClass]="this.helperService.isTouchDevice ? 'clock-padding': ''"></em>
                      <span [hidden]="this.helperService.isTouchDevice">{{ 'general.shiftReview' | translate }}</span>
                    <i class="sub-icon feather icon-chevron-down sub-icon-padding-left"></i>
                  </a>
                  <ul
                    [@notificationBottom]="shiftReviewDropdown"
                    class="router-dropdown show-notification shift-review-dropdown"
                    [ngClass]="shiftReviewDropdownClass"
                  >
                    <li class="ripple" *ngIf="lineVisibleMenuItems['activity-review']">
                      <a routerLink="/activity-review" data-automation-id="activity-review-btn">
                        <i class="fas fa-chart-line"></i> {{ 'general.activityReview' | translate }}
                      </a>
                    </li>
                    <li class="ripple" *ngIf="lineVisibleMenuItems['production-review']">
                      <a routerLink="/production-review" data-automation-id="production-review-btn">
                        <i class="fas fa-boxes"></i> {{ 'general.productionReview' | translate }}
                      </a>
                    </li>
                  </ul>
                </li>
              </ng-template>
              <li
                (clickOutside)="notificationOutsideClick(navBarElements.lineStation)"
                class="header-notification light top-menu-item"
                [class.disabled-in-progress]="isStationInformationLoading$ && isHomeInformationLoading$"
                [class.cursor-default]="isStationInformationLoading$ && isHomeInformationLoading$"
                [ngClass]="rippleClass"
                *ngIf="stations.length"
              >
                <a class="top-menu-item f-w-400" href="javascript:" (click)="toggleLineStation()">
                  <div class="mobile-nowrap mobile-nowrap-90 float-left">
                    <i class="fa fa-th-large icon-font-size"></i>
                    <span [hidden]="this.helperService.isTouchDevice">{{ 'settings.lines.tabTitle.stations' | translate }}</span>
                  </div>
                  <i class="sub-icon feather icon-chevron-down sub-icon-padding-left"></i>
                </a>
                <ul
                  [@notificationBottom]="lineStationDropdown"
                  class="router-dropdown show-notification stations-dropdown"
                  [ngClass]="lineStationDropdownClass"
                  *ngIf="lineVisibleMenuItems['home/station/:id']"
                >
                  <li *ngFor="let station of stations" (click)="onStationClick(station.id)">
                    <line-station-dropdown-item [data]="station"></line-station-dropdown-item>
                  </li>
                </ul>
              </li>
            </ng-template>
            <ng-template [ngIf]="(isLineSelectionVisible || isLineStation) && isOeeTrackerActive">
              <li class="ripple light top-menu-item f-w-400" *ngIf="lineVisibleMenuItems['activity-history']">
                <a class="mobile-nowrap padding-right-twenty-px"
                   routerLink="/activity-history" data-automation-id="activity-history-btn">
                  <i class="fas fa-history icon-font-size"></i>
                  <span *ngIf="missingDataCount > 0" class="badge bg-c-orange no-animation"
                  [ngClass]="missingDataCount > 10 ? 'activity-history-notification badge-two-digit' : 'badge-one-digit'">{{
                    missingDataCount > 99 ? '99+' : missingDataCount
                    }}</span>
                </a>
              </li>
            </ng-template>
             <ng-template [ngIf]="isAppNotificationActive$">
              <app-scw-notification-permission-modal></app-scw-notification-permission-modal>

              <li
                (clickOutside)="notificationOutsideClick(navBarElements.notificationFeed)"
                class="header-notification"
              >
                <app-notification-feed-modal
                  [animationState]="notificationFeedDropdown"
                  [animationClass]="notificationFeedDropdownClass"
                  (toggleListItem)="toggleNotificationFeed()"
                ></app-notification-feed-modal>
              </li>
            </ng-template>
            <ng-template [ngIf]="isLineSelectionVisible">
              <li
                (clickOutside)="notificationOutsideClick(navBarElements.alertControl)"
                class="header-notification light top-menu-item"
                *ngIf="!isLineStation"
              >
                <a
                  class="light ripple top-menu-item f-w-400 padding-right-sensor"
                  (click)="toggleAlertControl()"
                  href="javascript:"
                >
                  <span class="sensor-or-opc-dropdown" [ngClass]="sensorAndOPCButtonStyle">
                    <span>
                      <em class="fas fa-broadcast-tower icon-font-size"></em>
                      <em class="sub-icon feather icon-chevron-down sub-icon-padding-left"></em>
                    </span>
                  </span>
                </a>
                <ul
                  [@notificationBottom]="alertControlDropdown"
                  class="router-dropdown show-notification alert-control"
                  [ngClass]="alertControlDropdownClass"
                >
                  <li>
                    <app-opc-status-indicator [isHaveOPCFailure]="isHaveOPCFailure"></app-opc-status-indicator>
                  </li>
                  <li>
                    <alert-pause></alert-pause>
                  </li>
                  <li
                    *ngFor="let sensorStatus of currentSensorStatuses"
                    [class.d-none]="!sensorStatus.sensorDetails?.length"
                  >
                    <sensor-status-indicator [data]="sensorStatus"></sensor-status-indicator>
                  </li>
                </ul>
              </li>
              <li
                (clickOutside)="notificationOutsideClick(navBarElements.live)"
                class="call-support header-notification"
                (click)="toggleLiveNotification()"
              >
                <a
                  class="light ripple top-menu-item f-w-400 padding-right-ten-px"
                  (click)="toggleCallSupport()"
                  data-automation-id="contact-dropdown-btn"
                  href="javascript:"
                >
                  <a class="desktop-sized-phone-home-padding">
                    <i class="fa fa-phone fa-rotate-90 icon-font-size-phone"></i>
                  </a>
                  <i class="sub-icon feather icon-chevron-down sub-icon-phone-padding"></i>
                </a>
                <ul
                  [@notificationBottom]="liveNotification"
                  class="router-dropdown show-notification"
                  [ngClass]="liveNotificationClass"
                >
                  <mat-progress-bar
                    mode="indeterminate"
                    color="primary"
                    [hidden]="!customMailGroupsLoading"
                  ></mat-progress-bar>
                  <ng-container *ngFor="let customMailGroup of customMailGroups">
                    <li
                      class="ripple"
                      (click)="openCallSupportModal(call_support_modal, 'CustomMailGroup', customMailGroup)"
                    >
                      <a> <i class="fa fa-phone" aria-hidden="true"></i> {{ customMailGroup.title }} </a>
                    </li>
                  </ng-container>
                  <li class="ripple" (click)="openCallSupportModal(call_support_modal, 'ContactIT')">
                    <a> <i class="fa fa-phone" aria-hidden="true"></i> {{ 'general.contactIt' | translate }} </a>
                  </li>
                  <li class="ripple" (click)="openCallSupportModal(call_support_modal, 'ContactSupervisor')">
                    <a>
                      <i class="fa fa-phone" aria-hidden="true"></i> {{ 'general.contactSupervisor' | translate }}
                    </a>
                  </li>
                  <li class="ripple" (click)="openCallSupportModal(call_support_modal, 'ContactMaintenance')">
                    <a>
                      <i class="fa fa-phone" aria-hidden="true"></i> {{ 'general.contactMaintenance' | translate }}
                    </a>
                  </li>
                  <li class="ripple" (click)="openCallSupportModal(call_support_modal, 'ContactQuality')">
                    <a> <i class="fa fa-phone" aria-hidden="true"></i> {{ 'general.contactQuality' | translate }} </a>
                  </li>
                  <li class="ripple" (click)="openCallSupportModal(call_support_modal, 'ContactSCW')">
                    <a> <i class="fa fa-phone" aria-hidden="true"></i> {{ 'general.contactSCW' | translate }} </a>
                  </li>
                  <li class="ripple" (click)="openCallSupportModal(call_support_modal, 'ContactWarehouse')">
                    <a> <i class="fa fa-phone" aria-hidden="true"></i> {{ 'general.contactWarehouse' | translate }} </a>
                  </li>
                  <li class="ripple" (click)="openCallSupportModal(call_support_modal, 'ContactUser')">
                    <a> <i class="fa fa-phone" aria-hidden="true"></i> {{ 'general.contactUser' | translate }} </a>
                  </li>
                </ul>
              </li>
            </ng-template>
            <!--            <li-->
            <!--              (clickOutside)="notificationOutsideClick('notificationControl')"-->
            <!--              class="header-notification light top-menu-item"-->
            <!--              [ngClass]="rippleClass"-->
            <!--            >-->
            <!--              <a class="top-menu-item f-w-400" href="javascript:" (click)="toggleNotificationControl()">-->
            <!--                <em class="fas fa-bell"></em>-->
            <!--                <span *ngIf="unSeenNotifications$ > 0" class="badge bg-c-red no-animation notification-badge">{{-->
            <!--                  unSeenNotifications$-->
            <!--                }}</span>-->
            <!--              </a>-->
            <!--              <ul-->
            <!--                [@notificationBottom]="notificationDropdown"-->
            <!--                class="router-dropdown show-notification alert-control"-->
            <!--                [ngClass]="notificationDropdownClass"-->
            <!--              >-->
            <!--                <li>-->
            <!--                  <app-notification [isOpened]="this.notificationDropdown === 'an-animate'"></app-notification>-->
            <!--                </li>-->
            <!--              </ul>-->
            <!--            </li>-->
            <li
              (clickOutside)="notificationOutsideClick(navBarElements.language)"
              class="header-notification ripple light"
              (click)="toggleLanguage()"
            >
              <a class="mobile-nowrap top-menu-item f-w-400 mobile-nowrap-105 station-padding d-flex align-items-center" href="javascript:"
                 data-automation-id="shift-btn">
                <span class="lang-truncate">{{ userLanguage }}</span>
                <i class="sub-icon feather icon-chevron-down sub-icon-padding-left"></i>
              </a>
              <ul
                [@notificationBottom]="languageDropdown"
                class="router-dropdown show-notification stations-dropdown"
                [ngClass]="languageDropdownClass"
              >
                <ng-container *ngFor="let language of languages">
                  <li class="ripple" (click)="setLanguage(language)">
                    <a>{{ language.name }} - {{ language.id }}</a>
                  </li>
                </ng-container>
              </ul>
            </li>
            <li
              *ngIf="userName"
              (clickOutside)="notificationOutsideClick(navBarElements.profile)"
              class="header-notification"
              (click)="toggleProfileNotification()"
            >
              <a class="light ripple top-menu-item f-w-400 padding-right-ten-px" href="javascript:">
                <i class="fas fa-user fa-icon-size"></i>
                <i class="sub-icon feather icon-chevron-down sub-icon-padding-left sub-icon-profile-padding"></i>
              </a>
              <ul
                [@notificationBottom]="profileNotification"
                class="router-dropdown show-notification profile-notification cursor-default"
                [ngClass]="profileNotificationClass"
              >
                <li class="ripple">
                  <a (click)="goLink({ external: false, state: '/settings/account-settings' })">
                    <em class="fas fa-cog"></em> {{ 'general.account' | translate }}
                  </a>
                </li>
                <li class="ripple">
                  <a (click)="goLink({ external: false, state: '/knowledge-base' })">
                    <em class="fas fa-question-circle"></em> {{ 'general.knowledge_base' | translate }}
                  </a>
                </li>
                <li class="ripple" *ngIf="isSubmitATicketButtonEnabled">
                  <a [href]="submitATicketUrl" target="_blank">
                    <em class="fa fa-solid fa-ticket"></em> {{ 'general.submitATicket' | translate }}
                  </a>
                </li>
                <li *ngIf="lineName && router.url === '/home'" class="ripple light f-w-400">
                  <a (click)="showSiteTour()">
                    <em class="fas fa-map-signs"></em> {{ 'general.homeTour' | translate }}
                  </a>
                </li>
                <li class="ripple">
                  <a (click)="userLogout()">
                    <em class="fas fa-sign-out-alt"></em> {{ 'general.logout' | translate }}
                  </a>
                </li>
                <li class="text-center f-10 d-flex justify-content-center flex-column version">
                  {{ 'general.version' | translate }} : {{ version }}
                </li>
              </ul>
            </li>

            <ng-template [ngIf]="isAccessLogbookActive">
              <li>
                <a href="{{ logbookUrl }}" class="logo ripple light cursor-pointer padding-right-ten-px">
                  <img class="logbook-logo" src="assets/images/logbook_logo.png" alt="Digital Factory" />
                </a>
              </li>
            </ng-template>
          </ul>
        </div>
      </div>
    </nav>
    <div class="pcoded-main-container" [style.margin-top]="headerFixedMargin">
      <div *ngIf="isHaveOPCFailure && isHomeScreen" class="opc-failure-warning-border"></div>
      <div class="pcoded-wrapper">
        <nav
          id="main_navbar"
          class="pcoded-navbar"
          [attr.navbar-theme]="navBarTheme"
          [attr.active-item-theme]="activeItemTheme"
          sub-item-theme="theme2"
          active-item-style="style0"
          [attr.pcoded-header-position]="pcodedHeaderPosition"
          [attr.pcoded-navbar-position]="pcodedSidebarPosition"
          [style.top]="headerFixedTop"
          [style.height]="sidebarFixedNavHeight"
          (mouseleave)="hoverOutsideSidebar()"
          [style.display]="sidebarDisplay"
        >
          <div class="pcoded-inner-navbar main-menu" appAccordion>
            <app-main-nav-bar [newMenuItems]="newMenuItems" [itemBorder]="itemBorder"></app-main-nav-bar>
          </div>
        </nav>
        <div class="pcoded-content">
          <page-header [pageHeader]="pageHeader"></page-header>
          <div [class.pcoded-inner-content]="!pageHeader?.isTabPage">
            <!-- Main-body start -->
            <div class="main-body">
              <div class="page-wrapper">
                <router-outlet>
                  <title></title>
                  <app-spinner></app-spinner>
                </router-outlet>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #site_line_selection let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ siteLineSelectionTitle }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body">
    <app-site-line-selection
      [actionType]="'edit'"
      (selectionMode)="setSiteLineSelectionMode($event)"
      #appSiteLine
    ></app-site-line-selection>
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">{{ 'general.cancel' | translate }}</scw-mat-button>
      <scw-mat-button (click)="appSiteLine.selectDestination()">
        {{
          siteLineSelectionMode === ESiteLineSelectionMode.FLOOR_PLANS
            ? ('siteLineSelection.goToLayoutPlan' | translate)
            : ('siteLineSelection.start' | translate)
        }}
      </scw-mat-button>
    </div>
  </div>
</ng-template>

<ng-template #call_support_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ 'general.callSupport' | translate }}
    </h3>
    <button type="button" class="close" onfocus="blur()" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <scw-mat-form (scwMatFormSubmit)="sendCallSupport($event)">
    <div class="modal-body">
      <div class="row">
        <div class="col-12">
          <scw-mat-select
            label="{{ 'contactSupport.from' | translate }}*"
            [rules]="[{ required: true }]"
            [data]="fromDropdownOptions"
            [enableSearchFilter]="fromDropdownSettings.enableSearchFilter"
            [text]="fromDropdownSettings.text"
            [labelKey]="fromDropdownSettings.labelKey"
            [noDataLabel]="fromDropdownSettings.noDataLabel"
            [searchMinLength]="3"
            [searchMaxLength]="InputLimit.CHECK_IN_CHECK_OUT_USERNAME_MAX_LENGTH"
            searchBehavior="delayed"
            [searchPreventSameSearchString]="false"
            [searchPlaceholderText]="'cico.modules.laborTracker.searchDropdown.searchInput.information' | translate"
            [enableSearchResultList]="true"
            [(inputModel)]="callSupportForm.from"
            (onOpen)="onFromDropDownOpened()"
            (onDeSelectAll)="emptyFromSelection()"
            (onSearch)="fillUserOptionsIntoDropdown($event, 'from')"
            (onSearchModelChange)="onSearchModelChange($event, 'from')"
          >
            <ng-template let-item>
              <div>
                <label class="line-brake">{{ item.shortName }}</label> -
                <label>{{ item.name }}</label>
              </div>
            </ng-template>
          </scw-mat-select>
        </div>
        <ng-template [ngIf]="callSupportForm.contactType === 'ContactUser'" [ngIfElse]="otherContactTypes">
          <div class="col-12">
            <scw-mat-select
              label="{{ 'contactSupport.to' | translate }}*"
              [rules]="[{ required: true }]"
              [data]="userDropdownOptions"
              [enableSearchFilter]="userDropdownSettings.enableSearchFilter"
              [text]="userDropdownSettings.text"
              [labelKey]="userDropdownSettings.labelKey"
              [noDataLabel]="userDropdownSettings.noDataLabel"
              [searchMinLength]="3"
              [searchMaxLength]="InputLimit.CHECK_IN_CHECK_OUT_USERNAME_MAX_LENGTH"
              searchBehavior="delayed"
              [searchPreventSameSearchString]="false"
              [searchPlaceholderText]="'cico.modules.laborTracker.searchDropdown.searchInput.information' | translate"
              [enableSearchResultList]="true"
              [(inputModel)]="callSupportForm.user"
              (onOpen)="onUserDropdownOpened()"
              (onDeSelectAll)="emptyUserSelection()"
              (onSearch)="fillUserOptionsIntoDropdown($event, 'user')"
              (onSearchModelChange)="onSearchModelChange($event, 'user')"
              data-automation-id="contact-form-to-field"
            >
              <ng-template let-item>
                <div>
                  <label class="line-brake">{{ item.shortName }}</label> -
                  <label>{{ item.name }}</label>
                </div>
              </ng-template>
            </scw-mat-select>
          </div>
        </ng-template>
        <ng-template #otherContactTypes>
          <div class="col-12">
            <div class="form-group form-default form-static-label mb-1">
              <label class="float-label" style="font-size: 11px; margin-left: 4px">{{
                'contactSupport.to' | translate
              }}</label>
              <div class="form-control-static m-b-15" style="margin-left: 4px">{{ callSupportForm.to }}</div>
            </div>
          </div>
        </ng-template>
        <div class="col-12">
          <scw-mat-input
            label="{{ 'contactSupport.subject' | translate }}*"
            [rules]="[{ required: true }, { maxLength: InputLimit.CALL_SUPPORT_FROM_MAX_LENGTH }]"
            [(inputModel)]="callSupportForm.textSubject"
          ></scw-mat-input>
        </div>
        <div class="col-12">
          <scw-mat-textarea
            label="{{ 'general.message' | translate }}*"
            [block]="true"
            [rules]="[{ required: true }, { maxLength: InputLimit.CALL_SUPPORT_MESSAGE_MAX_LENGTH }]"
            [(inputModel)]="callSupportForm.textBody"
            (keyup)="onMessageTextareaKeyUp()"
          ></scw-mat-textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-2">
          <scw-mat-checkbox [(inputModel)]="callSupportForm.mail">
            {{ 'general.email' | translate }}
          </scw-mat-checkbox>
        </div>
        <div class="col-2">
          <scw-mat-checkbox [(inputModel)]="callSupportForm.sms">
            {{ 'general.sms' | translate }}
          </scw-mat-checkbox>
        </div>
        <div class="col-8">
          <p class="text-right">
            {{ callSupportForm.character }} {{ 'general.remaining' | translate }} / {{ callSupportForm.total }}
          </p>
        </div>
        <div class="col-12">
          <div *ngIf="!callSupportForm.mail && !callSupportForm.sms" class="messages text-danger">
            {{ 'general.sendTypeError' | translate }}
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="buttons-right">
        <scw-mat-button type="standard" (onClick)="modal.dismiss()">
          {{ 'general.cancel' | translate }}
        </scw-mat-button>
        <scw-mat-button [isSubmitButton]="true" data-automation-id="contact-form-send-btn">
          {{ 'contactSupport.sendRequest' | translate }}
        </scw-mat-button>
      </div>
    </div>
  </scw-mat-form>
</ng-template>

<ng-container *ngIf="showGmpDisclaimerModal">
  <div class="gmpDisclaimerModal">
    <div [innerHTML]="'general.notForGmpUse' | translate"></div>
  </div>
</ng-container>

<ng-template #payment_status_modal let-modal>
  <div class="modal-header scw-modal-header">
    <h3 class="modal-title scw-header">
      {{ paymentStatusModalTitle }}
    </h3>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">
        <em class="fas fa-times"></em>
      </span>
    </button>
  </div>
  <div class="modal-body d-flex flex-column justify-content-center payment-status-message">
    {{ paymentStatusModalMessage }}
  </div>
  <div class="modal-footer">
    <div class="buttons-right">
      <scw-mat-button type="standard" (onClick)="modal.dismiss()">{{ 'general.ok' | translate }}</scw-mat-button>
    </div>
  </div>
</ng-template>

