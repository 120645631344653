import { IAdvancedFilterOutput } from '../../shared/component/filter/advanced-filter/advanced-filter.model';
import { ITableHeader } from '../../../constants.model';
import { DateRangeTypes } from '../../shared/component/filter/filterable-objects.class';
import { Moment } from 'moment';

export interface WorkOrdersTableHeaderInterface {
  value: string;
  name: string;
  icon?: string;
  disabled?: boolean;
  selected?: boolean;
  sortable?: boolean;
}

export interface WorkOrdersFilterOutputInterface {
  siteId: number[];
  actualLine?: number[];
  productId: number[];
  dateType: string[];
  dateRange: {
    startDate: Moment | string;
    endDate: Moment | string;
  };
  workOrderStatus: string[];
  workOrderProperty: string[];
  searchBy: string;
  scheduledLine?: number[];
}

export interface CustomWorkOrdersFilterOutputInterface {
  siteId: IWorkOrderPageSiteFilter[];
  lineIds: number[];
  lastLineIds: number[];
  productId: number[];
  filterDateType: string[];
  filterDateRange?: {
    startDate: Moment | string;
    endDate: Moment | string;
  };
  filterStartDate: Moment | string;
  filterEndDate: Moment | string;
  completeStatus: string[];
  searchType: string[];
  search: string;
  advancedFilter?: IAdvancedFilterOutput;
  jobIds: number[];
}

export interface WorkOrderScheduleSaveOneDtoInterface {
  id?: number;
  siteId: number;
  woNumber: string;
  productId: number;
  woDueDate?: string | null;
  productVersion?: number;
  quantityOrdered: string;
  scheduledPreDuration?: string;
  scheduledPostDuration?: string;
  scheduledRunDuration?: string;
  productionStage?: number;
  jobId?: number;
  scheduledRunDate?: string;
  scheduledEndDate?: string;
  scheduledLine?: number;
  releaseDate?: string;
  sequenceNumber?: number;
  site?: any;
  product?: any;
  scheduledLineDetail?: any;
  scheduledRunLaborHours?: string;
  scheduledPreRunLaborHours?: string;
  scheduledPostRunLaborHours?: string;
  minimumWaitingDuration: string;
  maximumWaitingDuration: string;
  scheduledDepartmentTitle?: string;
  isSplitted?: boolean;
  woTargetSpeed?: string;
}

export interface WorkOrderBulkSaveManyInterface {
  workOrders: WorkOrderScheduleSaveOneDtoInterface[];
}

export interface WorkOrdersTableQueryParams {
  page: number;
  pageSize: number;
  action?: string;
  search?: string;
  orderBy?: string;
  orderDesc?: boolean;
  order?: string;
  orderType?: string;
  advancedFilter?: IAdvancedFilterOutput;
  advancedFilterPage?: string;
}

export interface WorkOrderReadExcelInterface {
  workOrderData: WorkOrderBulkSaveManyInterface;
  siteData: { id: number; name: string }[];
}

export interface ScenarioWorkOrdersTableQueryParams extends WorkOrdersTableQueryParams {
  siteId?: number;
  scenarioId?: number;
}

export interface DynamicDateFilterParameterInterface {
  isDashboardMode: boolean;
  timezone: string;
  locale: string;
  dynamicDateFilter: string;
}

export interface ILineDropDown {
  id: number;
  siteId: number;
  name: string;
}

export interface IWorkOrderFilters {
  regularMode: CustomWorkOrdersFilterOutputInterface;
  dashboardMode: CustomWorkOrdersFilterOutputInterface;
}

export enum EWorkOrdersGroups {
  COLUMN_GROUPS = 'columnGroups',
  GENERAL_INFO = 'generalInfo',
  PHASES = 'phases',
  TASK_GROUPS = 'taskGroups',
  WORK_ORDER_DURATION = 'workOrderDuration',
}

export interface ISelectedGroupsColumns {
  generalInfo?: string[];
  phases?: string[];
  taskGroups?: string[];
  workOrderDuration?: string[];
}

export interface IAdditionalColumns {
  phases: ITableHeader[];
  workOrderDuration: ITableHeader[];
}

export enum PageModes {
  REGULAR_MODE = 'REGULAR_MODE',
  DASHBOARD_MODE = 'DASHBOARD_MODE',
}

export interface BulkEditFieldInterface {
  id: number;
  siteId?: BulkEditFieldElementInterface<number>;
  productionStage?: BulkEditFieldElementInterface<number>;
  jobId?: BulkEditFieldElementInterface<number>;
  productId?: BulkEditFieldElementInterface<number>;
  productVersion?: BulkEditFieldElementInterface<number>;
  quantity?: BulkEditFieldElementInterface<number>;
  dueDate?: BulkEditFieldElementInterface<string>;
  releaseDate?: BulkEditFieldElementInterface<string>;
  scheduledStart?: BulkEditFieldElementInterface<string>;
  scheduledEnd?: BulkEditFieldElementInterface<string>;
  scheduledLine?: BulkEditFieldElementInterface<number>;
  scheduledRunDuration?: BulkEditFieldElementInterface<number>;
  scheduledPreRunDuration?: BulkEditFieldElementInterface<number>;
  scheduledPostRunDuration?: BulkEditFieldElementInterface<number>;
  isBulkEditFormValid?: boolean;
}

export interface CustomNewDesignWorkOrdersFilterOutputInterface {
  siteId: number[];
  lineIds: number[] | number;
  lastLineIds: number[] | number;
  productId: number[];
  filterDateType: string[];
  filterDateRange?: {
    startDate: Moment | string;
    endDate: Moment | string;
  };
  filterStartDate: Moment | string;
  filterEndDate: Moment | string;
  completeStatus: string[];
  searchType: string[];
  search: string;
  dynamicDate: DateRangeTypes[];
  advancedFilter?: IAdvancedFilterOutput;
  jobIds: number[] | number;
}

export type NewBulkEditFieldInterface = Omit<BulkEditFieldInterface, 'quantity'> & {
  quantityOrdered?: BulkEditFieldElementInterface<string>;
};

export interface BulkEditFieldElementInterface<T> {
  value?: T;
  text?: string;
  textKey?: string;
}

export interface TableQueryParams {
  page: number;
  pageSize: number;
  action?: string;
  search?: string;
  orderBy?: string;
  orderDesc?: boolean;
  order?: string;
  orderType?: string;
}

export interface IPhaseColumnNames {
  preRunPhaseName: string;
  postRunPhaseName: string;
  runPhaseName: string;
}

export interface IPhaseDuration {
  preRun: string;
  postRun: string;
  run: string;
}

export interface IPhaseDurationIndices {
  preRun: number;
  postRun:number;
  run:number ;
}

export enum EWorkOrderColors {
  GREEN = '#6FC764',
  RED = '#DD332B',
  ORANGE = '#FF9347',
  WHITE = '#FFF',
}

export interface IWorkOrderPageSiteFilter {
  id: number;
  weekStartDay: number;
}

export enum ERelatedRecordModals {
  ACTIVITY_HISTORY = 'ACTIVITY_HISTORY',
  COUNT = 'COUNT',
}
