import { TabRowInterface } from '../../../shared/component/side-config-bar/side-config-bar.model';
import {
  BaseTimestampInterface,
  GetManyResponseInterface,
} from '../../../shared/model/interface/crud-response-interface.model';
import { BulkResponseDataInterface, ResponseInterface } from '../../work-order-schedule/work-order-schedule.model';
import { ScwMatInputRule } from '../../../shared/component/scw-mat-ui/scw-mat-input/scw-mat-input.model';
import { GenericCrudRequestConstructionParameters } from '../../../shared/model/interface/generic-api-request.model';
import { DropdownOptionsInterface } from '../../../view/settings/tasks/tasks.model';
import { DateRangeFilterInterface } from '../../../view/reports/check-in-logs/check-in-logs.model';
import { ICommentTag } from 'src/app/standalone/comment-feed/comment-feed-service/comment-feed.model';
import { TagInterface } from '../../settings/tags/tags.model';

export interface CommentLogsStateInterface {
  isCommentLogsDataLoading: boolean;
  isCommentLogsDataLoaded: boolean;
  isCommentLogsDeleteRowsLoading: boolean;
  isCommentLogsDeleteRowsLoaded: boolean;
  isCommentLogRowForCommentFormLoading: boolean;
  isCommentLogRowForCommentFormLoaded: boolean;
  isCommentLogRowEditLoading: boolean;
  isCommentLogRowEditLoaded: boolean;
  commentLogsData: GetManyResponseInterface<ICommentLogsTableDataResponse>;
  commentLogData: IGetCommentLogRowResponse;
  filters: ICommentLogsFilterCard;
  tableSettings: TabRowInterface[];
}

export interface ICommentLogsRawData {
  changedAt: string;
  id: number;
  lastChangedAt: string;
  objectType: string;
  tags: number[] | null;
  objectId: number;
  objectProperty: string;
  objectName: string;
  commentDate: string;
  commentMessage: string;
  commentTypeId: number;
  shiftDay: string;
  shiftId: number;
  createdAt: string;
  commentType: ICommentLogsCommentType;
  shift: ICommentLogsSchedulerShift;
  site: ICommentLogsSite;
  workOrder: ICommentLogsWorkOrder;
  user: ICommentLogsUser;
  editableFields?: string | string[];
  tagsFromAI?: number;
  showIcon?: boolean;
  folderId?: number;
}

export interface ICommentLogsTableDataResponse extends Pick<BaseTimestampInterface, 'createdAt' | 'createdBy'> {
  id: number;
  lastChangedAt: string | null;
  lastChangedBy: number | null;
  commentDate: string;
  commentMessage: string;
  commentTypeId: number;
  commentTypeCategory: string;
  commentTypeName: string;
  departmentId: number;
  departmentName: string;
  lineId: number;
  lineName: string;
  objectId: number;
  objectProperty: string;
  objectType: string;
  objectName: string;
  shiftDay: string;
  shiftId: number;
  shiftName: string;
  siteId: number;
  siteName: string;
  tags: null;
  createUserFullName: string;
  changeUserFullName: string;
  userId: number;
  workOrderId: number;
  workOrderNumber: string;
  editableFields?: string | string[];
  tagIds: number[];
  tagIdFromAi: number | null;
  folderId: number | null;
  filePaths: string | null;
  jobName: string | null;
  jobId: number | null;
}

export interface ICommentLogsTableData extends ICommentLogsGetResponseData {
  tags: TagInterface[];
}

export interface ICommentLogsGetResponseData {
  siteId: number;
  siteName: string;
  workOrderName: string;
  lineName: string;
  departmentName: string;
  commentType: string;
  commentTypeRaw: string;
  objectType: string;
  objectName: string;
  objectProperty: string;
  commentDate: string;
  commentMessage: string;
  commentMessageRaw: string;
  commentMessageFixedLength: string | null;
  shiftName: string;
  createdBy: string;
  lastChangedBy: string;
  lastChangedAt: string;
  id: number;
  error?: string | string[];
  editableFields?: string | string[];
  tagIds?: number[];
  tagIdFromAi?: number | null;
  folderId: number | null;
  filePaths: string | null;
  workOrderId?: number;
  jobName?: string;
  jobId?: number;
}

export interface ICommentLogsCommentType {
  id: number;
  category: string;
}

export interface ICommentLogsSite {
  name: string;
}

export interface ICommentLogsSchedulerShift {
  name: string;
}

export interface ICommentLogsWorkOrder {
  woNumber: string;
}

export interface ICommentLogsUser {
  id: number;
  fullName: string;
}

export interface ICommentLogsDeleteResponse {
  data?: BulkResponseDataInterface[];
  date: Date;
  success: boolean;
  message?: string;
}

export interface IGetCommentLogRowResponse<T extends Partial<ICommentLogsRawData> = ICommentLogsRawData>
  extends ResponseInterface {
  data: T;
}

export interface ICommentInformationFormSubmitInterface {
  commentMessage: {
    isEnabled: boolean;
    value: string;
    dropdownValue?: DropdownOptionsInterface[];
    commentTypeId?: number;
    rules?: ScwMatInputRule[];
  };
}

export interface ICommentLogsTableSortingParameters {
  lineName: string;
  departmentName: string;
  siteName: string;
  workOrderName: string;
  shiftName: string;
  createdBy: string;
  lastChangedBy: string;
}

export interface ICommentLogsFilterParams {
  page: number;
  offset: number;
  dateRange?: DateRangeFilterInterface;
  siteIds?: number[] | number;
  lineIds?: number[] | number;
  workOrderIds?: number[] | number;
  commentTypes?: string[] | string;
  objectTypes?: string[] | string;
  tagIds?: number[] | number;
}

export interface ICommentLogsQueryParams extends ICommentLogsFilterParams {
  sort?: {
    column: string;
    type: string;
  }[];
}

export interface ICommentLogsQueryParamsFormatted extends ICommentLogsFilterParams {
  startDate?: string;
  endDate?: string;
  sort?: string;
}

export interface ICommentLogsFilterCard extends GenericCrudRequestConstructionParameters {
  dateRange?: DateRangeFilterInterface;
  siteIds?: number[];
  lineIds?: number[];
  workOrderIds?: number[];
  objectTypes?: string[];
  commentTypes?: string[];
  tagIds?: number[];
}

export interface ICommentCountWithLastCommentMessageRequestedObject {
  objectType: ECommentLogsObjectType;
  objectId: number;
}

export interface ICommentCountWithLastCommentMessageParams {
  commentType: ECommentLogsCommentType;
  requestedObjects: ICommentCountWithLastCommentMessageRequestedObject[];
  containAllCommentMessages?: boolean;
}

export interface ICommentCountWithLastCommentMessage {
  objectId: number;
  objectType: ECommentLogsObjectType;
  totalCommentCount: number;
  lastCommentMessage: string;
  allCommentMessages?: ICommentMessage[] | null;
  allCommentTags?: TagInterface[];
}

export interface ICommentMessage {
  message: string;
  tagIds: number[];
  tagIdFromAi: number | null;
  tags?: TagInterface[];
  createdAt: string;
  folderId: number;
}

export enum ECommentLogsObjectType {
  activityHistory = 'activity_history',
  department = 'department',
  lines = 'lines',
  workOrderSchedule = 'work_order_schedule',
  productionCounts = 'production_counts',
  stationActivityHistory = 'station_activity_history',
}

export enum ECommentLogsObjectName {
  department = 'department',
  lines = 'line',
  production_counts = 'workOrder',
  work_order_schedule = 'workOrder',
}

export enum ECommentLogsScopeName {
  department = 'lineType',
  lines = 'title',
  production_counts = 'woNumber',
  work_order_schedule = 'woNumber',
}

export enum ECommentLogsCommentType {
  maintenanceComments = 'maintenance_comments',
  supervisorComments = 'supervisor_comments',
  operatorComments = 'operator_comments',
  productionCountComments = 'production_count_comments',
  shiftQuality = 'shift_quality',
  shiftGoalHit = 'shift_goal_hit',
  shiftDataQuality = 'shift_data_quality',
  shiftSafety = 'shift_safety',
  shiftEndStatusComments = 'shift_end_status_comments',
  shiftActionItemComments = 'shift_action_item_comments',
  nextShiftGoalComments = 'next_shift_goal_comments',
  shiftTopDownTimeActivity = 'shift_top_down_time_activity',
  shiftGoalComments = 'shift_goal_comments',
  callOffsComments = 'call_offs_comments',
  productionReviewComments = 'production_review_comments',
  activityComment = 'activity_comment',
  deletedActivityComment = 'deleted_activity_comment',
  scrapCountComments = 'scrap_count_comment',
}

export enum ECommentLogsObjectProperty {
  runPhase = 'run_phase',
  preRunPhase = 'pre_run_phase',
  postRunPhase = 'post_run_phase',
}
