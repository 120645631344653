import {
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  Renderer2,
  SimpleChanges,
  Type,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { FilterableComponents } from '../filterable-objects.class';
import { DropdownComponent } from '../dropdown/dropdown.component';
import {
  DependencyType,
  DropdownFilterConfiguration,
  IDropdownDepend,
  IDropdownStaticCondition,
} from '../dropdown/dropdown.model';
import { combineLatest, map, Observable, Subject } from 'rxjs';
import { FilterDateRangePickerComponent } from '../filter-date-range-picker/filter-date-range-picker.component';
import { TranslateService } from '@ngx-translate/core';
import {
  DefaultSelectionValuesInterface,
  DropdownItemFilterInterface,
  FilterCardOptionInterface,
  FilterDataObjectTypes,
  IStaticDropdownParameters,
  LineCRUDInterface,
  RowConfigurationInterface,
} from '../filter.class';
import * as _ from 'lodash';
import { SearchBoxComponent } from '../search-box/search-box.component';
import { AdvancedFilterComponent } from '../advanced-filter/advanced-filter.component';
import { AdvancedFilterObjects, IFilter, SqlOperators } from '../advanced-filter/advanced-filter.model';
import { ButtonGroupComponent } from '../button-group/button-group.component';
import { EFilterDropdownElements } from '../../../../store/filter/filter.model';
import { Store } from '@ngrx/store';
import { OeeAppState } from '../../../../store/oee.reducer';
import { startWith, take } from 'rxjs/operators';
import { FilterLineState } from '../../../../store/filter/line/line.reducer';
import {
  IComparisonCardConfiguration,
  IComparisonDropdownOption,
} from '../comparison-filter-card/comparison-filter-card.model';
import * as FilterActions from '../../../../store/filter/filter.actions';
import { FilterShiftState } from '../../../../store/filter/shift/shift.reducer';

@Component({
  selector: 'filter-card',
  templateUrl: './filter-card.component.html',
  styleUrls: [],
})
export class FilterCardComponent implements OnInit, OnChanges {
  @ViewChild('row1', { read: ViewContainerRef, static: true }) row1: ViewContainerRef;
  @ViewChild('row2', { read: ViewContainerRef, static: true }) row2: ViewContainerRef;
  @ViewChild('row3', { read: ViewContainerRef, static: true }) row3: ViewContainerRef;
  @Input() comparisonMode: boolean = false;
  @Input() comparisonConfiguration: IComparisonCardConfiguration;
  @Input() options: FilterCardOptionInterface;
  @Input() mainGridWide: number = 8;
  @Input() additionalGridWide: number = 4;
  @Input() enableSetAsDefault: boolean = false;
  @Input() defaultDropdownSelectionSubject: Subject<DefaultSelectionValuesInterface & { skipNextUpdate?: boolean }>;
  @Input() strictControlForSelected: boolean = false;
  @Input() autoApply: boolean = false;
  @Input() advancedFilterDefaultValues: IFilter[] = [];
  @Input() isAdvancedFilterSetDefaultButtonHide: boolean = false;
  @Input() validUpdateMessage: string = this.translate.instant('filterUpdate.filterUpdateText', {
    object: this.translate.instant('filterUpdate.objectTypes.table'),
  });
  @Input() isCard: boolean = true;
  @Input() enableFilterUpdateCard: boolean = true;
  @Input() hiddenElementIds: string[] = [];
  @Input() triggerUpdateButtonClickSubject: Subject<void>;
  @Input() resetHiddenElementsSubject: Subject<void>;
  @Input() isLoading: boolean = false;
  @Input() triggerShowUpdateCardSubject: Subject<void>;

  @Output() filterChange = new EventEmitter<any>();
  @Output() setAsDefault = new EventEmitter<any>();
  @Output() filterEventListener = new EventEmitter<any>();
  private readonly createdComponents = Array<any>();
  private filterElements: ComponentRef<FilterableComponents>[] = [];
  private outputSubjects: Subject<FilterDataObjectTypes>[] = [];
  private filterOutput = {};
  private defaultSelectedFieldCount: number = 0;
  private skipNextUpdates: number = 0;
  private requiredFieldCount: number = 0;
  private filterReadyConditions: { [filterId in EFilterDropdownElements]?: Observable<boolean> } = {
    [EFilterDropdownElements.shiftMultiSelectDropdown]: this.store
      .select('shiftFilter')
      .pipe(map((state: FilterShiftState) => !state.isLoading && state.isLoaded)),
  };
  public showUpdateCard: boolean = false;
  public updateMessage: string = null;
  public invalidUpdate: boolean = false;
  public filterAutoApply: boolean = false;
  public invalidSelectBoxMessage: string = 'filterCard.messages.invalidFilter';
  public comparisonGroupFilterObjectId: string = '';
  public selectedGroupType: IComparisonDropdownOption | null;
  public isInvalidComparison: boolean = false;
  public showUpdateCardNormalMode: boolean;
  public invalidUpdateCardNormalMode: boolean;
  public updateMessageNormalMode: string;
  public areFiltersReady$: Observable<boolean> = new Observable<boolean>().pipe(startWith(true));

  constructor(
    private translate: TranslateService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private renderer: Renderer2,
    private viewContainerRef: ViewContainerRef,
    private readonly injector: Injector,
    private readonly store: Store<OeeAppState>,
  ) {}

  private add(
    row: number,
    componentClass,
    objectsClass,
    outputOptions,
    elementID?: string,
    dependency?: DependencyType,
    options?: any,
    cls?: string,
    disableComponent?: any,
    filter?: DropdownItemFilterInterface,
    advancedFilterObject?: Type<AdvancedFilterObjects>,
    dependedOptions?: any,
    dependProperties?: string[],
    dropdownDepends?: IDropdownDepend[],
    staticConditions?: IDropdownStaticCondition[],
  ): ComponentRef<FilterableComponents> {
    const componentFactory: ComponentFactory<FilterableComponents> =
      this.componentFactoryResolver.resolveComponentFactory(componentClass);
    const element = this.viewContainerRef.createComponent(componentFactory);
    this.renderer.appendChild(this[`row${row + 1}`].element.nativeElement, element.location.nativeElement);

    if (cls !== null && cls !== undefined) {
      element.location.nativeElement.className = cls;
    }

    if (
      (options.selectAll !== undefined && options.selectAll) ||
      options.inputModel !== undefined ||
      options.defaultSelection !== undefined
    ) {
      this.defaultSelectedFieldCount = this.defaultSelectedFieldCount + 1;
    }

    if (options.isRequired) {
      this.requiredFieldCount = this.requiredFieldCount + 1;
    }

    if (elementID !== null && elementID !== undefined) {
      element.instance.elementID = elementID;
    }

    if (typeof disableComponent !== 'undefined' && disableComponent !== null) {
      element.instance['disableComponent'] = {
        elementId: disableComponent['elementId'],
      };
    }

    if (filter) {
      element.instance['filter'] = filter;
    }

    element.instance.outputOptions = outputOptions;
    switch (componentClass) {
      case DropdownComponent:
        const staticParameters: IStaticDropdownParameters = FilterCardComponent.getStaticDropdownParameters(
          elementID as EFilterDropdownElements,
        );

        element.instance['dropdownDepends'] = [...dropdownDepends, ...staticParameters.depends];
        element.instance['dependProperties'] = [...dependProperties, ...staticParameters.dependProperties];
        element.instance['staticConditions'] = staticConditions;
        element.instance['dropdownObjectClass'] = objectsClass;
        element.instance['strictControlForSelected'] = this.strictControlForSelected;

        if (dependency !== null && dependency !== undefined) {
          element.instance['depends'] = dependency;
        }

        if (options !== null && options !== undefined) {
          element.instance['dropdownSettings'] = { ...options, ...staticParameters.options };
        }
        break;
      case FilterDateRangePickerComponent:
        if (options !== null && options !== undefined) {
          element.instance['defaultSettings'] = options;
        }
        break;
      case AdvancedFilterComponent:
        element.instance['filterObject'] = this.injector.get<AdvancedFilterObjects>(advancedFilterObject);
        element.instance['defaultValues'] = this.advancedFilterDefaultValues;
        element.instance['isSetDefaultButtonHide'] = this.isAdvancedFilterSetDefaultButtonHide;
        break;
      case ButtonGroupComponent:
        element.instance['buttons'] = options.buttons;
        element.instance['value'] = options.value;
        element.instance['disabled'] = options.disabled;
        break;
    }

    if (dependedOptions !== null && dependedOptions !== undefined) {
      element.instance['dependedOptions'] = dependedOptions;
    }

    options['customOperations'] = ((options['customOperations'] as Function[]) ?? []).map(
      (customOperation: Function) => {
        return customOperation.bind(element.instance);
      },
    );

    this.createdComponents.push(element.instance);
    return element;
  }

  ngOnInit(): void {
    const rows = this.options.rows;
    if (Array.isArray(rows)) {
      this.filterElements = _.flatten(
        rows.map((row, index) => {
          return row.map((component) => {
            const outputData = {};

            outputData[component.outputOptions.filterObjectId] = null;
            if (!component.options.isRequired && component.type === DropdownComponent) {
              outputData[component.outputOptions.filterObjectId] = -1;
            }

            if (!component.options.isRequired && component.type === ButtonGroupComponent) {
              outputData[component.outputOptions.filterObjectId] = component.options.value;
            }

            this.filterOutput = { ...this.filterOutput, ...outputData };
            return this.add(
              index,
              _.get(component, 'type', null),
              _.get(component, 'object', null),
              _.get(component, 'outputOptions', null),
              _.get(component, 'elementId', null),
              _.get(component, 'depends', null),
              _.get(component, 'options', null),
              _.get(component, 'cls', null),
              _.get(component, 'disableComponent', null),
              _.get(component, 'filter', null),
              _.get(component, 'advancedFilterObject', null),
              _.get(component, 'dependedOptions', null),
              _.get(component, 'dependProperties', []),
              _.get(component, 'dropdownDepends', []),
              _.get(component, 'staticConditions', []),
            );
          });
        }),
      );
      this.showOrHideFilterElements();
    }

    this.assignAreFiltersReadyObservable();

    this.createdComponents.forEach((component) => {
      const outputSubject = new Subject<FilterDataObjectTypes>();
      component.outputSubject = outputSubject;
      this.outputSubjects.push(outputSubject);

      if (
        component.hasOwnProperty('disableComponent') &&
        component.disableComponent &&
        component.disableComponent.elementId
      ) {
        const result = this.createdComponents.find(
          (element) => element.elementID === component.disableComponent.elementId,
        );
        if (result !== undefined) {
          result.disabledSubject.next(true);
          outputSubject.asObservable().subscribe((data) => {
            const output = _.get(data, component.outputOptions.filterObjectId, -1);
            let disableStatus: boolean = true;
            if (output !== -1) {
              disableStatus = false;
            }
            result.disabledSubject.next(disableStatus);
          });
        }
      }

      if (component.dependedOptions && Array.isArray(component.dependedOptions)) {
        for (const dependedOption of component.dependedOptions) {
          const result = this.createdComponents.find((element) => element.elementID === dependedOption.elementId);

          if (!result) {
            continue;
          }

          const dependedOptionSubject = new Subject();
          const componentDependedOptionConfiguration = {
            elementId: component.elementID,
            dependedElementId: result.elementID,
            dependedOptionListener: dependedOptionSubject,
            dependedOption: dependedOption.dependedOption,
            additionalChanges: dependedOption.additionalChanges,
            getDependentValue:
              dependedOption.getDependentValue ??
              function (value) {
                return value;
              },
            submit: false,
          };
          const resultDependedOptionConfiguration = {
            elementId: result.elementID,
            dependedElementId: component.elementID,
            dependedOptionListener: dependedOptionSubject,
            dependedOption: dependedOption.dependedOption,
            additionalChanges: dependedOption.additionalChanges,
            getDependentValue:
              dependedOption.getDependentValue ??
              function (value) {
                return value;
              },
            submit: true,
          };
          component.dependedOptionConfiguration.push(componentDependedOptionConfiguration);
          component.subscribeDependedOptionListener(componentDependedOptionConfiguration);
          result.dependedOptionConfiguration.push(resultDependedOptionConfiguration);
        }
      }

      if (component.depends) {
        const result = this.createdComponents.find((element) => element.elementID === component.depends.elementId);

        if (result === undefined) {
          return;
        }

        const filterSubject = new Subject();
        let componentFilterConfiguration: DropdownFilterConfiguration = {
          elementId: component.elementID,
          filteredElementId: result.elementID,
          filterListener: filterSubject,
          submit: false,
        };
        let resultFilterConfiguration: DropdownFilterConfiguration = {
          elementId: result.elementID,
          filteredElementId: component.elementID,
          filterListener: filterSubject,
          submit: true,
        };
        if (component.depends.isDataLocatedAtParent && component.depends.dataPathAtParent) {
          componentFilterConfiguration = {
            ...componentFilterConfiguration,
            isDataLocatedAtParent: component.depends.isDataLocatedAtParent,
            dataPathAtParent: component.depends.dataPathAtParent,
          };
          resultFilterConfiguration = {
            ...resultFilterConfiguration,
            isDataLocatedAtParent: component.depends.isDataLocatedAtParent,
            dataPathAtParent: component.depends.dataPathAtParent,
          };
        }
        component.filterListenerConfiguration.push(componentFilterConfiguration);
        component.subscribeFilterListener(componentFilterConfiguration);
        result.filterListenerConfiguration.push(resultFilterConfiguration);
      }
    });
    this.outputSubjects.forEach((subject) => {
      subject.asObservable().subscribe((data) => {
        this.filterOutput = { ...this.filterOutput, ..._.cloneDeep(_.omit(data, 'showUpdate')) };
        this.filterEventListener.emit(this.filterOutput);
        const isFilterUpdateEmitted: boolean = this.publishDataDecider(
          data['showUpdate'] || (data['showUpdate'] === undefined && !this.skipNextUpdates),
        );
        this.saveUpdateCardState();

        const keepUpdateCardState: boolean = 'shiftIds' in data && data['showUpdate'] !== false;
        this.validateComparisonMode(false, keepUpdateCardState);

        if (
          !this.showUpdateCard &&
          this.defaultSelectedFieldCount === 0 &&
          !this.invalidUpdate &&
          this.skipNextUpdates === 0 &&
          !isFilterUpdateEmitted
        ) {
          this.filterChange.emit(this.filterOutput);
        }
      });
    });

    this.triggerUpdateButtonClickSubject?.asObservable()?.subscribe(() => {
      this.onUpdateButtonClick();
    });

    this.triggerShowUpdateCardSubject?.asObservable()?.subscribe(() => {
      this.publishDataDecider(true);
    })

    if (this.defaultDropdownSelectionSubject) {
      this.defaultDropdownSelectionSubject.asObservable().subscribe((values) => {
        const { skipNextUpdate, ...data } = values;

        if (Object.keys(data).length <= 0) {
          return;
        }

        this.defaultSelectedFieldCount += Object.keys(data).length;

        if (skipNextUpdate) {
          this.skipNextUpdates = Object.keys(data).length;
        }

        this.applyDefaultSelectionToComponents(data);
      });
    }

    this.resetHiddenElementsSubject?.asObservable()?.subscribe((): void => {
      for (const index of Object.keys(this.filterElements)) {
        _.set(
          this.filterElements[index],
          'location.nativeElement.hidden',
          false,
        );
      }
    });

    if (this.defaultSelectedFieldCount !== 0) {
      return;
    }

    if (this.requiredFieldCount === 0) {
      this.filterChange.emit(this.filterOutput);
    } else {
      this.publishDataDecider();
    }
  }

  private applyDefaultSelectionToComponents(data: DefaultSelectionValuesInterface): void {
    this.createdComponents.forEach((component) => {
      if (component instanceof ButtonGroupComponent) {
        const value: any = _.cloneDeep(_.get(data[component.elementID], 'values.0', null));

        if (!_.isNil(value)) {
            component.value = value;

            this.filterOutput[component.elementID] = component.value;

            component.outputSubject.next({ value: component.value, showUpdate: true });
        }

        return;
      } else if (!(component instanceof DropdownComponent)) {
        return;
      }

      component.dropdownSettings.defaultSelection = _.cloneDeep(data[component.elementID]);

      if (component.dropdownObject.data === undefined) {
        return;
      }

      component.dropdownObject.dataSubject.next(component.dropdownObject.data);

      if (this.autoApply && !this.filterAutoApply) {
        this.filterAutoApply = true;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.showOrHideFilterElements();
    this.showOrHideUpdateCard(changes);
  }

  onUpdateButtonClick(): void {
    this.showUpdateCard = false;

    if (this.skipNextUpdates === 0) {
      this.filterChange.emit(this.filterOutput);
    }
  }

  onSetAsDefaultButtonClick(): void {
    if (!this.enableSetAsDefault) {
      return;
    }
    this.onUpdateButtonClick();
    this.setAsDefault.emit(this.filterOutput);
  }

  private publishDataDecider(showUpdate: boolean = true): boolean {
    const invalidFields = this.validateRequiredIsSelected();
    let invalidMessage: string;
    const invalidUpdatePreviousValue: boolean = this.invalidUpdate;
    this.invalidUpdate = invalidFields.length !== 0;
    this.defaultSelectedFieldCount = this.defaultSelectedFieldCount - 1;

    if (this.invalidUpdate) {
      invalidMessage = this.translate.instant(this.invalidSelectBoxMessage, {
        fields: invalidFields.join(', '),
      });
    }

    this.updateMessage = this.invalidUpdate ? invalidMessage : this.validUpdateMessage;

    if (!showUpdate) {
      return false;
    }

    this.showUpdateCard = (this.defaultSelectedFieldCount < 0 || this.invalidUpdate) && !this.skipNextUpdates;

    if (this.skipNextUpdates === 0) {
      return false;
    }

    this.skipNextUpdates -= 1;

    if (this.skipNextUpdates > 0) {
      return false;
    }

    this.store.dispatch(new FilterActions.FilterDependedDropdownData());

    if (invalidUpdatePreviousValue !== this.invalidUpdate) {
      return false;
    }

    this.filterChange.emit(this.filterOutput);
    return true;
  }

  private validateRequiredIsSelected(): string[] {
    const data = Object.values(this.options.rows);
    const invalidFieldNames: string[] = [];

    data.forEach((row: RowConfigurationInterface[]) => {
      row.forEach((element: RowConfigurationInterface) => {
        const elementId = element.outputOptions.filterObjectId;
        const isDatePicker = element.type === FilterDateRangePickerComponent;

        if (
          element.type === SearchBoxComponent &&
          this.filterOutput[elementId] !== null &&
          this.filterOutput[elementId].startsWith(' ')
        ) {
          invalidFieldNames.push(element.options.text);
          this.invalidSelectBoxMessage = 'filterCard.messages.invalidInput';
        }

        if (
          element.options.isRequired &&
          this.filterOutput.hasOwnProperty(elementId) &&
          !this.hiddenElementIds.includes(element.elementId)
        ) {
          if (
            this.filterOutput[elementId] === null ||
            (isDatePicker &&
              this.filterOutput[elementId].startDate === null &&
              this.filterOutput[elementId].endDate === null)
          ) {
            invalidFieldNames.push(element.options.text);
          } else if (this.strictControlForSelected && this.filterOutput[elementId] === -1) {
            invalidFieldNames.push(element.options.text);
          }
        }
      });
    });

    return invalidFieldNames;
  }

  private showOrHideFilterElements() {
    for (const index of Object.keys(this.filterElements)) {
      _.set(
        this.filterElements[index],
        'location.nativeElement.hidden',
        this.hiddenElementIds.includes(this.filterElements[index].instance.elementID),
      );
    }

    if (!this.hiddenElementIds.length) {
      return;
    }

    const showUpdateCardForValidUpdate: boolean = this.showUpdateCard && this.updateMessage === this.validUpdateMessage;

    this.publishDataDecider();

    this.showUpdateCard = showUpdateCardForValidUpdate || this.invalidUpdate;
  }

  private validateComparisonMode(isComparisonTypeChanged: boolean, showUpdate: boolean = true) {
    if ((!this.invalidUpdate || isComparisonTypeChanged) && this.comparisonMode && this.comparisonConfiguration) {
      const selectedTypesIds: string = this.selectedGroupType?.relatedFilterObjectId
        ? this.getSelectedFilterObjectIds()
        : this.filterOutput[this.comparisonGroupFilterObjectId];
      this.isInvalidComparison =
        !Array.isArray(selectedTypesIds) ||
        selectedTypesIds.length > this.comparisonConfiguration.maxLimit ||
        selectedTypesIds.length < this.comparisonConfiguration.minLimit;

      if (this.isInvalidComparison && this.comparisonGroupFilterObjectId) {
        this.setUpdateCardState(
          true,
          true,
          this.translate.instant('general.rootCauseAnalysis.comparisonModeUpdateMessage', {
            compareItem: this.selectedGroupType?.updateCardName.toLowerCase(),
          }),
        );
      } else if (this.isInvalidComparison && this.comparisonGroupFilterObjectId === '') {
        this.setUpdateCardState(
          true,
          true,
          this.translate.instant('general.rootCauseAnalysis.comparisonItemMissingWarningMessage'),
        );
      } else if (showUpdate) {
        this.setUpdateCardState(this.comparisonGroupFilterObjectId !== '', false, this.validUpdateMessage);
      }
    }
  }

  private getSelectedFilterObjectIds(): number[] {
    let selectedFilterObjectIds: number[] = [];

    this.store
      .select(this.selectedGroupType.relatedFilterObjectStore)
      .pipe(take(1))
      .subscribe((state: FilterLineState) => {
        const selectedRelatedFilterObjectIds: number[] | number =
          this.filterOutput[this.selectedGroupType.relatedFilterObjectId];

        if (!Array.isArray(selectedRelatedFilterObjectIds)) {
          return;
        }

        let selectedFilterObjects: LineCRUDInterface[] = state.data.filter((filterObject: LineCRUDInterface) =>
          this.filterOutput[this.selectedGroupType.relatedFilterObjectId].includes(filterObject.id),
        );

        if (this.selectedGroupType.uniqByKey) {
          selectedFilterObjects = _.uniqBy(selectedFilterObjects, this.selectedGroupType.uniqByKey);
        }

        selectedFilterObjectIds = selectedFilterObjects.map((filterObject: LineCRUDInterface) => filterObject.id);
      });

    return selectedFilterObjectIds;
  }

  public comparisonTypeChanged(groupType) {
    this.selectedGroupType = groupType.length > 0 ? _.cloneDeep(groupType[0]) : null;
    this.comparisonGroupFilterObjectId = groupType.length > 0 ? groupType[0].id : '';

    this.validateComparisonMode(true);
  }

  private showOrHideUpdateCard(changes: SimpleChanges) {
    if (changes.hasOwnProperty('comparisonMode')) {
      if (!changes['comparisonMode'].currentValue) {
        this.setUpdateCardState(
          this.showUpdateCardNormalMode,
          this.invalidUpdateCardNormalMode,
          this.updateMessageNormalMode,
        );
        this.comparisonGroupFilterObjectId = '';
      }

      if (this.comparisonMode) {
        this.showUpdateCard = false;
      }
    }
  }

  private saveUpdateCardState(): void {
    this.showUpdateCardNormalMode = this.showUpdateCard;
    this.invalidUpdateCardNormalMode = this.invalidUpdate;
    this.updateMessageNormalMode = this.updateMessage;
  }

  private setUpdateCardState(showUpdateCard, invalidUpdate, updateMessage): void {
    this.showUpdateCard = showUpdateCard;
    this.invalidUpdate = invalidUpdate;
    this.updateMessage = updateMessage;
  }

  private static getStaticDropdownParameters(elementId: EFilterDropdownElements): IStaticDropdownParameters {
    const output: IStaticDropdownParameters = {
      options: { badgeShowLimit: 1 },
      depends: [],
      dependProperties: [],
    };

    switch (elementId) {
      case EFilterDropdownElements.siteSingleSelectDropdown:
      case EFilterDropdownElements.siteMultiSelectDropdown:
        output.dependProperties.push('id');
        break;
      case EFilterDropdownElements.lineSingleSelectDropdown:
      case EFilterDropdownElements.lineMultiSelectDropdown:
        output.dependProperties.push('id', 'activityIds');
        output.depends.push(
          {
            property: 'siteId',
            parentProperty: 'id',
            parentElementId: EFilterDropdownElements.siteSingleSelectDropdown,
            conditionType: SqlOperators.$in,
          },
          {
            property: 'siteId',
            parentProperty: 'id',
            parentElementId: EFilterDropdownElements.siteMultiSelectDropdown,
            conditionType: SqlOperators.$in,
          },
        );
        break;

      case EFilterDropdownElements.activitySingleSelectDropdown:
      case EFilterDropdownElements.activityMultiSelectDropdown:
        output.depends.push(
          {
            property: 'id',
            parentElementId: EFilterDropdownElements.lineMultiSelectDropdown,
            parentProperty: 'activityIds',
            conditionType: SqlOperators.$in,
            isDataLocatedAtParent: true,
          },
          {
            property: 'id',
            parentElementId: EFilterDropdownElements.lineSingleSelectDropdown,
            parentProperty: 'activityIds',
            conditionType: SqlOperators.$in,
            isDataLocatedAtParent: true,
          },
        );
        break;
      case EFilterDropdownElements.productSingleSelectDropdown:
      case EFilterDropdownElements.productMultiSelectDropdown:
        output.options.enableServerSideSearch = true;
        output.options.lazyLoading = true;
        output.options.searchProps = [
          {
            prop: 'description',
            condition: '$cont',
          },
          {
            prop: 'productId',
            condition: '$cont',
          },
        ];
        output.depends.push(
          {
            property: 'siteId',
            parentElementId: EFilterDropdownElements.siteSingleSelectDropdown,
            parentProperty: 'id',
            conditionType: SqlOperators.$in,
          },
          {
            property: 'siteId',
            parentElementId: EFilterDropdownElements.siteMultiSelectDropdown,
            parentProperty: 'id',
            conditionType: SqlOperators.$in,
          },
        );
        break;
      case EFilterDropdownElements.shiftSingleSelectDropdown:
      case EFilterDropdownElements.shiftMultiSelectDropdown:
        output.depends.push(
          {
            property: 'siteIds',
            parentElementId: EFilterDropdownElements.siteSingleSelectDropdown,
            parentProperty: 'id',
            conditionType: SqlOperators.$in,
            isDataLocatedAtCurrent: true,
          },
          {
            property: 'siteIds',
            parentElementId: EFilterDropdownElements.siteMultiSelectDropdown,
            parentProperty: 'id',
            conditionType: SqlOperators.$in,
            isDataLocatedAtCurrent: true,
          },
          {
            property: 'lineIds',
            parentElementId: EFilterDropdownElements.lineSingleSelectDropdown,
            parentProperty: 'id',
            conditionType: SqlOperators.$in,
            isDataLocatedAtCurrent: true,
          },
          {
            property: 'lineIds',
            parentElementId: EFilterDropdownElements.lineMultiSelectDropdown,
            parentProperty: 'id',
            conditionType: SqlOperators.$in,
            isDataLocatedAtCurrent: true,
          },
        );
        break;
      case EFilterDropdownElements.floorPlanSingleSelectDropdown:
        output.depends.push(
          {
            property: 'siteId',
            parentElementId: EFilterDropdownElements.siteSingleSelectDropdown,
            parentProperty: 'id',
            conditionType: SqlOperators.$in,
            isDataLocatedAtCurrent: true,
          },
          {
            property: 'lineId',
            parentElementId: EFilterDropdownElements.lineSingleSelectDropdown,
            parentProperty: 'id',
            conditionType: SqlOperators.$in,
            isDataLocatedAtCurrent: true,
          },
        );
        break;
      case EFilterDropdownElements.commentTypeMultiSelectDropdown:
        output.dependProperties.push('associatedTagType');
        break;
      case EFilterDropdownElements.tagMultiSelectDropdown:
        output.depends.push({
          property: 'objectType',
          parentProperty: 'associatedTagType',
          parentElementId: EFilterDropdownElements.commentTypeMultiSelectDropdown,
          conditionType: SqlOperators.$in,
        });
        break;
      case EFilterDropdownElements.lineStationMultiSelectDropdown:
        output.dependProperties.push('id');
        break;
      case EFilterDropdownElements.jobSingleSelectDropdown:
      case EFilterDropdownElements.jobMultiSelectDropdown:
        output.depends.push(
          {
            property: 'siteId',
            parentProperty: 'id',
            parentElementId: EFilterDropdownElements.siteMultiSelectDropdown,
            conditionType: SqlOperators.$in,
          },
          {
            property: 'siteId',
            parentProperty: 'id',
            parentElementId: EFilterDropdownElements.siteSingleSelectDropdown,
            conditionType: SqlOperators.$in,
          },
        );
        break;
    }

    switch (elementId) {
      case EFilterDropdownElements.activityMultiSelectDropdown:
        output.options.groupBy = 'activityTypeText';
        output.options.additionalLiveSearchProperties = ['activityTypeText'];
        break;

      case EFilterDropdownElements.lineMultiSelectDropdown:
        output.options.groupBy = 'typeName';
        output.options.additionalLiveSearchProperties = ['typeName'];
        output.options.singleSelection = false;
        break;

      case EFilterDropdownElements.siteSingleSelectDropdown:
        output.options.getInitData = true;
        output.options.singleSelection = true;
        break;

      case EFilterDropdownElements.lineStationMultiSelectDropdown:
        output.options.groupBy = 'lineTitle';
        output.options.additionalLiveSearchProperties = ['lineTitle'];
        break;
    }

    return output;
  }

  private assignAreFiltersReadyObservable(): void {
    if (!Array.isArray(this.options.rows)) {
      return;
    }

    const isFilterReadyObservables: Observable<boolean>[] = Object.entries(this.filterReadyConditions)
      .filter(([filterId]) =>
        (_.flatten(this.options.rows) as RowConfigurationInterface[]).some((row) => row.elementId === filterId),
      )
      .map(([, isFilterReadyObservable]) => isFilterReadyObservable);

    if (!isFilterReadyObservables.length) {
      return;
    }

    this.areFiltersReady$ = combineLatest(isFilterReadyObservables).pipe(
      map((isFilterReadyObservableResults) => isFilterReadyObservableResults.every(Boolean)),
    );
  }
}
