import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { FirebaseApp, getApp, initializeApp } from 'firebase/app';
import { Auth, getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Firestore, getFirestore } from 'firebase/firestore';
import { BehaviorSubject, Observable, Subject, filter, from, map, switchMap } from 'rxjs';
import { OeeAppState } from 'src/app/store/oee.reducer';
import * as UserActions from 'src/app/store/user/actions';
import { FirebaseCredentials } from 'src/app/store/user/model';
import { BaseOneResponseInterface } from '../../model/interface/crud-response-interface.model';
import { getMessaging, Messaging } from 'firebase/messaging';

@Injectable({ providedIn: 'root' })
export class ScwFirebaseService {
  public static pushNotificationsServiceUrl: string | null | undefined;
  private readonly firebaseApp$: Subject<FirebaseApp | null> = new BehaviorSubject<FirebaseApp | null>(null);

  private configFetched: boolean = false;

  constructor(private readonly http: HttpClient, private readonly store: Store<OeeAppState>) {}

  public getFirestore(): Observable<Firestore> {
    return this.getFirebaseApp()?.pipe(map((app: FirebaseApp) => getFirestore(app)));
  }

  public getMessaging(): Observable<Messaging> {
    return this.getFirebaseApp()?.pipe(map((app: FirebaseApp) => getMessaging(app)));
  }

  public getUid(): Observable<string> {
    return this.getFirebaseApp()?.pipe(
      map((app: FirebaseApp) => {
        const uid: string | undefined = getAuth(app).currentUser?.uid;

        if (!uid) {
          throw new Error('User UID not available');
        }

        return uid;
      }),
    );
  }

  private getFirebaseApp(): Observable<FirebaseApp> {
    if (!this.configFetched) {
      this.fetchConfig();
    }

    return this.firebaseApp$.pipe(filter((app: FirebaseApp | null) => app !== null));
  }

  private fetchConfig(): void {
    if (!ScwFirebaseService.pushNotificationsServiceUrl) {
      return;
    }

    this.http
      .get<BaseOneResponseInterface<FirebaseCredentials>>(
        `${ScwFirebaseService.pushNotificationsServiceUrl}/firebase/credentials/web`,
      )
      .pipe(
        switchMap((response: BaseOneResponseInterface<FirebaseCredentials>) => {
          this.store.dispatch(new UserActions.FirebaseCredentialsLoaded(response.data));
          const app: FirebaseApp = initializeApp(response.data.firebase);
          const auth: Auth = getAuth(app);
          return from(signInWithEmailAndPassword(auth, response.data.user.email, response.data.user.password));
        }),
      )
      .subscribe(() => {
        this.firebaseApp$.next(getApp());
      });

    this.configFetched = true;
  }
}
