import { FilterConditionTypes } from '../../shared/component/filter/filter.class';
import { SqlOperators } from '../../shared/component/filter/advanced-filter/advanced-filter.model';

export interface HttpOptionInterface {
  filter?: FilterConditionInterface[];
  page?: number;
  limit?: number;
  sort?: string;
  s?: string;
  groupBy?: string;
  fields?: string;
  join?: string;
}

export interface FilterConditionInterface {
  prop: string;
  condition: FilterConditionTypes;
  query?: string;
  isNotOrOperand?: boolean;
}

export interface IDependPropertyValue {
  property: string;
  values: string[] | number[];
}

export interface IDropdownData {
  elementId: EFilterDropdownElements;
  values: IDependPropertyValue[];
  isItemSelected: boolean;
}

export enum EFilterDropdownElements {
  siteSingleSelectDropdown = 'siteSingleSelectDropdown',
  siteMultiSelectDropdown = 'siteMultiSelectDropdown',
  lineSingleSelectDropdown = 'lineSingleSelectDropdown',
  lineMultiSelectDropdown = 'lineMultiSelectDropdown',
  activitySingleSelectDropdown = 'activitySingleSelectDropdown',
  activityMultiSelectDropdown = 'activityMultiSelectDropdown',
  taskSingleSelectDropdown = 'taskSingleSelectDropdown',
  shiftSingleSelectDropdown = 'shiftSingleSelectDropdown',
  shiftMultiSelectDropdown = 'shiftMultiSelectDropdown',
  rootCauseGroupMultiSelectDropdown = 'rootCauseGroupMultiSelectDropdown',
  productSingleSelectDropdown = 'productSingleSelectDropdown',
  productMultiSelectDropdown = 'productMultiSelectDropdown',
  equipmentMultiSelectDropdown = 'equipmentMultiSelectDropdown',
  workOrderSingleSelectDropdown = 'workOrderSingleSelectDropdown',
  workOrderMultiSelectDropdown = 'workOrderMultiSelectDropdown',
  kpiViewTimePeriodSelectDropdown = 'kpiViewTimePeriodSelectDropdown',
  kpiViewGroupSelectDropdown = 'kpiViewGroupSelectDropdown',
  taskAnalysisModeDropdown = 'taskAnalysisModeDropdown',
  scheduleAdherenceAnalysisModeDropdown = 'scheduleAdherenceAnalysisModeDropdown',
  startOfDaySingleSelectDropdown = 'startOfDaySingleSelectDropdown',
  sensorMultiSelectDropdown = 'sensorMultiSelectDropdown',
  sensorSingleSelectDropdown = 'sensorSingleSelectDropdown',
  sensorTypeSingleSelectDropdown = 'sensorTypeSingleSelectDropdown',
  departmentSingleSelectDropdown = 'departmentSingleSelectDropdown',
  departmentMultiSelectDropdown = 'departmentMultiSelectDropdown',
  userMultiSelectDropdown = 'userMultiSelectDropdown',
  checkInStatusMultiSelectDropdown = 'checkInStatusMultiSelectDropdown',
  checkInGroupBySingleSelectDropdown = 'checkInGroupBySingleSelectDropdown',
  productFamilyMultiSelectDropdown = 'productFamilyMultiSelectDropdown',
  actualLineMultiSelectDropdown = 'actualLineMultiSelectDropdown',
  scheduledLineMultiSelectDropdown = 'scheduledLineMultiSelectDropdown',
  schedulerPlannedMultiSelectDropdown = 'schedulerPlannedMultiSelectDropdown',
  schedulerDeployedMultiSelectDropdown = 'schedulerDeployedMultiSelectDropdown',
  userLevelMultiSelectDropdown = 'userLevelMultiSelectDropdown',
  userStatusMultiSelectDropdown = 'userStatusMultiSelectDropdown',
  activityTypeMultiSelectDropdown = 'activityTypeMultiSelectDropdown',
  activeMultiSelectDropdown = 'activeMultiSelectDropdown',
  statusSingleSelectDropdown = 'statusSingleSelectDropdown',
  kpiMetricPageMultiSelectDropdown = 'kpiMetricPageMultiSelectDropdown',
  rootCauseTypeMultiSelectDropdown = 'rootCauseTypeMultiSelectDropdown',
  alertFunctionMultiSelectDropdown = 'alertFunctionMultiSelectDropdown',
  workOrderStatusSingleSelectDropdown = 'workOrderStatusSingleSelectDropdown',
  workOrderDynamicDateSingleSelectDropdown = 'workOrderDynamicDateSingleSelectDropdown',
  objectTypeMultiSelectDropdown = 'objectTypeMultiSelectDropdown',
  commentTypeMultiSelectDropdown = 'commentTypeMultiSelectDropdown',
  activityTimelineTimePeriodSelectDropdown = 'activityTimelineTimePeriodSelectDropdown',
  floorPlanSingleSelectDropdown = 'floorPlanSingleSelectDropdown',
  objectPropertySingleSelectDropdown = 'objectPropertySingleSelectDropdown',
  checkInTimelineTimePeriodSelectDropdown = 'checkInTimelineTimePeriodSelectDropdown',
  tagMultiSelectDropdown = 'tagMultiSelectDropdown',
  customerMultiSelectDropdown = 'customerMultiSelectDropdown',
  opcTagMultiSelectDropdown = 'opcTagMultiSelectDropdown',
  productTransitionCompareDropdown = 'productTransitionCompareDropdown',
  productTransitionDurationTypeDropdown = 'productTransitionDurationTypeDropdown',
  productOrProductFamilyDropdown = 'productOrProductFamilyDropdown',
  lineWithNullDropdown = 'lineWithNullDropdown',
  automationCenterRuleStatusDropdown = 'automationCenterRuleStatusDropdown',
  notificationTypeMultiSelectDropdown = 'notificationTypeMultiSelectDropdown',
  notificationSystemTypeMultiSelectDropdown = 'notificationSystemTypeMultiSelectDropdown',
  taskMultiSelectDropdown = 'taskMultiSelectDropdown',
  lineStationMultiSelectDropdown = 'lineStationMultiSelectDropdown',
  jobSingleSelectDropdown = 'jobSingleSelectDropdown',
  jobMultiSelectDropdown = 'jobMultiSelectDropdown',
  iotDeviceReportDefinedInDFDropdown = 'iotDeviceReportDefinedInDFDropdown',
  iotDeviceReportDefinedInIotManagementDropdown = 'iotDeviceReportDefinedInIotManagementDropdown',
  iotDeviceReportIotDeviceTypeDropdown = 'iotDeviceReportIotDeviceTypeDropdown',
  // TODO recipient filtresi
  jobStatusMultiSelectDropdown = 'jobStatusMultiSelectDropdown'
}

export interface IDependSearch {
  [key: string]: { [key in SqlOperators]: number[] | string[] };
}

export interface ISetFilter {
  elementId: EFilterDropdownElements;
  value: IDependPropertyValue;
  isItemSelected: boolean;
}
